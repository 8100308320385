import { VTC_COMPARSION } from "../../../constants/ApplicationUrl";
import Api from "../../../dependencies/utils/Api";
export const VtcCompareListService = {
   
vtvCompareList( params) {
    return Api.POST(VTC_COMPARSION, params).then((response) => {
      console.log(response,'response')
      const { data, status } = response
      if (status === 200 || status === 201) {
        return { data, status }
      } else {
        const { data: { message } = {} } = response
        return { message, status }
      }
    })
  }
}