import {Box,Flex,Image,Button,Text,Menu,MenuButton,MenuList,MenuItem, IconButton} from '@chakra-ui/react';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { IoCallSharp } from "react-icons/io5";
import {buttonBackgroundColor,buttonColor,buttonHoverBackground,buttonHoverColor,primaryBackgroundColor} from '../../../Utils/UniversalColors';
import {FaFacebook,FaInstagramSquare,FaLinkedin,FaTwitter} from 'react-icons/fa';
import { MdEmail, MdMenu } from 'react-icons/md';
import '../Header/Style.css'
import { storage } from '../../../dependencies/store/storage';
import { ROLES } from '../../../constants/role';


function Header() {
  const role = storage.getUserRole();


  const handleLogout = ()=>{
    storage.clear()
    window.location.href='/'
  }

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (navbarRef.current) {
  //       const navbarTop = navbarRef.current.getBoundingClientRect().top;
  //       const scrollTop = window.scrollY;
  //       if (scrollTop > topBoxHeight) {
  //         setIsFixed(true);
  //         setShowTopBox(false);
  //       } else {
  //         setIsFixed(false);
  //         setShowTopBox(true);
  //       }
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  

return (
  <Box>


        {!role &&  (
            <Box position={'fixed'} w={'100%'} zIndex={'999'}>
            <Box boxShadow='rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;'>
              <Flex display={['none','none','flex']} p='2px 30px' justifyContent={'space-between'} alignItems={'center'} background={'rgb(248 248 251)'} color={'rgb(119 119 119)'} fontSize={'13px'} fontFamily={'Poppins, sans-serif'}>
                <Flex fontSize={'13px'} fontWeight={'400'} alignItems={'center'} gap={'25px'}>
                <a href='https://www.linkedin.com/company/103296109' target='_blank'>
                  <FaLinkedin />
                  </a>
                  <a href='https://www.instagram.com/travelmedicare/' target='_blank'>
                    <FaInstagramSquare />
                  </a>
                  <a href='https://x.com/TravelMedicare' target='_blank'>
                  <FaTwitter />
                  </a>
                </Flex>
                <Text textAlign={'center'}>Compare Rates to get best quotes.</Text>
                <Flex alignItems={'center'} gap={'45px'}>
                  <Flex cursor={'pointer'} _hover={{color:primaryBackgroundColor}} alignItems={'center'} gap={'7px'}>
                    <MdEmail />
                    <Text>support@travelmedicare.com</Text>
                  </Flex>
                  <Flex cursor={'pointer'} _hover={{color:primaryBackgroundColor}} alignItems={'center'} gap={'7px'}>
                  <IoCallSharp />
                    <Text>1844-844-3272</Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                background={'white'}
                color={'rgb(75 89 102)'}
                width={'100%'}
                p={'10px'}
                fontSize={'14px'}
                fontFamily={'Poppins, sans-serif'}
                justifyContent={'space-between'}
                display={['none','none','flex']}
              >
                <Box>
                  <Link to={'/'}>
                    <Image
                      style={{ width: '80%' }}
                      src='https://travelmedicare.com/public/users/images/logo.png'
                    />
                  </Link>
                </Box>
                <Flex gap={'60px'}>
                  <Flex gap={'30px'} alignItems={'center'}>
                    {/* <Link to='/travel-compare'>
                    <Text _hover={{color:buttonBackgroundColor}}>Travel Insurnace</Text>
                    </Link> */}
                    <Link to='/super-visa-compare'>
                    <Text _hover={{color:buttonBackgroundColor}}>SuperVisa Insurance</Text>
                    </Link>
                    <Link to='/visitor-compare'>
                    <Text _hover={{color:buttonBackgroundColor}}>Visitor Insurance</Text>
                    </Link>
                    <Link to='/student-compare'>
                    <Text _hover={{color:buttonBackgroundColor}}>Student Travel Insurnace</Text>
                    </Link>
                  </Flex>
                  <Flex alignItems={'center'}>
                    <Link to={'/login'}>
                      <Button
                        _hover={{
                          background: buttonHoverBackground,
                          color: buttonHoverColor
                        }}
                        size={'sm'}
                        backgroundColor={buttonBackgroundColor}
                        color={buttonColor}
                        fontFamily={'Poppins, sans-serif'}
                        fontWeight={'500'}
                        fontSize={'13px'}
                      >
                        Register - Login
                      </Button>
                    </Link>
                  </Flex>
                </Flex>
              </Flex>




            {/* ------------------- mobilee -------------------- */}

            <Flex zIndex={"9999999"} background={"white"}
                      position={"fixed"}
                      w={"100%"}
                      p={"10px"}
                      justifyContent={"space-between"}
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px" display={['flex','none','none']}>
                    <Box w={["48%","48%","18%"]}>
                        <Link to={"/"}>
                          <Image
                            w={"70%"}
                            src="https://travelmedicare.com/public/users/images/logo.png"
                          />
                        </Link>
                      </Box>
                      <Menu>
                      <MenuButton as={Button} _hover={{background:'white'}} background={'white'}>
                      <IconButton _hover={{background:'white'}} background={'white'} icon={<MdMenu />} aria-label />
                      </MenuButton>
                      <MenuList>
                        {/* <Link to={'/travel-compare'}>
                        <MenuItem>Travel Insurance</MenuItem>
                        </Link> */}
                        <Link to={'/supervisa-compare'}>
                        <MenuItem>Supervisa Insurance</MenuItem>
                        </Link>
                        <Link to={'/visitor-compare'}>
                        <MenuItem>Visitor Insurance</MenuItem>
                        </Link>
                        <Link to={'/student-compare'}>
                        <MenuItem>Student Insurance</MenuItem>
                        </Link>
                        <Link to={'/register'}>
                        <MenuItem>Register</MenuItem>
                        </Link>
                        <Link to={'/login'}>
                        <MenuItem>Login</MenuItem>
                        </Link>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                      </MenuList>
                    </Menu>
                    </Flex>



              </Box>
            </Box>
        )}
    

        {role === ROLES.MGA &&  (
                <Box position={'fixed'} w={'100%'} zIndex={'999'}>
                <Box boxShadow='rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;'>
                  <Flex display={['none','none','flex']} p='2px 30px' justifyContent={'space-between'} alignItems={'center'} background={'rgb(248 248 251)'} color={'rgb(119 119 119)'} fontSize={'13px'} fontFamily={'Poppins, sans-serif'}>
                  <Flex fontSize={'13px'} fontWeight={'400'} alignItems={'center'} gap={'25px'}>
                <a href='https://www.linkedin.com/company/103296109' target='_blank'>
                  <FaLinkedin />
                  </a>
                  <a href='https://www.instagram.com/travelmedicare/' target='_blank'>
                    <FaInstagramSquare />
                  </a>
                  <a href='https://x.com/TravelMedicare' target='_blank'>
                  <FaTwitter />
                  </a>
                </Flex>
                    <Text textAlign={'center'}>Compare Rates to get best quotes.</Text>
                    <Flex alignItems={'center'} gap={'45px'}>
                      <Flex cursor={'pointer'} _hover={{color:primaryBackgroundColor}} alignItems={'center'} gap={'7px'}>
                        <MdEmail />
                        <Text>support@travelmedicare.com</Text>
                      </Flex>
                      <Flex cursor={'pointer'} _hover={{color:primaryBackgroundColor}} alignItems={'center'} gap={'7px'}>
                      <IoCallSharp />
                      <Text>1844-844-3272</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex
                    background={'white'}
                    color={'rgb(75 89 102)'}
                    width={'100%'}
                    p={'10px'}
                    fontSize={'14px'}
                    fontFamily={'Poppins, sans-serif'}
                    justifyContent={'space-between'}
                    display={['none','none','flex']}
                  >
                    <Box>
                      <Link to={'/'}>
                        <Image
                          style={{ width: '80%' }}
                          src='https://travelmedicare.com/public/users/images/logo.png'
                        />
                      </Link>
                    </Box>
                    <Flex gap={'60px'}>
                      <Flex gap={'30px'} alignItems={'center'}>
                        <Link to='/dashboard'>
                        <Text _hover={{color:buttonBackgroundColor}}>Get Quote</Text>
                        </Link>
                        <Link to='/all-quotes'>
                        <Text _hover={{color:buttonBackgroundColor}}>My Quotes</Text>
                        </Link>
                        <Link to='/advisor-profile'>
                        <Text _hover={{color:buttonBackgroundColor}}>Profile</Text>
                        </Link>
                      </Flex>
                      <Flex alignItems={'center'}>
                          <Button onClick={handleLogout}
                            _hover={{
                              background: buttonHoverBackground,
                              color: buttonHoverColor
                            }}
                            size={'sm'}
                            backgroundColor={buttonBackgroundColor}
                            color={buttonColor}
                            fontFamily={'Poppins, sans-serif'}
                            fontWeight={'500'}
                            fontSize={'13px'}
                          >
                          Logout
                          </Button>
                      </Flex>
                    </Flex>
                  </Flex>




                {/* ------------------- mobilee -------------------- */}

                <Flex zIndex={"9999999"} background={"white"}
                          position={"fixed"}
                          w={"100%"}
                          p={"10px"}
                          justifyContent={"space-between"}
                          boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px" display={['flex','none','none']}>
                        <Box w={["48%","48%","18%"]}>
                            <Link to={"/"}>
                              <Image
                                w={"70%"}
                                src="https://travelmedicare.com/public/users/images/logo.png"
                              />
                            </Link>
                          </Box>
                          <Menu>
                          <MenuButton as={Button} _hover={{background:'white'}} background={'white'}>
                          <IconButton _hover={{background:'white'}} background={'white'} icon={<MdMenu />} aria-label />
                          </MenuButton>
                          <MenuList>
                            <Link to={'/dashboard'}>
                            <MenuItem>Get Quotes</MenuItem>
                            </Link>
                            <Link to={'/all-quotes'}>
                            <MenuItem>My Quotes</MenuItem>
                            </Link>
                            <Link to={'/advisor-profile'}>
                            <MenuItem>Profile</MenuItem>
                            </Link>
                          </MenuList>
                        </Menu>
                        </Flex>



                  </Box>
                </Box>
            )}


        {role === ROLES.CUSTOMER  &&  (
                <Box position={'fixed'} w={'100%'} zIndex={'999'}>
                <Box boxShadow='rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;'>
                  <Flex display={['none','none','flex']} p='2px 30px' justifyContent={'space-between'} alignItems={'center'} background={'rgb(248 248 251)'} color={'rgb(119 119 119)'} fontSize={'13px'} fontFamily={'Poppins, sans-serif'}>
                  <Flex fontSize={'13px'} fontWeight={'400'} alignItems={'center'} gap={'25px'}>
                <a href='https://www.linkedin.com/company/103296109' target='_blank'>
                  <FaLinkedin />
                  </a>
                  <a href='https://www.instagram.com/travelmedicare/' target='_blank'>
                    <FaInstagramSquare />
                  </a>
                  <a href='https://x.com/TravelMedicare' target='_blank'>
                  <FaTwitter />
                  </a>
                </Flex>
                    <Text textAlign={'center'}>Compare Rates to get best quotes.</Text>
                    <Flex alignItems={'center'} gap={'45px'}>
                      <Flex cursor={'pointer'} _hover={{color:primaryBackgroundColor}} alignItems={'center'} gap={'7px'}>
                        <MdEmail />
                        <Text>support@travelmedicare.com</Text>
                      </Flex>
                      <Flex cursor={'pointer'} _hover={{color:primaryBackgroundColor}} alignItems={'center'} gap={'7px'}>
                      <IoCallSharp />
                      <Text>1844-844-3272</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex
                    background={'white'}
                    color={'rgb(75 89 102)'}
                    width={'100%'}
                    p={'10px'}
                    fontSize={'14px'}
                    fontFamily={'Poppins, sans-serif'}
                    justifyContent={'space-between'}
                    display={['none','none','flex']}
                  >
                    <Box>
                      <Link to={'/'}>
                        <Image
                          style={{ width: '80%' }}
                          src='https://travelmedicare.com/public/users/images/logo.png'
                        />
                      </Link>
                    </Box>
                    <Flex gap={'60px'}>
                      <Flex gap={'30px'} alignItems={'center'}>
                        <Link to='/dashboard'>
                        <Text _hover={{color:buttonBackgroundColor}}>Get Quote</Text>
                        </Link>
                        <Link to='/all-quotes'>
                        <Text _hover={{color:buttonBackgroundColor}}>My Quotes</Text>
                        </Link>
                        <Link to='/customer-profile'>
                        <Text _hover={{color:buttonBackgroundColor}}>Profile</Text>
                        </Link>
                        {/* <Link to='/visitor-compare'>
                        <Text _hover={{color:buttonBackgroundColor}}>Logout</Text>
                        </Link> */}
                        {/* <Link to='/student-compare'>
                        <Text _hover={{color:buttonBackgroundColor}}>Student Travel Insurnace</Text>
                        </Link> */}
                      </Flex>
                      <Flex alignItems={'center'}>
                          <Button onClick={handleLogout}
                            _hover={{
                              background: buttonHoverBackground,
                              color: buttonHoverColor
                            }}
                            size={'sm'}
                            backgroundColor={buttonBackgroundColor}
                            color={buttonColor}
                            fontFamily={'Poppins, sans-serif'}
                            fontWeight={'500'}
                            fontSize={'13px'}
                          >
                          Logout
                          </Button>
                      </Flex>
                    </Flex>
                  </Flex>




                {/* ------------------- mobilee -------------------- */}

                <Flex zIndex={"9999999"} background={"white"}
                          position={"fixed"}
                          w={"100%"}
                          p={"10px"}
                          justifyContent={"space-between"}
                          boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px" display={['flex','none','none']}>
                        <Box w={["48%","48%","18%"]}>
                            <Link to={"/"}>
                              <Image
                                w={"70%"}
                                src="https://travelmedicare.com/public/users/images/logo.png"
                              />
                            </Link>
                          </Box>
                          <Menu>
                          <MenuButton as={Button} _hover={{background:'white'}} background={'white'}>
                          <IconButton _hover={{background:'white'}} background={'white'} icon={<MdMenu />} aria-label />
                          </MenuButton>
                          <MenuList>
                            <Link to={'/travel-compare'}>
                            <MenuItem>Travel Insurance</MenuItem>
                            </Link>
                            <Link to={'/supervisa-compare'}>
                            <MenuItem>Supervisa Insurance</MenuItem>
                            </Link>
                            <Link to={'/visitor-compare'}>
                            <MenuItem>Visitor Insurance</MenuItem>
                            </Link>
                            <Link to={'/student-compare'}>
                            <MenuItem>Student Insurance</MenuItem>
                            </Link>
                            <Link to={'/register'}>
                            <MenuItem>Register</MenuItem>
                            </Link>
                            <Link to={'/login'}>
                            <MenuItem>Login</MenuItem>
                            </Link>
                            <Link to={'/support'}>
                            <MenuItem>Support</MenuItem>
                            </Link>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                          </MenuList>
                        </Menu>
                        </Flex>



                  </Box>
                </Box>
            )}

        {role===ROLES.AGA &&  (
              <Box position={'fixed'} w={'100%'} zIndex={'999'}>
              <Box boxShadow='rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;'>
                <Flex display={['none','none','flex']} p='2px 30px' justifyContent={'space-between'} alignItems={'center'} background={'rgb(248 248 251)'} color={'rgb(119 119 119)'} fontSize={'13px'} fontFamily={'Poppins, sans-serif'}>
                <Flex fontSize={'13px'} fontWeight={'400'} alignItems={'center'} gap={'25px'}>
              <a href='https://www.linkedin.com/company/103296109' target='_blank'>
                <FaLinkedin />
                </a>
                <a href='https://www.instagram.com/travelmedicare/' target='_blank'>
                  <FaInstagramSquare />
                </a>
                <a href='https://x.com/TravelMedicare' target='_blank'>
                <FaTwitter />
                </a>
              </Flex>
                  <Text textAlign={'center'}>Compare Rates to get best quotes.</Text>
                  <Flex alignItems={'center'} gap={'45px'}>
                    <Flex cursor={'pointer'} _hover={{color:primaryBackgroundColor}} alignItems={'center'} gap={'7px'}>
                      <MdEmail />
                      <Text>support@travelmedicare.com</Text>
                    </Flex>
                    <Flex cursor={'pointer'} _hover={{color:primaryBackgroundColor}} alignItems={'center'} gap={'7px'}>
                    <IoCallSharp />
                    <Text>1844-844-3272</Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex
                  background={'white'}
                  color={'rgb(75 89 102)'}
                  width={'100%'}
                  p={'10px'}
                  fontSize={'14px'}
                  fontFamily={'Poppins, sans-serif'}
                  justifyContent={'space-between'}
                  display={['none','none','flex']}
                >
                  <Box>
                    <Link to={'/'}>
                      <Image
                        style={{ width: '80%' }}
                        src='https://travelmedicare.com/public/users/images/logo.png'
                      />
                    </Link>
                  </Box>
                  <Flex gap={'60px'}>
                    <Flex gap={'30px'} alignItems={'center'}>
                      <Link to='/dashboard'>
                      <Text _hover={{color:buttonBackgroundColor}}>Get Quote</Text>
                      </Link>
                      <Link to='/all-quotes'>
                      <Text _hover={{color:buttonBackgroundColor}}>My Quotes</Text>
                      </Link>
                      <Link to='/advisor-profile'>
                      <Text _hover={{color:buttonBackgroundColor}}>Profile</Text>
                      </Link>
                      {/* <Link to='/visitor-compare'>
                      <Text _hover={{color:buttonBackgroundColor}}>Logout</Text>
                      </Link> */}
                      {/* <Link to='/student-compare'>
                      <Text _hover={{color:buttonBackgroundColor}}>Student Travel Insurnace</Text>
                      </Link> */}
                    </Flex>
                    <Flex alignItems={'center'}>
                        <Button onClick={handleLogout}
                          _hover={{
                            background: buttonHoverBackground,
                            color: buttonHoverColor
                          }}
                          size={'sm'}
                          backgroundColor={buttonBackgroundColor}
                          color={buttonColor}
                          fontFamily={'Poppins, sans-serif'}
                          fontWeight={'500'}
                          fontSize={'13px'}
                        >
                        Logout
                        </Button>
                    </Flex>
                  </Flex>
                </Flex>




              {/* ------------------- mobilee -------------------- */}

              <Flex zIndex={"9999999"} background={"white"}
                        position={"fixed"}
                        w={"100%"}
                        p={"10px"}
                        justifyContent={"space-between"}
                        boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px" display={['flex','none','none']}>
                      <Box w={["48%","48%","18%"]}>
                          <Link to={"/"}>
                            <Image
                              w={"70%"}
                              src="https://travelmedicare.com/public/users/images/logo.png"
                            />
                          </Link>
                        </Box>
                        <Menu>
                        <MenuButton as={Button} _hover={{background:'white'}} background={'white'}>
                        <IconButton _hover={{background:'white'}} background={'white'} icon={<MdMenu />} aria-label />
                        </MenuButton>
                        <MenuList>
                          <Link to={'/travel-compare'}>
                          <MenuItem>Travel Insurance</MenuItem>
                          </Link>
                          <Link to={'/supervisa-compare'}>
                          <MenuItem>Supervisa Insurance</MenuItem>
                          </Link>
                          <Link to={'/visitor-compare'}>
                          <MenuItem>Visitor Insurance</MenuItem>
                          </Link>
                          <Link to={'/student-compare'}>
                          <MenuItem>Student Insurance</MenuItem>
                          </Link>
                          <Link to={'/register'}>
                          <MenuItem>Register</MenuItem>
                          </Link>
                          <Link to={'/login'}>
                          <MenuItem>Login</MenuItem>
                          </Link>
                          <Link to={'/support'}>
                          <MenuItem>Support</MenuItem>
                          </Link>
                          <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </MenuList>
                      </Menu>
                      </Flex>
                </Box>
              </Box>
          )}



        {role===ROLES.ADVISOR &&  (
            <Box position={'fixed'} w={'100%'} zIndex={'999'}>
            <Box boxShadow='rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;'>
              <Flex display={['none','none','flex']} p='2px 30px' justifyContent={'space-between'} alignItems={'center'} background={'rgb(248 248 251)'} color={'rgb(119 119 119)'} fontSize={'13px'} fontFamily={'Poppins, sans-serif'}>
              <Flex fontSize={'13px'} fontWeight={'400'} alignItems={'center'} gap={'25px'}>
            <a href='https://www.linkedin.com/company/103296109' target='_blank'>
              <FaLinkedin />
              </a>
              <a href='https://www.instagram.com/travelmedicare/' target='_blank'>
                <FaInstagramSquare />
              </a>
              <a href='https://x.com/TravelMedicare' target='_blank'>
              <FaTwitter />
              </a>
            </Flex>
                <Text textAlign={'center'}>Compare Rates to get best quotes.</Text>
                <Flex alignItems={'center'} gap={'45px'}>
                  <Flex cursor={'pointer'} _hover={{color:primaryBackgroundColor}} alignItems={'center'} gap={'7px'}>
                    <MdEmail />
                    <Text>support@travelmedicare.com</Text>
                  </Flex>
                  <Flex cursor={'pointer'} _hover={{color:primaryBackgroundColor}} alignItems={'center'} gap={'7px'}>
                  <IoCallSharp />
                  <Text>1844-844-3272</Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                background={'white'}
                color={'rgb(75 89 102)'}
                width={'100%'}
                p={'10px'}
                fontSize={'14px'}
                fontFamily={'Poppins, sans-serif'}
                justifyContent={'space-between'}
                display={['none','none','flex']}
              >
                <Box>
                  <Link to={'/'}>
                    <Image
                      style={{ width: '80%' }}
                      src='https://travelmedicare.com/public/users/images/logo.png'
                    />
                  </Link>
                </Box>
                <Flex gap={'60px'}>
                  <Flex gap={'30px'} alignItems={'center'}>
                    <Link to='/dashboard'>
                    <Text _hover={{color:buttonBackgroundColor}}>Get Quote</Text>
                    </Link>
                    <Link to='/all-quotes'>
                    <Text _hover={{color:buttonBackgroundColor}}>My Quotes</Text>
                    </Link>
                    <Link to='/advisor-profile'>
                    <Text _hover={{color:buttonBackgroundColor}}>Profile</Text>
                    </Link>
                    {/* <Link to='/visitor-compare'>
                    <Text _hover={{color:buttonBackgroundColor}}>Logout</Text>
                    </Link> */}
                    {/* <Link to='/student-compare'>
                    <Text _hover={{color:buttonBackgroundColor}}>Student Travel Insurnace</Text>
                    </Link> */}
                  </Flex>
                  <Flex alignItems={'center'}>
                      <Button onClick={handleLogout}
                        _hover={{
                          background: buttonHoverBackground,
                          color: buttonHoverColor
                        }}
                        size={'sm'}
                        backgroundColor={buttonBackgroundColor}
                        color={buttonColor}
                        fontFamily={'Poppins, sans-serif'}
                        fontWeight={'500'}
                        fontSize={'13px'}
                      >
                       Logout
                      </Button>
                  </Flex>
                </Flex>
              </Flex>




            {/* ------------------- mobilee -------------------- */}

            <Flex zIndex={"9999999"} background={"white"}
                      position={"fixed"}
                      w={"100%"}
                      p={"10px"}
                      justifyContent={"space-between"}
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px" display={['flex','none','none']}>
                    <Box w={["48%","48%","18%"]}>
                        <Link to={"/"}>
                          <Image
                            w={"70%"}
                            src="https://travelmedicare.com/public/users/images/logo.png"
                          />
                        </Link>
                      </Box>
                      <Menu>
                      <MenuButton as={Button} _hover={{background:'white'}} background={'white'}>
                      <IconButton _hover={{background:'white'}} background={'white'} icon={<MdMenu />} aria-label />
                      </MenuButton>
                      <MenuList>
                        <Link to={'/travel-compare'}>
                        <MenuItem>Travel Insurance</MenuItem>
                        </Link>
                        <Link to={'/supervisa-compare'}>
                        <MenuItem>Supervisa Insurance</MenuItem>
                        </Link>
                        <Link to={'/visitor-compare'}>
                        <MenuItem>Visitor Insurance</MenuItem>
                        </Link>
                        <Link to={'/student-compare'}>
                        <MenuItem>Student Insurance</MenuItem>
                        </Link>
                        <Link to={'/register'}>
                        <MenuItem>Register</MenuItem>
                        </Link>
                        <Link to={'/login'}>
                        <MenuItem>Login</MenuItem>
                        </Link>
                        <Link to={'/support'}>
                        <MenuItem>Support</MenuItem>
                        </Link>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                      </MenuList>
                    </Menu>
                    </Flex>
              </Box>
            </Box>
        )}
   
    </Box>
  );
}

export default Header;
