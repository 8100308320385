import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Text,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  FormControl,
  InputRightElement,
  Box,
  FormLabel,
  InputGroup,
  Input,
  useToast,
  useTheme,
} from '@chakra-ui/react';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor } from "../../Utils/UniversalColors";
import { customerLoginList } from "../Register/dependencies/action";
import { storage } from "../../dependencies/store/storage";
import { advisorLoginForgetPassword, advisorLoginList } from "../Login/dependencies/action";

const LoginModal = ({ isOpen, onClose,onSubmit }) => {
    const navigate = useNavigate();
    const toast = useToast();
    const dispatch = useDispatch();
    const theme=useTheme()
    const [isHCaptchaLoaded, setIsHCaptchaLoaded] = useState(false);
    const [show, setShow] = useState(false);
    const [values, setValues] = useState({
      username: "",
      password: "",
    });
    const [errors, setErrors] = useState({
      username: "",
      password: "",
    });
  
    const handleClick = () => setShow(!show);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setValues({ ...values, [name]: value });
      setErrors({ ...errors, [name]: "" });
    
      // Validate email format
      if (name === "username" && !/\S+@\S+\.\S+/.test(value)) {
        setErrors({ ...errors, [name]: "Please enter a valid email address" });
      }
    
      // Validate password length
      if (name === "password" && value.length < 6) {
        setErrors({
          ...errors,
          [name]: "Password must be at least 6 characters long",
        });
      }
    };
    const handleCustomerLogin = async () => {
      const { username, password } = values;
      let newErrors = { ...errors };
    
      // Validate email
      if (!/\S+@\S+\.\S+/.test(username)) {
        newErrors.username = "Please enter a valid email address";
      }
    
      // Validate password
      if (password.length === 0) {
        newErrors.password = "Password is required";
      }
    
      if (Object.values(newErrors).some((error) => error !== "")) {
        setErrors(newErrors);
        return;
      }
    
      dispatch(customerLoginList(values)).then((res) => {
        const { status, data } = res;
        if (status === 200) {
          storage.setUser(data);
          alert("Login Success")
          onSubmit()
        }
      });
    };

    
    const handleAdvisorLogin = async () => {
      const { username, password } = values;
      let newErrors = { ...errors };
      // Validate email
      if (!/\S+@\S+\.\S+/.test(username)) {
        newErrors.username = "Please enter a valid email address";
      }
      // Validate password
      if (password.length === 0) {
        newErrors.password = "Password is required";
      }
      if (Object.values(newErrors).some((error) => error !== "")) {
        setErrors(newErrors);
        return;
      }
      dispatch(advisorLoginList(values)).then((res) => {
        const { status, data } = res;
        if (status === 200) {
          storage.setUser(data);
          alert("Login Success. Enter details to proceed")
          onSubmit()
        }
      });
  };
  
  


return (
    <>
    <Modal isOpen={isOpen} size={'md'} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={'14px'}>Login Required</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <Box w={["95%","45%","95%"]} borderRadius={'10px'} background={'white'} p={5} textAlign={"center"}>
        <Tabs variant="soft-rounded" colorScheme="teal">
          <TabList>
            <Tab fontSize={'14px'}>Advisor</Tab>
            <Tab fontSize={'14px'}>Customer</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
        <Box>
        <FormControl isRequired w={["95%","80%","98%"]} m={"auto"} fontSize={'14px'}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter email"
                  name="username"
                  onChange={handleChange}
                  size={'sm'}
                />
                <Text color="red.500">{errors.username}</Text>
                <FormLabel pt={"10px"}>Password</FormLabel>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    placeholder="Enter password"
                    name="password"
                    onChange={handleChange}
                    size={'sm'}
                  />
                  <InputRightElement width="3.5rem">
                    <Button h="1.15rem" size="xs" onClick={handleClick}>
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Text color="red.500">{errors.password}</Text>
                <Link to='/advisorForgetPassword'>
            <Text
              textAlign={"left"}
              fontSize={"14px"}
              color={"blue"}
              mt={"5px"}
            >
              Forgot Password?
            </Text>
            </Link>
                <Button
                  mt={4}
                  width="100%"
                  onClick={handleAdvisorLogin}
                  color={buttonColor}
                  backgroundColor={buttonBackgroundColor}
                  _hover={{background:buttonHoverBackground,color:buttonHoverColor}}
                  size={'sm'}
                >
                Login
                </Button>
              </FormControl>
        </Box>
              
            </TabPanel>
            <TabPanel>
              <FormControl isRequired w={["95%","80%","98%"]} m={"auto"}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter email"
                  name="username"
                  onChange={handleChange}
                  size={'sm'}
                />
                <Text color="red.500">{errors.username}</Text>
                <FormLabel pt={"10px"}>Password</FormLabel>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    placeholder="Enter password"
                    name="password"
                    onChange={handleChange}
                    size={'sm'}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.15rem" size="sm" onClick={handleClick}>
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Text color="red.500">{errors.password}</Text>
                <Link to='/customerForgetPassword'>
            <Text
              textAlign={"left"}
              fontSize={"14px"}
              color={"blue"}
              mt={"5px"}
            >
              Forgot Password?
            </Text>
            </Link>
                <Button
                  mt={4}
                  width="100%"
                  onClick={handleCustomerLogin}
                  color={buttonColor}
                  backgroundColor={buttonBackgroundColor}
                  _hover={{background:buttonHoverBackground,color:buttonHoverColor}}
                  size={'sm'}
                >
                Login
                </Button>
              </FormControl>
            </TabPanel>
          </TabPanels>
        </Tabs>
        
      </Box>
        </ModalBody>
       
      </ModalContent>
    </Modal>
    </>
    
  );
};

export default LoginModal;
