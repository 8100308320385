import { ADVISOR_LOGIN, FORGET_PASSWORD } from "../../../constants/ApplicationUrl";
import Api from "../../../dependencies/utils/Api";

export const LoginService = {
    advisorLoginList(params) {
        return Api.POST(ADVISOR_LOGIN, params).then((response) => {
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

advisorLoginForgetPassword(params){
    return Api.POST(FORGET_PASSWORD,params).then((response)=>{
      const {data,status} =response;
      if(status === 200 || status === 201){
        return {data,status}
      }else{
        const {data: {message} = {}} = response
        return {message, status}
      }
    })
  }
}