import React, { useState } from 'react'
import { Box,Flex,FormControl,Input,Button,FormLabel,Image,Text, Heading, color,InputGroup,InputRightElement, useToast, useTheme } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from "react-redux";

import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { advisorSignupList } from './dependencies/action';
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor } from '../../Utils/UniversalColors';

const AdvisorRegister = () => {
     const toast = useToast();
     const navigate=useNavigate()
    const dispatch = useDispatch();
    const theme=useTheme()
    const [captchaValue, setCaptchaValue] = useState(null);
    const [agreedToTerms, setAgreedToTerms] = useState(false); 
    const [username, setUsername] = useState("");
   const {t} =useTranslation()
    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };
    const handleCheckboxChange = (e) => {
      setAgreedToTerms(e.target.checked);
      // Concatenate the username with the terms message when checkbox is checked
      const terms = "agreed to the terms and conditions."; // Move the terms declaration here
    // Concatenate the username with the terms message when checkbox is checked
    if (e.target.checked) {
        const updatedUsername = `${values.firstName} ${values.lastName} ${terms}`;
        setUsername(updatedUsername);
        console.log("username", updatedUsername);
    } else {
        setUsername(`${values.firstName} ${values.lastName}`);
    }
  };

  const [show, setShow] = useState(false);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleClick = () => setShow(!show);
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleLogin = async() => {
    // try{
    const { firstName, lastName, email, password, confirmPassword } = values;
    let newErrors = { ...errors };
    if (firstName.length === 0) {
      newErrors.firstName = "Please enter your first name.";
    }
    if (lastName.length === 0) {
      newErrors.lastName = "Please enter your last name.";
    }
    if (email.length === 0 || !validateEmail(email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!validatePassword(password)) {
      newErrors.password = "Password should be at least 6 characters long.";
    }
    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }
    if (!agreedToTerms) {
      // If terms are not agreed
      // You can display an error message or handle it as you prefer
      return;
  }

    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      return;
    }
    await dispatch(advisorSignupList(values)).then((res)=>{
      if(res===200 || res===201){
        navigate('/advisorLogin')
      }else{
        console.log("Something went wrong")
      }
    })
  }

  

return (
    <Box m="auto">
    <Flex direction={['column','column','row','row']} pt={'60px'}>
        <Box w={['95%','95%','50%','50%']} p={['10px','30px','70px']} h={'100vh'} display={["none","none","block"]} color={'white'} background={'#272939'} backgroundImage={'https://travelmedicare.com/public/users/images/bg-01.jpg'} backgroundSize={'cover'} backgroundPosition={'center'}>
            {/* <Image src='https://experiortravel.com/public/users/images/bg-02.jpg'/> */}
            </Box>
        <Box p={5} w={['99%','95%','50%','50%']} pt={'40px'} textAlign={'center'}>
          <Flex justifyContent={'center'}>
          {/* <Image w={'130px'} src={'https://travelmedicare.com/public/users/images/logo.png'}/> */}
          </Flex>
          <Text fontFamily={'poppins'} mt={['30px','1px','10px','20px']} pb={['20px','20px','80px','20px']}>{t('Advisor Sign Up')}</Text>
        <FormControl isRequired w={['90%','90%','70%']} m={'auto'}>
            <Flex justifyContent={'space-between'}>
            <Box w={'48%'}>
                 <FormLabel fontSize={'13px'}>{t('First Name')}</FormLabel>
                 <Input size={'sm'} type="text" placeholder={t('First Name')} name='firstName' onChange={handleChange}/>
                 <Text textAlign="start" color="red.500">{errors.firstName}</Text>
            </Box>
            <Box w={'48%'}>
                 <FormLabel fontSize={'13px'}>{t('Last Name')}</FormLabel>
                 <Input size={'sm'} type="text" placeholder={t('Last Name')} name='lastName' onChange={handleChange}/>
                 <Text textAlign="start" color="red.500">{errors.lastName}</Text>
            </Box>
            </Flex>
                <FormLabel fontSize={'13px'}>{t('Email')}</FormLabel>
                <Input size={'sm'} type="email" placeholder= {t('Enter Email')} name='email' onChange={handleChange}/>
                <Text textAlign="start" color="red.500">{errors.email}</Text>
                <FormLabel fontSize={'13px'}>{t('Password')}</FormLabel>
                
               <InputGroup size="md">
                    <Input size={'sm'}
                      pr="4.5rem"
                      type={show ? "text" : "password"}
                      placeholder={t("Enter password")}
                      name="password"
                      onChange={handleChange}
                  />
                  <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? (t("Hide")) : (t("Show"))}
                      </Button>
                  </InputRightElement>
              </InputGroup>
              <Text textAlign="start" color="red.500">{errors.password}</Text>
              <FormLabel fontSize={'13px'}>{t('Confirm Password')}</FormLabel>
              <Input size={'sm'} type="password" placeholder={t('Confirm Password')} name='confirmPassword' onChange={handleChange} />
              <Box mt={4}>
                        <ReCAPTCHA
                            sitekey="6LeywCIcAAAAAFPEJdniwD04W6HSNs88jC8HmWT-"
                            onChange={handleCaptchaChange}
                        />
                    </Box>

                    <Flex align="center"> {/* Flex container for checkbox and label */}
                    <input
                        type="checkbox"
                        size="sm" // Set the size of the checkbox to small
                        checked={agreedToTerms}
                        onChange={handleCheckboxChange}
                        name="agreedToTerms"
                    />
                    <FormLabel ml={2} mb={0} fontSize={'13px'}> {/* Use margin to adjust spacing */}
                        {t('I agree to website')} <Link to="/terms_condition">{t('terms and conditions')}</Link>
                    </FormLabel>
                    
                </Flex>
                {!agreedToTerms && (
                    <Text fontSize={'13px'} textAlign="start" color="red.500">
                        {t('You must agree to the terms and conditions.')}
                    </Text>
                )}
              <Text textAlign="start" color="red.500">{errors.confirmPassword}</Text>
              <Button mt={4} width="100%" onClick={handleLogin} size={'sm'} background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}}>Sign up</Button>
          </FormControl>
          <Text mt={'35px'}>{t('Already have an account')}</Text>
          <Link to={'/login'} color='blue'>
              <Text color={'blue'}>{t('Login')}</Text>
          </Link>
      </Box>
  </Flex>
</Box>
  )
}

export default AdvisorRegister;
