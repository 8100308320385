import React, { useEffect, useState } from 'react'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { MdDoubleArrow } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { Link, useLocation } from 'react-router-dom';




function Footer() {






return (
  <Box>    
      <hr />
    <Box mt={'40px'} pt={'10px'} pb={'15px'} color={'black'}>
      <Flex direction={['column','column','row']} w={'90%'} m={'auto'} pb={'30px'}>
          <Box fontFamily={"Poppins, sans-serif"} color={'rgb(119 119 119)'} w={['95%','90%','30%']} m={'auto'} fontSize={'14px'}>
            <Text pb={'10px'} color={'rgb(75 89 102)'} fontFamily={'Poppins, sans-serif'} fontSize={'16px'}>{('Contact')} </Text>
            <Box>
              <Flex p={'7px'} fontSize={'12px'} gap={'15px'}>
                <Flex justifyContent={'center'} alignItems={'center'} width={'20px'} fontSize={'20px'}>
                  <FaLocationDot />
                </Flex>
                <Text alignSelf={'center'} fontSize={'14px'}>7895 Tranmere Dr Unit #16 Suite 3 Mississauga ON L5S 1V9</Text>
              </Flex>
              <Flex p={'7px'} gap={'15px'}>
                <Flex justifyContent={'center'} alignItems={'center'} width={'20px'} fontSize={'20px'}>
                  <FaPhoneAlt />
                </Flex>
                <Text alignSelf={'center'}>1844-844-3272</Text>
              </Flex>
              <Flex p={'7px'} gap={'15px'}>
                <Flex justifyContent={'center'} alignItems={'center'} width={'20px'} fontSize={'20px'}>
                  <MdOutlineMail />
                </Flex>
                <Text alignSelf={'center'}>info@riskcare.com</Text>
              </Flex>
            </Box>
          </Box>

          <Box fontFamily={"Poppins, sans-serif"} color={'rgb(119 119 119)'} w={['95%','90%','20%']} m={'auto'}>
            <Text pb={'10px'} color={'rgb(75 89 102)'} fontFamily={'Poppins, sans-serif'}>Discover TM</Text>
            <Box fontSize={'14px'}>
              <Flex p={'7px'} gap={'15px'}>
                <Link to='/about-us'>
                  <Text alignSelf={'center'}>About Us</Text>
                </Link>
              </Flex>
              <Flex p={'7px'} gap={'15px'}>
                <Link to='/enquiry'>
                  <Text alignSelf={'center'}>Enquiry</Text>
                </Link>
              </Flex>
              <Flex p={'7px'} gap={'15px'}>
              
                <Link to='/privacy_policy'>
                  <Text alignSelf={'center'}>Privacy Policy</Text>
                </Link>
              </Flex>
              
            </Box>
          </Box>

          <Box fontFamily={"Poppins, sans-serif"} color={'rgb(119 119 119)'} w={['95%','90%','20%']} m={'auto'}>
            <Text pb={'10px'} color={'rgb(75 89 102)'} fontFamily={'Assistant,sans-serif'}>Other</Text>
            <Box fontSize={'14px'}>
              <Flex p={'7px'} gap={'15px'}>
                <Text alignSelf={'start'}>News & Blogs</Text>
              </Flex>
              <Flex p={'7px'} gap={'15px'}>
                <Link to='/terms_condition'>
                  <Text alignSelf={'center'}>Terms & Condition</Text>
                </Link>
              </Flex>
              <Flex p={'7px'} gap={'15px'}>
                <Text alignSelf={'center'}>  </Text>
              </Flex>
              <Flex p={'7px'} gap={'15px'}>
                <Text alignSelf={'center'}>  </Text>
              </Flex>
            </Box>
          </Box>


          <Box fontFamily={"Poppins, sans-serif"} color={'rgb(119 119 119)'} w={['95%','90%','20%']} m={'auto'}>
            <Text pb={'28px'} color={'rgb(75 89 102)'} fontFamily={'Poppins, sans-serif'}>Social Media</Text>
            <Flex justifyContent={'space-around'} w={'70%'} fontSize={'22px'} pb={'15px'}>

              <FaFacebook />
              <a href="https://www.instagram.com/travelmedicare/" target='_blank'>
                <FaInstagramSquare />
              </a>
              <FaTwitter />
              <FaLinkedin />
            </Flex>
            <Flex>
              {/* <Image h={'50%'} src='https://verify.authorize.net/anetseal/images/secure90x72.gif' /> */}
            </Flex>
          </Box>
        </Flex>

        <Box color={'rgb(119 119 119)'} background={'rgb(248 248 251)'} borderTop={'1px solid #e4e4e4'} fontSize={'13px'} fontFamily={'Poppins, sans-serif'}>
          <Text pt={'5px'} pl={'20px'}>Copyright  2019 Riskcare. All Rights Reserved</Text>
        </Box>
    </Box>
  </Box>

  )
}

export default Footer