import React from 'react';

const bytesToBase64 = (bytes) => {
  let binaryString = '';
  
  if (typeof bytes === 'string') {
    bytes = Uint8Array.from(atob(bytes), c => c.charCodeAt(0));
  } else if (Array.isArray(bytes)) {
    bytes = new Uint8Array(bytes);
  } else if (!(bytes instanceof Uint8Array)) {
    console.error('Invalid byte data:', bytes);
    return null;
  }

  binaryString = bytes.reduce((data, byte) => data + String.fromCharCode(byte), '');

  return `data:image/jpeg;base64,${btoa(binaryString)}`;
};

const ImageItem = ({ imageBytes }) => {
  if (!imageBytes || imageBytes.length === 0) {
    console.error('Invalid image data:', imageBytes);
    return null;
  }

  const imageUrl = bytesToBase64(imageBytes);

  return <img src={imageUrl} alt="Item" style={{ width: '100%' }} />;
};

export default ImageItem;
