import React, { useEffect, useState } from 'react';
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor, primaryColor } from '../../Utils/UniversalColors/index';
import { Flex, Box, Text, Input, Textarea, Toast, Button, Checkbox, Table, Thead, Tr, Th, Tbody, Td, Select, useMediaQuery, TableContainer } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux';
import { advisorProfile, advisorRefrence } from '../Dependencies/action';
import { isLoading } from '../../constants/actionConstants';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ADVISOR_CORPORATION_DETAILS, ADVISOR_LICENCE_DETAILS, ADVISOR_PERSONAL_DETAILS, API_URL } from '../../constants/ApplicationUrl';
import Api from '../../dependencies/utils/Api';
import { validateMobileNumber } from './Validation';


const AdvisorProfile = () => {
  const [activeTab, setActiveTab] = useState('personal');
  const [personalData, setPersonalData] = useState();
  const [licenseData, setLicenseData] = useState();
  const [corporationData, setCorporationData] = useState();
  const [referenceData, setReferenceData] = useState();
  const [commonData, setCommonData] = useState("")
  const [isProfileComplete,setIsProfileComplete]=useState(0)
  const dispatch = useDispatch();
  const [formData, setFormData] = useState("");
  const [approved, setApproved] = useState(0)

  useEffect(() => {
    getAdvisorProfile()
  }, [dispatch]);

  const getAdvisorProfile=()=>{
    dispatch(advisorProfile())
    .then((res) => {
      setCommonData(res);
      setApproved(res.isApproved)
      setActiveTab(res.profileCompleteStatus===null?'personal':res.profileCompleteStatus===1?'license':res.profileCompleteStatus===2?'corporation':res.profileCompleteStatus===3?'reference':'personal')
      setIsProfileComplete(res.profileCompleteStatus)
      setFormData({
        // id: id,
        firstName: res?.firstName || '',
        lastName: res?.lastName || '',
        email: res?.email || '',
        phone: res?.phone || '',
        address: res?.address || '',
        website: res?.website || ""
      });
    })
    .catch((error) => {
      console.error("Error fetching advisor profile data:", error);

    });
  }

    const handleTabClick = (tab) => {
      if (tab) {
        setActiveTab(tab);
      }
    };

    const handleSaveData = (data) => {
      switch (activeTab) {
        case 'personal':
          setPersonalData(data);
          break;
        case 'license':
          setLicenseData(data);
          break;
        case 'corporation':
          setCorporationData(data);
          break;
        case 'reference':
          setReferenceData(data);
          break;
        default:
          break;
      }
    }

    const renderForm = () => {
      switch (activeTab) {
        case 'personal':
          return <PersonalDetailsForm onSave={handleSaveData} getAdvisorProfile={getAdvisorProfile} commonData={commonData} approved={approved} />;
        case 'license':
          return <LicenseDetailsForm commonData={commonData} getAdvisorProfile={getAdvisorProfile} isProfileComplete={isProfileComplete} onSave={handleSaveData}  approved={approved} />;
        case 'corporation':
          return <CorporationDetailsForm commonData={commonData} getAdvisorProfile={getAdvisorProfile} isProfileComplete={isProfileComplete} onSave={handleSaveData} approved={approved} />;
        case 'reference':
          return <ReferenceDetailsForm commonData={commonData} getAdvisorProfile={getAdvisorProfile} onSave={handleSaveData}  approved={approved} />;
        default:
          return null;
      }
    };

  return (
    <>
    {
      isProfileComplete===4 && approved!==1?
      <Box h={'70vh'} pt={'120px'}>
      <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' w={'80%'} m={'auto'} p={'20px'} mt={'20px'}>
      <Text textAlign={'center'} fontSize={'18px'} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}> Well done! you have completed your profile , please wait for profile approval !</Text>
    </Box>
      </Box>
      :
    <Box paddingTop={['60px','80px','80px']}>
      <Box w={'80%'} m={'auto'} pt={'10px'}>
        {approved === 1 ? '' :
          <Text>Welcome <b> {commonData.firstName} {commonData.lastName} </b> please complete your profile ! Follow the steps one by one</Text>
        }
        <Flex pt={['10px','20px','50px']} direction={['column','column','row']}>
          <Box w={['80%','60%','20%']} m={'auto'} h={['auto','auto','520px']}>
            <Flex direction={['row','row','column']} gap={'12px'} className="tablist">
              <Button fontSize={['10px','14px','14px']} style={{
                border: '1px solid grey', backgroundColor: activeTab === 'personal' ? "teal" : "#eee",
                color: activeTab === 'personal' ? '#ffffff' : 'black', borderRadius: '10px', border: 'none', cursor: 'pointer'
              }}
                onClick={() => handleTabClick('personal')}>1. Personal Details</Button>
              <Button fontSize={['10px','14px','14px']}
                style={{
                  backgroundColor: activeTab === 'license' ? "teal" : "#eee",
                  color: activeTab === 'license' ? '#ffffff' : 'black',
                  borderRadius: '10px',
                  border: 'none',
                }}
                disabled={isProfileComplete<1}
                onClick={() => handleTabClick('license')}
              >
                2. License Details
              </Button>
              </Flex>
              <Flex direction={['row','row','column']} gap={'12px'} className="tablist" mt={'15px'}>
              <Button fontSize={['10px','14px','14px']}
                style={{
                  backgroundColor: activeTab === 'corporation' ? "teal" : "#eee",
                  color: activeTab === 'corporation' ? '#ffffff' : 'black',
                  borderRadius: '10px',
                  border: 'none',
                  // cursor: isProfileComplete<2 ? 'pointer' : 'not-allowed'
                  }}
                disabled={isProfileComplete<2}
                onClick={() => handleTabClick('corporation')}
              >
                3. Corporation Details
              </Button>
              <Button fontSize={['10px','14px','14px']}
                style={{
                  backgroundColor: activeTab === 'reference' ? "teal" : "#eee",
                  color: activeTab === 'reference' ? '#ffffff' : 'black',
                  borderRadius: '10px',
                  border: 'none',
                }}
                disabled={isProfileComplete<3}
                onClick={() => handleTabClick('reference')}
              >
                4. Reference Details
              </Button>
              </Flex>
          </Box>
          <Box pt={['20px','20px','5px']} w={['95%','90%','70%']} mb={'20px'}>
            {renderForm()}
            <DisplayData activeTab={activeTab} data={{ personalData, licenseData, corporationData, referenceData }} />
          </Box>
        </Flex>
      </Box>
    </Box>
     }
  </>
  );
};


const DisplayData = ({ activeTab, data }) => {
  switch (activeTab) {
    case 'personal':
      return <pre>{JSON.stringify(data.personalData, null, 2)}</pre>;
    case 'license':
      return <pre>{JSON.stringify(data.licenseData, null, 2)}</pre>;
    case 'corporation':
      return <pre>{JSON.stringify(data.corporationData, null, 2)}</pre>;
    case 'reference':
      return <pre>{JSON.stringify(data.referenceData, null, 2)}</pre>;
    default:
      return null;
  }
};


const PersonalDetailsForm = ({ approved, onSave,getAdvisorProfile }) => {
  const dispatch = useDispatch();
  const { token, id } = JSON.parse(localStorage.getItem("code_dev1")) ||{}; 
  const [profileData, setProfileData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isProfileComplete,setIsProfileComplete]=useState(0)
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    website: '',
    fax: '',
  });
  const [formData, setFormData] = useState({
    id: id,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    website: "",
    fax: "",
  }); 

  useEffect(() => {
    dispatch(advisorProfile())
      .then((res) => {
        setProfileData(res);
        setIsProfileComplete(res.isProfileComplete)
        setFormData({
          id: id,
          firstName: res?.firstName || '',
          lastName: res?.lastName || '',
          email: res?.email || '',
          phone: res?.phone || '',
          address: res?.address || '',
          website: res?.website || ""
        });
      })
      .catch((error) => {
        console.error("Error fetching advisor profile data:", error);
      });
  }, [dispatch]);

const handleSave = () => {
  const hasErrors = Object.values(errors).some((error) => error);
  if (hasErrors) {
    console.error("Form has errors. Cannot save.");
    return;
  }
  if (formData.phone.length === 0 || !validateMobileNumber(formData.phone)) {
    alert('Please enter valid Phone');
    return;
  }
  if(!formData.phone){
    alert('Please enter phone')
    return;
  }
  if(!formData.address){
    alert('Please enter address')
    return;
  }
  axios.put(ADVISOR_PERSONAL_DETAILS,formData,{
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      ).then((res)=>{
          alert("Personal details saved successfully.")
          onSave();
          setShowModal(true);
          getAdvisorProfile()
        
      }).catch((err)=>{
        alert(err.response.data.message)
        console.log(err.response.data.message,'errr')
      })
      // Api.PUT(`${ADVISOR_PERSONAL_DETAILS}`,formData).then((res)=>{
      //   console.log(res,'reeeeeeeeeeeeeeee')
      // })
  // dispatch(advisorPersonalDetails(formData))
  //   .then((res) => {
  //     const { data, status, message } = res;
  //     console.log(res,'ressssssssssssss')
  //     if (status === 200 || status === 201) {
  //       toast("Personal Details updated successfully");
  //       onSave();
  //       setShowModal(true);
  //       getAdvisorProfile()
  //     } else if (status === 500) {
  //       toast(message || "Mobile number already exists");
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         phone: 'Phone number already exists',
  //       }));
  //     } else {
  //       console.error("Unexpected response status:", status);
  //       toast(message || "An unexpected error occurred");
  //     }
  //   })
  //   .catch((error) => {
  //     console.error("Error updating advisor personal details:", error);
  //     toast("An error occurred while updating advisor details");
  //   });
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newErrors = { ...errors };
    switch (name) {
      case 'firstName':
        newErrors.firstName = value.trim() === '' ? 'First name is required' : /[0-9]/.test(value) ? 'First name cannot contain numbers' : '';
        break;
      case 'lastName':
        newErrors.lastName = value.trim() === '' ? 'Last name is required' : /[0-9]/.test(value) ? 'Last name cannot contain numbers' : '';
        break;
      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        newErrors.email = value.trim() === '' ? 'Email is required' : (!emailRegex.test(value) ? 'Invalid email format' : '');
        break;
      case 'phone':
        newErrors.phone = value.trim() === '' ? 'Phone number is required' : '';
       
        break;
      case 'address':
        newErrors.address = value.trim() === '' ? 'Address is required' : '';
        break;
      case 'website':
        newErrors.website = value.trim() === '' ? 'Website is required' : '';
        break;
      case 'fax':
        newErrors.fax = value.trim() === '' ? 'Fax number is required' : '';
        break;
      default:
        break;
    }
    setErrors(newErrors);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  return (
    <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={['10px','20px','20px']} w={'100%'} m={'auto'} mb={'15px'}>
      <Text fontSize={'18px'} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}>Personal Details</Text>
      <hr />
      <Flex flexDirection={['column','column','row']} mt={'15px'} fontSize={'13px'} justifyContent={'space-around'} fontFamily={'Open Sans,sans-serif'}>
        <Box>
          <Text mb={'8px'}>First Name <span style={{ color: 'red' }}>*</span></Text>
          <Input readOnly={approved === 1} size={'sm'} name="firstName" backgroundColor={'#f1f1f1'} color={'grey'} value={formData.firstName} onChange={handleInputChange} placeholder='First Name' />
          {errors.firstName && <Text color="red">{errors.firstName}</Text>}
        </Box>
        <Box>
          <Text mb={'8px'}>Last Name <span style={{ color: 'red' }}>*</span></Text>
          <Input readOnly={approved === 1} size={'sm'} name="lastName" backgroundColor={'#f1f1f1'} color={'grey'} value={formData.lastName}
            onChange={handleInputChange} placeholder='Last Name' />
          {errors.lastName && <Text color="red">{errors.lastName}</Text>}
        </Box>
        <Box>
          <Text mb={'8px'}>Email <span style={{ color: 'red' }}>*</span></Text>
          <Input size={'sm'} name="email" backgroundColor={'#f1f1f1'} color={'grey'} value={formData.email} readOnly
            onChange={handleInputChange} placeholder='Email' />
        </Box>
      </Flex>
      <Flex flexDirection={['column','column','row']} justifyContent={'space-around'} mt={'20px'} fontSize={'13px'} fontFamily={'Open Sans,sans-serif'}>
        <Box>
          <Text mb={'8px'}>Website</Text>
          <Input readOnly={approved === 1} size={'sm'} name="website" value={formData.website} backgroundColor={'#f1f1f1'} color={'grey'} onChange={handleInputChange} placeholder='' />
        </Box>
        <Box>
          <Text mb={'8px'}>Phone <span style={{ color: 'red' }}>*</span></Text>
          <Input readOnly={approved === 1} size={'sm'} backgroundColor={'#f1f1f1'} color={'grey'} name="phone" value={formData.phone}
            onChange={handleInputChange} placeholder='Phone Number' />
          {errors.phone && <Text color="red">{errors.phone}</Text>}
        </Box>
        <Box>
          <Text mb={'8px'}>Fax</Text>
          <Input readOnly={approved === 1} size={'sm'} name="fax" onChange={handleInputChange} value={formData.fax} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' />
        </Box>
      </Flex>
      <Flex pl={['10px','20px','40px']} mt={'20px'} fontSize={'13px'} fontFamily={'Open Sans,sans-serif'}>
        <Box>
          <Text mb={'8px'}>Address <span style={{ color: 'red' }}>*</span></Text>
          <Textarea readOnly={approved === 1} size={'sm'} name="address" onChange={handleInputChange} value={formData.address} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' />
          {errors.address && <Text color="red">{errors.address}</Text>}
        </Box>
      </Flex>
      <Flex justifyContent={'end'}>
        {
          approved === 1 ? '' :
            <Button onClick={handleSave} size={'sm'} background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}}>{isProfileComplete>0?"Update":'Save Details'}</Button>
        }
      </Flex>
    </Box>
  );
}

const LicenseDetailsForm = ({ approved, commonData,isProfileComplete,getAdvisorProfile}) => {
  const dispatch = useDispatch();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { token, id } = JSON.parse(localStorage.getItem("code_dev1")) || {};
  const pdfId=id;
  const [licenseDetails, setLicenseDetails] = useState({
    idUser: id,
    licenceNo: commonData.licenceNo || '',
    eoPolicyNo: commonData.eopolicyNo || '',
    licenceExpiry: commonData.licenceExpiryDate || '',
    eoPolicyExpiry: commonData.eopolicyExpiryDate || '',
    eoDocUpload: null,
    providerDocUpload: null,
  });

  const handleSave = async () => {
    if(!licenseDetails.licenceNo){
      alert("Licence No. is required.")
      return;
    }
    if(!licenseDetails.licenceExpiry){
      alert("Licence expiry date is required.")
      return
    }
    if(!licenseDetails.eoPolicyNo){
      alert('E&O Policy Number is required')
      return;
    }
    if(!licenseDetails.eoPolicyExpiry){
      alert('E&O Policy Expiry Date is required')
      return;
    }
    dispatch(isLoading(true));
    try {
      const formData = new FormData();
      Object.keys(licenseDetails).forEach((key) => {
        if (key !== 'eoDocUpload' && key !== 'providerDocUpload') {
          formData.append(key, licenseDetails[key]);
        }
      });
      if (licenseDetails.eoDocUpload) {
        formData.append('eoDocUpload', licenseDetails.eoDocUpload);
      }
      if (licenseDetails.providerDocUpload) {
        formData.append('providerDocUpload', licenseDetails.providerDocUpload);
      }
      Api.POSTDATA(`${ADVISOR_LICENCE_DETAILS}`,formData).then((response)=>{
        console.log(response,'response')
         if (response.status === 200 || response.status === 201) {
        toast.success("License details updated successfully");
        getAdvisorProfile()
        } else {
          toast.error("Failed to update license details");
        }
      })
      // const response = await axios.post(ADVISOR_LICENCE_DETAILS,
      //   formData,
      //   {
      //     headers: {
      //       'Content-Type': 'multipart/form-data',
      //       'Authorization': `Bearer ${token}`,
      //     },
      //   }
      // );
     
    } catch (error) {
      console.error('Error:', error);
      toast.error("An error occurred while updating license details");
    }
    dispatch(isLoading(false));
  };

  const handleChange = (e) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      setLicenseDetails((prevDetails) => ({
        ...prevDetails,
        [name]: file,
      }));
    } else {
      const { value } = e.target;
      setLicenseDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  
  const handleDownload = async (type) => {
    try {
      const response = await fetch(`${API_URL()}/file/downloadFileOfBenefitDocument?id=${pdfId}&Type=${type}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        // saveAs(blob, 'Policy.pdf');
    } catch (error) {
        console.error('Failed to download PDF:', error);
    }

  };

  const today = new Date().toISOString().split('T')[0];

  return (
    <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'100%'} m={'auto'}>
      <Text fontSize={'18px'} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}>License Details</Text>
      <hr />
      <Flex flexDirection={['column','column','row']} mt={'15px'} fontSize={'13px'} justifyContent={'space-around'} fontFamily={'Open Sans,sans-serif'}>
        <Box>
          <Text mb={'8px'}>License No. *</Text>
          <Input readOnly={approved === 1} size={'sm'} name="licenceNo" value={licenseDetails.licenceNo} onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='Enter License No.' />
        </Box>
        <Box>
          <Text mb={'8px'}>License Expiry Date *</Text>
          <Input
            readOnly={approved === 1}
            size={'sm'}
            name="licenceExpiry"
            value={licenseDetails.licenceExpiry}
            onChange={handleChange}
            type='date'
            backgroundColor={'#f1f1f1'}
            color={'grey'}
            min={today}
            placeholder=''
          />
        </Box>

        <Box>
          <Text mb={'8px'}>E&O Policy No. *</Text>
          <Input readOnly={approved === 1} size={'sm'} name="eoPolicyNo" value={licenseDetails.eoPolicyNo} onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='Enter EO Policy No.' />
        </Box>
        <Box>
          <Text mb={'8px'}>E&O Policy Exp. Date *</Text>
          <Input
            readOnly={approved === 1}
            size={'sm'}
            name="eoPolicyExpiry"
            value={licenseDetails.eoPolicyExpiry}
            onChange={handleChange}
            type='date'
            backgroundColor={'#f1f1f1'}
            color={'grey'}
            min={today}
            placeholder=''
          />
        </Box>
      </Flex>
      <Box mt={'20px'} pl={'20px'}>
        <Text fontWeight="bold" fontSize={'14px'} fontFamily={'Open Sans,sans-serif'}>Uploaded PDF Files:</Text>
        <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th p={isLargerThan768 ? '7px' : '4px'} fontWeight="600" fontFamily="poppins">
                User Name
              </Th>
              <Th p={isLargerThan768 ? '7px' : '4px'} fontSize={isLargerThan768 ? '10px' : '8px'}>
                Plan Name
              </Th>
              <Th p={isLargerThan768 ? '7px' : '4px'} fontSize={isLargerThan768 ? '10px' : '8px'}>
                With Pre-existing
              </Th>
              <Th p={isLargerThan768 ? '7px' : '4px'} fontSize={isLargerThan768 ? '10px' : '8px'}>
                Without Pre-existing
              </Th>
            </Tr>
          </Thead>
          <Tbody>
          {commonData && (
                <React.Fragment>
                  <Tr fontSize={isLargerThan768 ? '12px' : '10px'} p={isLargerThan768 ? '2px' : '1px'} >
                  <Td p={isLargerThan768 ? '2px' : '1px'} fontFamily={'Open Sans,sans-serif'} fontSize={'12px'}>1</Td>
                  <Td p={isLargerThan768 ? '2px' : '1px'}><input w={"50%"} type="file" name="providerDocUpload" onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} /></Td>
                  <Td fontFamily={'Open Sans,sans-serif'} fontSize={'12px'} color={"black"}>{commonData.provider}</Td>
                  <Td p={isLargerThan768 ? '2px' : '1px'} fontFamily={'Open Sans,sans-serif'}>
                    <Button fontSize={'12px'} size={'sm'} onClick={() => handleDownload(commonData.provider)}>Download</Button></Td>
                </Tr>
                <Tr fontSize={isLargerThan768 ? '12px' : '10px'} p={isLargerThan768 ? '2px' : '1px'} >
                  <Td p={isLargerThan768 ? '2px' : '1px'}>2</Td>
                  <Td p={isLargerThan768 ? '2px' : '1px'}><input w={"50%"} type="file" name="eoDocUpload" onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} /></Td>
                  <Td p={isLargerThan768 ? '2px' : '1px'} fontFamily={'Open Sans,sans-serif'} fontSize={'12px'} color={"black"}>{commonData.eoInsurance}</Td>
                  <Td p={isLargerThan768 ? '2px' : '1px'} fontFamily={'Open Sans,sans-serif'} fontSize={'12px'}><Button fontSize={'12px'} size={'sm'} onClick={() => handleDownload(commonData.eoInsurance)}>Download</Button></Td>
                </Tr>
                </React.Fragment>
            )}
          </Tbody>
        </Table>
      </TableContainer>

        {/* <Table variant="simple" mt={4}>
          <Thead>
            <Tr fontFamily={'Open Sans,sans-serif'}>
              <Th fontFamily={'Open Sans,sans-serif'}>Serial No.</Th>
              <Th fontFamily={'Open Sans,sans-serif'}>Upload</Th>
              <Th fontFamily={'Open Sans,sans-serif'}>File Name</Th>
              <Th fontFamily={'Open Sans,sans-serif'}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {commonData && (
              <>
                <Tr>
                  <Td fontFamily={'Open Sans,sans-serif'} fontSize={'12px'}>1</Td>
                  <Td><input w={"50%"} type="file" name="providerDocUpload" onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} /></Td>
                  <Td fontFamily={'Open Sans,sans-serif'} fontSize={'12px'} color={"black"}>{commonData.provider}</Td>
                  <Td fontFamily={'Open Sans,sans-serif'}>
                    <Button fontSize={'12px'} size={'sm'} onClick={() => handleDownload('providerDocUpload')}>Download</Button></Td>
                </Tr>
                <Tr>
                  <Td>2</Td>
                  <Td><input w={"50%"} type="file" name="eoDocUpload" onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} /></Td>
                  <Td fontFamily={'Open Sans,sans-serif'} fontSize={'12px'} color={"black"}>{commonData.eoInsurance}</Td>
                  <Td fontFamily={'Open Sans,sans-serif'} fontSize={'12px'}><Button fontSize={'12px'} size={'sm'} onClick={() => handleDownload('eoDocUpload')}>Download</Button></Td>
                </Tr>
              </>
            )}
          </Tbody>
        </Table> */}
      </Box>
      <Flex justifyContent={'end'} mt={'10px'}>
        {
          approved === 1 ? '' :
            <Button size={'sm'} background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} onClick={handleSave}>{isProfileComplete>1?"Update":'Save Details'}</Button>
        }
      </Flex>
    </Box>
  );
};

const CorporationDetailsForm = ({ approved, commonData, isProfileComplete,getAdvisorProfile }) => {
  const dispatch = useDispatch();
  const today = new Date().toISOString().split('T')[0];
  const { token, id } = JSON.parse(localStorage.getItem("code_dev1")) || {}
  const id1=id;
  const [licenseDetails, setLicenseDetails] = useState({
    idUser: id,
    corpName: commonData.corporationName || '',
    eoCorpName: commonData.eocorporationName || '',
    corpEOPolicyExpiryDate: commonData.corporationeoPolicyexpiryDate || '',
    corpLicenceExpiryDate: commonData.corporationLicenceExpirydate || '',
    binNoOfCorp: commonData.binnoofCorporation || '',
    corpLicenceDoc: null,
    corpPaperDoc: null
    });
  const [showFirm,setShowFirm]=useState(commonData.firm || false)


  const handleYesNoChange = (e) => {
    const { value } = e.target;
    setShowFirm(value === 'true');
  };

  const handleSave = async () => {
    if(showFirm===true && !licenseDetails.corpName){
      alert("Please Enter Corporation Name")
      return;
    }
    if(showFirm===true && !licenseDetails.eoCorpName){
      alert("The E&O corporation name is required")
      return;
    }
    if(showFirm===true && !licenseDetails.corpEOPolicyExpiryDate)
      {
       alert('The Corporation E&O Policy Expiry date is required')
        return
      }
    if(showFirm===true && !licenseDetails.corpLicenceExpiryDate){
      alert('The corporation licence expiry date field is required.')
      return;
    }
    if (showFirm === true && (!licenseDetails.binNoOfCorp || licenseDetails.binNoOfCorp.length !== 9)) {
      alert('The BIN No of Corporation field is required and must be 9 digits.');
      return;
    }
    if(showFirm===true && !licenseDetails.corpLicenceDoc){
      alert('Corportation license doc is required')
      return;
    }
    if(showFirm===true && !licenseDetails.corpPaperDoc){
      alert("Corporation Doc is Required")
      return;
    }
    if(showFirm===false){
      const formData = new FormData();
      formData.append('idUser',id)
      formData.append('firm',showFirm)
      Api.POSTDATA(`${ADVISOR_CORPORATION_DETAILS}`,formData).then((res)=>{
        console.log(res)
        const {status,data}=res
        if(status===200){
          toast.success("Corporation details updated successfully");
          getAdvisorProfile()
        }else{
          console.log('error')
          alert("Something went wrong")
        }
      })
    }
    if(showFirm===true){
      dispatch(isLoading(true));
      try {
        const formData = new FormData();
        formData.append('idUser',id)
        formData.append('corpName',licenseDetails.corpName)
        formData.append('eoCorpName',licenseDetails.eoCorpName)
        formData.append('corpEOPolicyExpiryDate',licenseDetails.corpEOPolicyExpiryDate)
        formData.append('corpLicenceExpiryDate',licenseDetails.corpLicenceExpiryDate)
        formData.append('binNoOfCorp',licenseDetails.binNoOfCorp)
        formData.append('firm',showFirm)
        formData.append('corpLicenceDoc',licenseDetails.corpLicenceDoc)
        formData.append('corpPaperDoc',licenseDetails.corpPaperDoc)
        if (licenseDetails.corpLicenceDoc) {
          formData.append('corpLicenceDoc', licenseDetails.corpLicenceDoc);
        }
        if (licenseDetails.corpPaperDoc) {
          formData.append('corpPaperDoc', licenseDetails.corpPaperDoc);
        }
        Api.POSTDATA(`${ADVISOR_CORPORATION_DETAILS}`,formData).then((res)=>{
          console.log(res,'response')
          const {status,data}=res
          if(status===200){
            toast.success("Corporation details updated successfully");
            getAdvisorProfile()
          }else{
            console.log('error')
            alert("Something went wrong")
          }
        })
        // const response = await axios.post(ADVISOR_CORPORATION_DETAILS,
        //   formData,
        //   {
        //     headers: {
        //       'Content-Type': 'multipart/form-data',
        //       'Authorization': `Bearer ${token}`,
        //     },
        //   }
        // );
        // if (response.status === 200) {
          
        // } else {
        //   toast.error("Failed to update corporation details");
        // }
      } catch (error) {
        console.error('Error:', error);
        toast.error("An error occurred while updating corportaion details");
      }
    }
    dispatch(isLoading(false));
  };

  const handleChange = (e) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      setLicenseDetails((prevDetails) => ({
        ...prevDetails,
        [name]: file,
      }));
    } else {
      const { value } = e.target;
      setLicenseDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
    };

    const handleDownload = async (type) => {
      try {
        const response = await fetch(`${API_URL()}/file/downloadFileOfBenefitDocument?id=${id1}&Type=${type}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        if (!response.ok) {
          throw new Error('Network response was not ok');
          }
          const blob = await response.blob();
        //   saveAs(blob, 'Policy.pdf');
      } catch (error) {
          console.error('Failed to download PDF:', error);
      }
  
    };
  return (
    <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'}>
      <Text fontSize={'18px'} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}>Corporation Details</Text>
      <hr />
      <Flex mt={'25px'} justifyContent={'space-around'} fontSize={'13px'} fontFamily={'Open Sans,sans-serif'}>
        <Box w={'230px'}>
          <Text mb={'8px'}>Firm/Corporation/Prop *</Text>
          <Select readOnly={approved === 1} disabled={approved === 1} size={'sm'} name='firm' onChange={handleYesNoChange} value={showFirm.toString()}>
            <option value="">select</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </Select>
        </Box>
          {
            showFirm===true?
            <Box>
              <Text mb={'8px'}>E&O updated with Corporation name *</Text>
              <Input w={'230px'} readOnly={approved === 1} size={'sm'} name="eoCorpName" value={licenseDetails.eoCorpName} onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' />
            </Box>:''
          }
          {
            showFirm===true?
        <Box>
          <Text mb={'8px'}>Corporation E&O Expiry Date *</Text>
          <Input w={'230px'} min={today} readOnly={approved === 1} size={'sm'} name="corpEOPolicyExpiryDate" value={licenseDetails.corpEOPolicyExpiryDate} onChange={handleChange} type='date' backgroundColor={'#f1f1f1'} color={'grey'} placeholder='ravi@gmail.com' />
        </Box>:''}
      </Flex>
      {
            showFirm===true?
            <>
      <Flex justifyContent={'space-around'} mt={'20px'} fontSize={'13px'} fontFamily={'Open Sans,sans-serif'}>
        <Box>
          <Text mb={'8px'}>Corporation Name *</Text>
          <Input w={'230px'} readOnly={approved === 1} size={'sm'} name="corpName" value={licenseDetails.corpName} onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' />
        </Box>
        <Box>
          <Text mb={'8px'}>BIN No of the Corporation *</Text>
          <Input w={'230px'} readOnly={approved === 1} size={'sm'} name="binNoOfCorp" value={licenseDetails.binNoOfCorp} onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} />
        </Box>
        <Box>
          <Text mb={'8px'}>Date Of License Expiry *</Text>
          <Input w={'230px'} readOnly={approved === 1} size={'sm'} name="corpLicenceExpiryDate" value={licenseDetails.corpLicenceExpiryDate} onChange={handleChange} type="date" backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' min={today} />
        </Box>
      </Flex>
      <Box mt={'30px'} pl={'20px'} w={'90%'} m={'auto'} fontSize={'13px'} fontFamily={'Open Sans,sans-serif'}>
        <Text fontWeight="bold" mt={'30px'}>Uploaded PDF Files:</Text>
        <Table variant="simple" mt={2}>
          <Thead>
            <Tr>
              <Th>Serial No.</Th>
              <Th>Upload</Th>
              <Th>File Name</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {commonData && (
              <>
                <Tr>
                  <Td>1</Td>
                  <Td><input type="file" name="corpLicenceDoc" onChange={handleChange} /></Td>
                  <Td color={"black"}>{commonData.corporationLicenceDoc}</Td>
                  <Td><Button fontSize={'12px'} size={'sm'} onClick={() => handleDownload('corpLicenceDoc')}>Download</Button></Td>
                </Tr>
                <Tr>
                  <Td>2</Td>
                  <Td><input type="file" name="corpPaperDoc" onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} /></Td>

                  <Td color={"black"}>{commonData.corporationPaperDoc}</Td>
                  <Td><Button fontSize={'12px'} size={'sm'} onClick={() => handleDownload("corpPaperDoc")}>Download</Button></Td>
                </Tr>
              </>
            )}
          </Tbody>
        </Table>
      </Box></>:''}
      <Flex justifyContent={'end'} mt={'10px'}>
      {
          approved === 1 ? '' :
          <Button size={'sm'} background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} onClick={handleSave}>{isProfileComplete>2?"Update":'Save Details'}</Button>
        }
        
      </Flex>
    </Box>
  );
};

const ReferenceDetailsForm = ({ approved, commonData,getAdvisorProfile}) => {
  const { token, id } = JSON.parse(localStorage.getItem("code_dev1")) || {}
  const dispatch = useDispatch();
  const [referenceDetails, setReferenceDetails] = useState({
    reference_name_1:"",
    reference_email_1: "",
    reference_contact_1:  "",
    reference_address_1:  "",
    reference_name_2:  "",
    reference_email_2:  "",
    reference_contact_2:  "",
    reference_address_2:   "",
    reference_name_3: "",
    reference_email_3:  "",
    reference_contact_3:  "",
    reference_address_3:"",
  });;
  const handleSave = () => {
    const requestData = {
      idUser: id,
      reference_name_1: referenceDetails.reference_name_1,
      reference_email_1: referenceDetails.reference_email_1,
      reference_contact_1: referenceDetails.reference_contact_1,
      reference_address_1: referenceDetails.reference_address_1,
      reference_name_2: referenceDetails.reference_name_2,
      reference_email_2: referenceDetails.reference_email_2,
      reference_contact_2: referenceDetails.reference_contact_2,
      reference_address_2: referenceDetails.reference_address_2,
      reference_name_3: referenceDetails.reference_name_3,
      reference_email_3: referenceDetails.reference_email_3,
      reference_contact_3: referenceDetails.reference_contact_3,
      reference_address_3: referenceDetails.reference_address_3,


    };
    dispatch(advisorRefrence(requestData)).then((res)=>{
      console.log(res,'reess')
      getAdvisorProfile()
    })
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setReferenceDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'}>
      <Text fontSize={'18px'} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}>Reference Details</Text>
      <hr />
      <Flex flexDirection={['column','column','column']} fontSize={'12px'} color={'grey'} fontFamily={'Open Sans,sans-serif'} fontWeight={'600'} justifyContent={'space-around'}>
        {[1, 2, 3].map((index) => (
          <Flex flexDirection={['column','column','row']} gap={['0px','0px','20px']} key={index} mt={'25px'} justifyContent={'space-around'}>
            <Box>
              <Text mb={'8px'}>{`Reference Name ${index}`}</Text>
              <Input size={'sm'} name={`reference_name_${index}`} value={referenceDetails[`reference_name_${index}`]} onChange={handleChange} />
            </Box>
            <Box>
              <Text mb={'8px'}>{`Reference Email ${index}`}</Text>
              <Input size={'sm'} name={`reference_email_${index}`} value={referenceDetails[`reference_email_${index}`]} onChange={handleChange} />
            </Box>
            
            <Box>
              <Text mb={'8px'}>{`Reference Contact ${index}`}</Text>
              <Input size={'sm'} name={`reference_contact_${index}`} value={referenceDetails[`reference_contact_${index}`]} onChange={handleChange} />
            </Box>
            <Box>
              <Text mb={'8px'}>{`Reference Address ${index}`}</Text>
              <Input size={'sm'} name={`reference_address_${index}`} value={referenceDetails[`reference_address_${index}`]} onChange={handleChange} />
            </Box>
          </Flex>
        ))}
      </Flex>
      <Flex mt={'15px'} justifyContent={'end'}>
        {
          approved === 1 ? '' :
            <Button size={'sm'} background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} onClick={handleSave}>Save Reference Details</Button>
        }
      </Flex>
    </Box>
  );
};




export default AdvisorProfile;
