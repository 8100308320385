import { Box,Grid,GridItem,Text,Button, Image, Flex, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from '@chakra-ui/react'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { buttonBackgroundColor, buttonColor } from '../../Utils/UniversalColors';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const ComparedProducts = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { items } = location.state || { items: [] };
    console.log(items,'items')
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

      const handleBuyNow = (data) => {
        const item={
            datas:data,
            row:items.row
          }
        navigate('/single-quote', { state: { item } });
      };


return (
    <Box background={'#efefef'} pt={'90px'}>
        <hr />
    <Box w={'87%'} m={'auto'} pb={'120px'} color={'black'}>
    <Box mt={'20px'} background={'white'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' borderRadius={'7px'}>
        <Box pt={'50px'} overflow="hidden">
            <Carousel
                swipeable
                draggable
                responsive={responsive}
                infinite
                showDots
                autoPlay
                autoPlaySpeed={5000}
                keyBoardControl
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-20-px"
                >
                {items.datas.map(item => (
                <Box w={'90%'} textAlign={'center'} fontFamily={'Montserrat, Sans-serif'}>
                <Flex justifyContent={'center'}>
                  <Image w='120px' h={'45px'} src={item.image} />
                </Flex>
                <Flex justifyContent={'center'} alignItems={'center'} direction={'column'} mt={'10px'} >
                {item.premiumAmount.map((ele, index) => (
                    <Text fontWeight={'bold'} color={'#307EF2'} key={index}>{Math.round(ele)} $</Text>
                ))}
                </Flex>
                <Flex justifyContent={'center'} p={'10px'}>
                    <Button size={'xs'} background={buttonBackgroundColor} color={buttonColor} onClick={() => handleBuyNow(item)}>Buy Now</Button>
                </Flex>
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Name</Text>
                    <Text fontSize={'14px'} pb={'15px'}>{item.name}</Text>
                    <hr />
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Insurnace Policy</Text>
                    <Text color={'blue'} fontSize={'14px'} pb={'15px'}>View Sample Policy</Text>
                    <hr />
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Coverage Amount</Text>
                    <Text fontSize={'14px'} pb={'15px'}>$ {item.sumInsured}</Text>
                    <hr />
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Deductibles</Text>
                    <Text fontSize={'14px'} pb={'15px'}>Deductibles Data</Text>
                    <hr />
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Emergency Hospital Services</Text>
                    <Text fontSize={'14px'} pb={'15px'}>Up to Maximum Policy Limit</Text>
                    <hr />
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Emergency Medical Services/Treatment</Text>
                    <Text fontSize={'14px'} pb={'15px'}>Up to Maximum Policy Limit</Text>
                    {/* <hr /> */}
                    <Box mt={'15px'}>
                    <Accordion defaultIndex={[0]} allowMultiple w={'80%'} m={'auto'}>
                        <AccordionItem>
                            <h2>
                            <AccordionButton>
                                <Box fontSize={'12px'} as='span' flex='1' textAlign='left'>
                                Exclusions
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} background={'#efefef'}>
                                <Text fontSize={'12px'}>{item.exclusion}</Text>
                            
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                            <AccordionButton>
                                <Box fontSize={'12px'} as='span' flex='1' textAlign='left'>
                                Coverages
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} background={'#efefef'}>
                                <Text fontSize={'12px'}>{item.coverage}</Text>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                            <AccordionButton>
                                <Box fontSize={'12px'} as='span' flex='1' textAlign='left'>
                                Eligiblity
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel background={'#efefef'} pb={4}>
                                <Text fontSize={'12px'}>{item.eligibility}</Text>
                            
                            </AccordionPanel>
                        </AccordionItem>
                        </Accordion>
                    </Box> 
                    </Box>    
                ))}
                </Carousel>
                </Box>
            </Box>



        {/* <Grid background={'white'} mt={'20px'} templateColumns='repeat(4, 1fr)' p={'20px'}>
                <GridItem border={'1px solid rgb(238 238 238)'} fontSize={'15px'} fontWeight={'600'} fontFamily={'Manrope, sans-serif'}>
                <Box h={'100px'} border={'1px solid rgb(238 238 238)'}>
                    <Text p={'15px'}>Providers</Text>
                </Box>
                <Box p={'10px'} border={'1px solid rgb(238 238 238)'}>
                    <Text p={'15px'}>Rate</Text>
                </Box>
                <Box border={'1px solid rgb(238 238 238)'}>
                    <Text p={'13px'}>Buy Now</Text>
                </Box>
                <Box border={'1px solid rgb(238 238 238)'}>
                    <Text p={'15px'}>Covid 19 coverages</Text>
                </Box>
                <Box border={'1px solid rgb(238 238 238)'}>
                    <Text p={'15px'}>Ambulance Service</Text>
                </Box>
                <Box border={'1px solid rgb(238 238 238)'}>
                    <Text p={'15px'}>Wait Period</Text>
                </Box>
                <Box border={'1px solid rgb(238 238 238)'}>
                    <Text p={'15px'}>Policy Wording</Text>
                </Box>
                <Box border={'1px solid rgb(238 238 238)'}>
                    <Text p={'15px'}>Claim Form</Text>
                </Box>
                </GridItem>
                {items.map(item => (
                <GridItem color={'rgb(119 119 119)'} border={'1px solid rgb(238 238 238)'} fontSize={'15px'} fontWeight={'500'} fontFamily={'Manrope, sans-serif'}>
                <Flex alignItem='center' h={'100px'} p={'20px'}>
                    <Image w='70%' h={'90%'} src={item.image} />
                </Flex>
                     <Box border={'1px solid rgb(238 238 238)'}>
                    <Text textAlign={'center'} color={'black'} fontWeight={'600'} fontSize={'26px'} p={'15px'}>$60.78</Text>
                    </Box>
                    <Flex justifyContent={'center'} p={'10px'} border={'1px solid rgb(238 238 238)'}>
                    <Button size={'sm'} background={buttonBackgroundColor} color={buttonColor}>Buy Now</Button>
                    </Flex>
                    <Box p={'14px'} border={'1px solid rgb(238 238 238)'}>
                    <Text>YES</Text>
                    </Box>
                    <Box p={'15px'} border={'1px solid rgb(238 238 238)'}>
                    <Text>Covered</Text>
                    </Box>   
                    <Box border={'1px solid rgb(238 238 238)'}>
                    <Accordion defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <h2>
                            <AccordionButton>
                                <Box as='span' flex='1' textAlign='left'>
                                See more...
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Text>48 Hours if gap is  30 days <br />
                            7 Days If gap is  30 days <br />
                            Age 86 or over 15 Days</Text>
                            
                            </AccordionPanel>
                        </AccordionItem>
                        </Accordion>
                    </Box>
                    <Box p={'15px'} border={'1px solid rgb(238 238 238)'}>
                    <Text>Covered</Text>
                    </Box>       
                </GridItem>))}
            </Grid> */}
        </Box>
    </Box>
  )
}

export default ComparedProducts
