import Home from '../../Components/Home'
import Aboutus from '../../Components/Aboutus'
import TravelComparison from '../../Components/TravelComparison';
import TravelComparisonForm from '../../Components/TravelComparison/TravelComparisonForm';
import TravelAllProviders from '../../Components/TravelComparison/AllProviders';
import CompareTravelProducts from '../../Components/TravelComparison/CompareProducts';
import TravelCompariosnQuotes from '../../Components/TravelComparison/TravelComparisonQuotes';
import Homepage from '../../Components/Homepage';
import GetQuotesForm from '../../Components/GetQuotesForm';
import SuperVisaCompare from '../../Components/Supervisa';
import ComparedProducts from '../../Components/ComparedProducts';
import Register from '../../Components/Register';
import Login from '../../Components/Login';
import VisitorCompare from '../../Components/Visitor';
import StudentCompare from '../../Components/Student';
import TravelCompare from '../../Components/Travel';
import UserDashboard from '../../Components/User/Dashboard';
import ViewSingleQuote from '../../Components/ViewSingleQuote';
import AllQuotes from '../../Components/Quotes';
import ViewQuote from '../../Components/Quotes/ViewQuote';
import ForgetPassword from '../../Components/LoginForgetPassword';
import CustomerProfile from '../../Components/Profiles/CustomerProfile';
import AdvisorProfile from '../../Components/Profiles/AdvisorProfile';
import Enquiry from '../../Components/Enquiry';
import Privacy from '../../Components/PrivacyPolicy';
import Terms from '../../Components/Terms&Conditions';
import AdvisorRegister from '../../Components/Register/advisorSignup';
import CustomerForgetPassword from '../../Components/LoginForgetPassword/customerForget';

export const routes = [
  {
    path: '/',
    element: <Homepage />,
    protected: false
  },
  {
    path: '/about-us',
    element: <Aboutus />,
    protected: false
  },
   {
    path: '/travel-comparison',
    element: <TravelComparison />,
    protected: false
  },
  {
    path: '/travel-comparison-form',
    element: <TravelComparisonForm />,
    protected: false
  },
  {
    path: '/travel-comparison-quotes',
    element: <TravelCompariosnQuotes />,
    protected: false
  },
  {
    path: '/travel-compare-products',
    element: <CompareTravelProducts />,
    protected: false
  },
  {
    path: '/travel-all-providers',
    element: <TravelAllProviders />,
    protected: false
  },
  {
    path: '/get-quotes',
    element: <GetQuotesForm />,
    protected: false
  },
  {
    path: '/super-visa-compare',
    element: <SuperVisaCompare />,
    protected: false
  },
  {
    path: '/visitor-compare',
    element: <VisitorCompare />,
    protected: false
  },
  {
    path: '/travel-compare',
    element: <TravelCompare />,
    protected: false
  },
  {
    path: '/student-compare',
    element: <StudentCompare />,
    protected: false
  },
  {
    path: '/compared-products',
    element: <ComparedProducts />,
    protected: false
  },
  {
    path: '/register',
    element: <Register />,
    protected: false
  },
  {
    path: '/advisorRegister',
    element: <AdvisorRegister />,
    protected: false
  },
  {
    path: '/login',
    element: <Login />,
    protected: false
  },
  {
    path: '/advisorForgetPassword',
    element: <ForgetPassword />,
    protected: false
  },
  {
    path: '/customerForgetPassword',
    element: <CustomerForgetPassword />,
    protected: false
  },
  {
    path: '/dashboard',
    element: <UserDashboard />,
    protected: false
  },
  {
    path: '/single-quote',
    element: <ViewSingleQuote/>,
    protected: false
  },
  {
    path: '/all-quotes',
    element: <AllQuotes/>,
    protected: false
  },
  {
    path: '/view-quote/:id',
    element: <ViewQuote/>,
    protected: false
  },
  {
    path: '/customer-profile',
    element: <CustomerProfile/>,
    protected: false
  },
  {
    path: '/advisor-profile',
    element: <AdvisorProfile/>,
    protected: false
  },
  {
    path: '/enquiry',
    element: <Enquiry/>,
    protected: false
  },
  {
    path: '/privacy_policy',
    element: <Privacy/>,
    protected: false
  },
  {
    path: '/terms_condition',
    element: <Terms/>,
    protected: false
  }

];

