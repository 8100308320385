import React, { useEffect, useState } from 'react'
import { Box, Flex, Image, Text } from '@chakra-ui/react'


const Privacy = () => {
      
      
        // useEffect(() => {
        //   dispatch(adminPagesAll())
        //     .then(res => {
        //       const { status, data } = res;
        //       setPageData(res)
        //     })
        //     .catch(error => {
        //       console.error('Error fetching data:', error);
        //     });
        // }, [dispatch]);
      
      // console.log("pageData",pageData)



return (
    <Box pt={'90px'} pb={'120px'}>
    <Box m={'auto'}>
    <Flex alignItems={'center'} justifyContent={'space-between'}>
     <Box>
      <Text fontSize={'28px'} pl={'40px'}> Privacy Policy</Text>
     </Box>
     <Box w={'40%'}  backgroundImage={''}>
      <Image src='https://quickinsure.co.in/static/images/sub-header/terms-and-condition-banner.svg'/>
     </Box>
    </Flex>
    </Box>
      

    <Box w={'80%'} m={'auto'} mt={'120px'}>
        <Text color={'#707070'} fontWeight={'400'} fontFamily={'Assistant,sans-serif'}>
        It is Travel Medicare’s policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to www.travelmedicare.com (hereinafter, "us", "we", or www.travelmedicare.com or domain owners Risk Care Insurance Ltd herein referred to as RCIL). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources. This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general rules and policies governing your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions. <br /><br />

Like most website operators, Travel Medicare collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Travel Medicare’s purpose in collecting non-personally identifying information is to better understand how Travel Medicare's visitors use its website. From time to time, Travel Medicare may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website. <br /> <br />

Travel Medicare also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on http://www.travelmedicare.com blog posts. Travel Medicare only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.</Text>
        <Text pt={'10px'}>We use your information for</Text>
        <Box color={'#707070'} fontWeight={'400'} fontFamily={'Assistant,sans-serif'}>
        <ul>
            <li>To personalize your experience (your information helps us to better respond to your individual needs)</li>
            <li>To improve our website (we continually strive to improve our website based on the information and feedback we receive from you)</li>
            <li>To improve customer service (your information helps us to effectively respond to your customer service requests and support needs)</li>
<li>
To process transactions
</li>
<li>
To administer a contest, promotion, survey or other site feature</li>
<li>To send periodic emails
</li>
        </ul>
        </Box>
<Text color={'#707070'} fontWeight={'400'} fontFamily={'Assistant,sans-serif'}>Our e-mail marketing is permission based. If you received an email from us, our records indicate that (a) you have expressly provided your e-mail address for the purpose of receiving information in the future (“opt-in”), or (b) you have registered or purchased or otherwise have an existing relationship with us.</Text> <br />

<Text color={'#707070'} fontWeight={'400'} fontFamily={'Assistant,sans-serif'}> <b>Note :</b>  If at any time you would like to unsubscribe from receiving future commercial electronic messages (CEM), we include detailed unsubscribe instructions at the bottom of each email.</Text>
<br />

<Text color={'#707070'} fontWeight={'400'} fontFamily={'Assistant,sans-serif'}>Certain visitors to Travel Medicare's websites choose to interact with Travel Medicare in ways that require Travel Medicare to gather personally-identifying information. The amount and type of information that Travel Medicare gathers depends on the nature of the interaction. For example, we ask visitors who sign up for Advisor contract / AGA / MGA at http://www.travelmedicare.com to provide a username and email address his details pertaining to conduct of business as per Insurance regulatory requirements and CHLIA requirements as per various laws can be reached through https://www.clhia.ca/ and www.fsrs.ca and various others regulators. The advisor may gather and input personal information of the client related to the business advisor conducting through www.travelmedicare.comb. Your information, whether public or private, will not be sold, exchanged, transferred, or given to any unauthorized third party for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested.</Text>

<Text color={'#707070'} fontWeight={'400'} fontFamily={'Assistant,sans-serif'}>The email address you provide for order processing may be used to send you information and updates pertaining to your order, in addition to receiving company communications. <br /> <br />

The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure and we use SSl certificates and state of Art Amazon AWS Secured servers to host our website by using leading virus protection software. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security. <br />

We utilize a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be stored on our servers. <br /> <br />
 
Do we disclose any information to outside parties? <br /><br />

We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses. Because we value your privacy we have taken the necessary precautions to be in compliance with Canada’s Personal Information Protection and Electronic Documents Act. We therefore will not distribute your personal information to outside parties without your consent. <br /><br />

Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by Travel Medicare and does not cover the use of cookies by any advertisers. <br /><br />

Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit. <br /><br />

We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services. <br /><br />

www.travelmedicare.com uses the remarketing services to advertise on third party websites (including Google) to previous visitors to our site. It could mean that we advertise to previous visitors who haven't completed a task on our site, for example using the contact form to make an enquiry. This could be in the form of an advertisement on the Google search results page, or a site in the Google Display Network. Third-party vendors, including Google, use cookies to serve ads based on someone's past visits. Of course, any data collected will be used in accordance with our own privacy policy and Google's privacy policy. <br /><br />

You can set preferences for how Google advertises to you using the Google Ad Preferences page, and if you want to you can opt out of interest-based advertising entirely by cookie settings or permanently using a browser plugin. <br /><br />

Travel Medicare discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on Travel Medicare's behalf or to provide services available at Travel Medicare's website, and (ii) that have agreed not to disclose it to others. Some of those employees, Advisors, Underwriters; by using Travel Medicare's website, you consent to the transfer of such information to them. Travel Medicare will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, Advisors and Underwriters of the product as described above, Travel Medicare discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when Travel Medicare believes in good faith that disclosure is reasonably necessary to protect the property or rights of Travel Medicare, third parties or the public at large. <br /><br />

If you are a registered user of http://www.travelmedicare.com and have supplied your email address, Travel Medicare may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what's going on with Travel Medicare and our products. We primarily use our blog to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Travel Medicare takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information. <br /><br />

Travel Medicare may collect statistics about the behavior of visitors to its website. Travel Medicare may display this information publicly or provide it to others. However, Travel Medicare does not disclose your personally-identifying information. <br /><br />

This site uses affiliate links and does earn a commission from certain links. This does not affect your purchases or the price you may pay. <br /><br />

To enrich and perfect your online experience, Travel Medicare uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer. <br /><br />

A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. Travel Medicare uses cookies to help Travel Medicare identify and track visitors, their usage of http://www.travelmedicare.com , and their website access preferences. Travel Medicare visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Travel Medicare's websites, with the drawback that certain features of Travel Medicare's websites may not function properly without the aid of cookies. <br /><br />

By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to Travel Medicare's use of cookies. <br /><br />

Those who engage in transactions with Travel Medicare– by purchasing Travel Medicare's services or products, are asked to provide additional information, including as necessary the personal and financial information required to process those transactions. In each case, Travel Medicare collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor's interaction with Travel Medicare. Travel Medicare does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities. <br /><br />

If Travel Medicare, or substantially all of its assets, were acquired, or in the unlikely event that Travel Medicare goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of Travel Medicare may continue to use your personal information as set forth in this policy. <br /><br />

Although most changes are likely to be minor, Travel Medicare may change its Privacy Policy from time to time, and in Travel Medicare's sole discretion. Travel Medicare encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</Text>



      </Box>




    </Box>
  )
}

export default Privacy
