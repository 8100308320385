export const validateMobileNumber = (number) => {
    const mobileRegex = /^[1-9]\d{9,14}$/;
    return mobileRegex.test(number);
};

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const getMaxSelectableDate = () => {
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];
  return formattedDate;
};

export const getMinSelectableDate = () => {
  const today = new Date();
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 89);
  return minDate.toISOString().split('T')[0]; 
};