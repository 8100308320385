import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Grid, GridItem, Image, Input, Select, Tab, TabList, TabPanel, TabPanels, Tabs, Text, background, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor } from '../../Utils/UniversalColors'
import { useNavigate } from 'react-router-dom'
import '../../fonts/fonts.css'
import { useDispatch } from 'react-redux'
import ImageItem from '../../Utils/UniversalColors/ImageItem'
import { vtvCompareList } from '../Visitor/dependencies/action'
import { FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoMdAdd, IoMdCloseCircle } from "react-icons/io";
import { getMaxSelectableDate, getMinSelectableDate } from '../../Utils/UniversalColors/Validation'
import { ComparedProductsModal, GetRateModal } from '../ComparedProductsModal'
import LoginModal from './LoginModal'


const SuperVisaCompare = () => {
    const navigate=useNavigate()
    const toast = useToast();
    const [age,setAge]=useState('')
    const [isComparedModalOpen,setIsComparedModalOpen]=useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [buyNowData,setBuyNowData]=useState([])
    const [isRateModalOpen, setIsRateModalOpen] = useState(false);
    const [comparedData,setComparedData]=useState([])
    const [form, setForm] = useState({
      policyType: '',
      dob: '',
      startDate: '',
      endDate: '',
      sumInsured: '25000',
      deductibles: '0',
      preExisting: '',
    });
    const [familyForm,setFamilyForm]=useState({
      startDate: '',
      endDate: '',
      clientCountry: 'canada',
      sumInsured:'25000',
      preExisting:'',
      clientProvince: '',
      vtcDepartureDate: "",
      studentDepartureDate: "",
      deductibles:'0'
    })
    const [travelerDob,setTravelerDob]=useState([
      {
        name: "",
        gender: "",
        dob: null,
        relation: "",
        benificiaryDob: null,
        benificiaryName: "",
        age:''
      },
    ])
    const [addTravelers, setAddTravelers] = useState([
      {
        name: "",
        gender: "",
        dob: " ",
        relation: "",
        benificiaryDob: "",
        benificiaryName: "",
        selectedKey:''
      },
    ]);
    const dispatch=useDispatch()
    const [responseData, setResponseData] = useState([]);
    const [duration,setDuration]=useState([])
    const [selectedItems, setSelectedItems] = useState([]);
    const [itemsData,setItemsData]=useState([])
      
      const handleAddToCompare = (name) => {
        setSelectedItems((prevItems) => {
          if (prevItems.some((item) => item.name === name)) {
            toast({
              title: 'Item already added to compare.',
              status: 'info',
              duration: 2000,
              isClosable: true,
            });
            return prevItems;
          }
          return [...prevItems, { name }];
        });
      };
    
      const handleCompare = () => {
        if (selectedItems.length > 1) {
          const itemsWithDetails = selectedItems.map((selectedItem) => {
            const itemDetails = itemsData.find((item) => item.name === selectedItem.name);
            return {
              ...selectedItem,
              ...itemDetails,
            };
          });
          const item={
            datas:itemsWithDetails,
            row:form
          }
          setComparedData(item)
          setIsComparedModalOpen(true)
          // navigate('/compared-products', { state: { items: item } });
        } else {
          toast({
            title: 'Select at least two items to compare.',
            status: 'warning',
            duration: 2000,
            isClosable: true,
          });
        }
      };

      const handleChange = (e) => {
          const { name, value } = e.target;
          if (name === "startDate") {
              const startDate = new Date(value);
              const endDate = new Date(startDate);
              endDate.setFullYear(startDate.getFullYear() + 1);
              endDate.setDate(startDate.getDate() - 1); // Ensure it's exactly one year minus one day
              setForm({ 
                  ...form, 
                  startDate: value, 
                  endDate: endDate.toISOString().split("T")[0] 
              });
              setDuration('365');
          }
      
          if (name === "dob") {
              const dob = new Date(value);
              const today = new Date();
              let age = today.getFullYear() - dob.getFullYear();
              const monthDiff = today.getMonth() - dob.getMonth();
              const dayDiff = today.getDate() - dob.getDate();
              if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
                  age--;
              }
              setAge(age)
              setForm((prevState) => ({
                  ...prevState,
                  dob: value,
              }));
          } else {
              setForm((prevState) => ({
                  ...prevState,
                  [name]: value,
              }));
          }
      };
    
      const handleFamilyChange = (e) => {
        const { name, value } = e.target;
        if(name==="startDate"){
          const startDate = new Date(value);
          const endDate = new Date(startDate);
          endDate.setFullYear(startDate.getFullYear() + 1);
          endDate.setDate(startDate.getDate() - 1); // Ensure it's exactly one year minus one day
          setFamilyForm({ 
            ...familyForm, 
            startDate: value, 
            endDate: endDate.toISOString().split("T")[0] 
          });
          setDuration('365');
        }

      setFamilyForm((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };
      const handleChangeTraveler = (index, dateOrEvent, fieldName) => {
        let name, value;
        if (fieldName) {
          name = fieldName;
          value = dateOrEvent;
        } else {
          const event = dateOrEvent;
          name = event.target.name;
          value = event.target.value;
        }
        console.log(value,name)
    const today = new Date();
    const selectedDate = new Date(value);
    const age = today.getFullYear() - selectedDate.getFullYear();
    if(name === "dob" || name === "benificiaryDob") {
      const dateValue = new Date(value);
        const today = new Date();
        const selectedDate = new Date(value);
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - 15);
        if (selectedDate >= minDate) {
            alert("Users must be at least 15 days older than today.");
            return;
        }
        const age = today.getFullYear() - selectedDate.getFullYear();
        const monthDiff = today.getMonth() - selectedDate.getMonth();
        const dayDiff = today.getDate() - selectedDate.getDate();

        if (age > 75 || (age === 75 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)))) {
            // setCurrentTravelerIndex(index);
            // setCurrentDob(value);
            // setIsQuestionerModalOpen(true);
            // return;
        }
        value = dateValue.toISOString().split('T')[0]; 
    }
    setAddTravelers((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = { ...updatedState[index], [name]: value };
        return updatedState;
    });
    setTravelerDob((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = { ...updatedState[index], [name]: value };
      if (name === "dob") {
        const dob = new Date(value);
        const today = new Date();
        const age = today.getFullYear() - dob.getFullYear();
        updatedState[index] = {
            ...updatedState[index],
            age: age,
        };
    }
      return updatedState;
  }); 
      };

      const handleAddTraveller = () => {
          setAddTravelers([
            ...addTravelers,
            {
              name: "",
              gender: "",
              dob: "",
              relation: "",
              benificiaryDob: "",
              benificiaryName: "",
            },
          ]);
      };
      const handleAddTravellerDob = () => {
        handleAddTraveller()
          setTravelerDob([
            ...travelerDob,
            {
              name: "",
              gender: "",
              dob: "",
              relation: "",
              benificiaryDob: "",
              benificiaryName: "",
            },
          ]);
      };
      const handleRemoveTraveller = (index) => {
        const updatedTravelers = [...addTravelers];
        updatedTravelers.splice(index, 1);
        setAddTravelers(updatedTravelers);
      };
      const handleRemoveTravellerDob = (index) => {
        handleRemoveTraveller(index)
        const updatedTravelers = [...travelerDob];
        updatedTravelers.splice(index, 1);
        setTravelerDob(updatedTravelers);
      };

      useEffect(() => {
        const validateDateFields = () => {
          const { firstDate, endDate, dob } = form;
          if (dob) {
            const selectedDate = new Date(dob);
            const today = new Date();
            const ageInMilliseconds = today - selectedDate;
            const ageInDays = ageInMilliseconds / (1000 * 60 * 60 * 24);
            const ageInYears = ageInDays / 365.25;
            setAge(Math.floor(ageInYears))
            const minDate = new Date();
            minDate.setDate(today.getDate() - 15);
            if (selectedDate >= minDate) {
              alert("Users must be at least 15 days older than today.");
              setForm((prevState) => ({
                ...prevState,
                dob: '',
              }));
              return;
            }
          }
          if (endDate) {
            const startDate = new Date(firstDate);
            const endDateValue = new Date(endDate);
            const diffInMilliseconds = endDateValue - startDate;
            const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;

            if (endDateValue < startDate) {
              alert("Last date cannot be earlier than the first date");
              setForm((prevState) => ({
                ...prevState,
                endDate: '',
              }));
              return;
            } else if (diffInMilliseconds > oneYearInMilliseconds) {
              alert("Duration cannot exceed 1 year");
              setForm((prevState) => ({
                ...prevState,
                endDate: '',
              }));
              return;
            } else {
              const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24) + 1);
              setDuration(durationInDays);
            }
          }
        };

        validateDateFields();
      }, [form]);

      const handleSubmit = async () => {
        const today = new Date();
        const dob = new Date(form.dob);
        const age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        const dayDiff = today.getDate() - dob.getDate();

        if (age > 89 || (age === 89 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)))) {
            alert('Date of birth cannot be more than 89 years ago');
            return;
        }
        if(!form.startDate){
          alert('Please enter start date')
          return;
        }
        if(!form.endDate){
          alert("Please enter end date")
          return;
        }
        if(!form.dob){
          alert("Please enter dob")
          return;
        }
        if(!form.preExisting){
          alert("Please Cost with or without pre-existing")
          return;
        }
        const payload = {
          policyType: 'Standard',
          dob: [form.dob],
          startDate: form.startDate,
          endDate: form.endDate,
          sumInsured: form.sumInsured,
          deductibles: form.deductibles,
          preExisting: form.preExisting,
        };
        console.log(payload,'payload')
            const result = await dispatch(vtvCompareList(payload));
            setResponseData(result.data);
            setItemsData(result.data)
      }

      const handleFamilySubmit = async () => {
        if(addTravelers.length<3){
          alert('Select Atleast 3 member of family')
          return;
        }
        const eldestMember = addTravelers.reduce((eldest, traveler) => {
          const dob = new Date(traveler.dob);
          const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
          if (!eldest || age > eldest.age) {
              return { age, traveler };
          }
          return eldest;
        }, null);
        if (!eldestMember || eldestMember.age < 27 || eldestMember.age > 69) {
            alert("The eldest family member's age should be between 27 and 69 years for family coverage.");
            return;
        }
        const hasChildMember = addTravelers.some((traveler) => {
            const dob = new Date(traveler.dob);
            const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
            return age >= 1 && age <= 26;
        });
        if (!hasChildMember) {
            alert('You must select atleast one child up to 21 or 26 if fully dependent on parents');
            return;
        }
        const dependentChildren = addTravelers.filter((traveler) => {
            const dob = new Date(traveler.dob);
            const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
            return age >= 21 && age <= 26;
        });
        const olderThan26Count = addTravelers.reduce((count, traveler) => {
          const dob = new Date(traveler.dob);
          const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
          if (age > 26) {
            return count + 1;
          }
          return count;
        }, 0);
        if (olderThan26Count > 2) {
          alert('There should not be more than two family members whose age is more than 26 years.');
          return;
        }
        if (dependentChildren.length > 0 && olderThan26Count<=2) {
          // setIsFamilyModel(true)
          // if(familyCoverage===false){
          // }else{
          //   console.log('already selected')
          // }
        }
        const dobs = travelerDob.map(traveler => traveler.dob);
        if(!familyForm.startDate){
          alert('Please enter start date')
          return;
        }
        if(!familyForm.endDate){
          alert("Please enter end date")
          return;
        }
        if(!familyForm.preExisting){
          alert("Please Cost with or without pre-existing")
          return;
        }
        const payload = {
          policyType: 'Standard',
          dob: dobs,
          startDate: familyForm.startDate,
          endDate: familyForm.endDate,
          sumInsured: familyForm.sumInsured,
          deductibles: familyForm.deductibles,
          preExisting: familyForm.preExisting,
          familyPolicy: true
        };
        console.log(payload,'payload')
            const result = await dispatch(vtvCompareList(payload));
            setResponseData(result.data);
            setItemsData(result.data)
      }

      const handleRateModal=(data)=>{
        setBuyNowData(data)
        setIsRateModalOpen(true)
      }

      const handleRateModalClose=()=>{
        setIsRateModalOpen(false)
      }

      const handleBuyNow = () => {
        const storedData = localStorage.getItem("code_dev1");
        if (!storedData) {
          setIsLoginModalOpen(true);
          return;
        }
        const item = {
          datas: buyNowData,
          row:form
        };
        navigate('/single-quote', { state: { item } });
      };

      const handleBuyNowAfterLogin=()=>{
        const item = {
          datas: buyNowData,
          row:form
        };
        navigate('/single-quote', { state: { item } });
      }

      const handleComparedModalClose=()=>{
        setIsComparedModalOpen(close)
      }
  
      const getMaxDate = () => {
        const today = new Date();
        today.setDate(today.getDate() + 180);
        return today.toISOString().split('T')[0];
      };


return (
<>
<Box pt={['78px','98px','98px']} background={'#efefef'} position={['absolute','fixed','fixed']} h={'100vh'} w={'100%'}>
    <Flex w={'98%'} m={'auto'} gap={'20px'} h={['auto','100vh','100vh']} direction={['column','column','row']}>    
        <Box borderRadius={'5px'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' 
            background={'white'} 
            w={['98%','20%','20%']} 
            p={'20px'} 
            overflowY='auto' 
            pb={['20px','120px','120px']}
            fontFamily={'Poppins, sans-serif'} fontSize={'12px'} >
            <Text fontFamily={'Montserrat,sans-serif'} textAlign={'center'} fontSize='20px' fontWeight='600' p={'10px'}>Supervisa Insurance</Text>
            <hr />
            <Box>
                <Text pt={'10px'}>Policy Type*</Text>
            <Tabs align='center' variant='soft-rounded' colorScheme='transparent'>
                <TabList>
                    <Tab _selected={{background:'white', color:'teal'}} fontSize={'12px'}>Single</Tab>
                    <Tab _selected={{background:'white', color:'teal'}} fontSize={'12px'}>Family</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel textAlign={'left'}>
                      <Box>
                            <Text pb={'10px'}>Date Of Birth</Text>
                            <Box w={'99%'} m={'auto'}>
                                <Input size={'xs'} type='date' max={getMaxSelectableDate()} min={getMinSelectableDate()} name="dob" value={form.dob} onChange={handleChange}/>
                            </Box>
                        </Box>
                        <Box mt={'10px'}>
                            <Text pb={'2px'}>Start Date</Text>
                            <Box w={'99%'} m={'auto'}>
                                <Input max={getMaxDate()} min={new Date().toISOString().split("T")[0]} size={'xs'} type='date'  name="startDate" value={form.startDate} onChange={handleChange}/>
                            </Box>
                        </Box>
                        <Box mt={'10px'}>
                            <Text pb={'2px'}>End Date</Text>
                            <Box w={'99%'} m={'auto'}>
                                <Input readOnly size={'xs'} type='date' name="endDate" value={form.endDate} onChange={handleChange}/>
                            </Box>
                        </Box>
                        <Box mt={'10px'}>
                        <Text pb={'2px'}>Coverages</Text>
                        <Box w={'99%'} m={'auto'}>
                            <Select size={'xs'} name="sumInsured" value={form.sumInsured} onChange={handleChange}>
                            <option value="10000">10000</option>
                            <option value="25000">25000</option>
                            <option value="50000">50000</option>
                            <option value="100000">100000</option>
                            <option value="150000">150000</option>
                            <option value="300000">300000</option>
                            </Select>
                        </Box>
                        </Box>
                        <Box mt={'10px'}>
                        <Text pb={'2px'}>Deductibles</Text>
                        <Box w={'99%'} m={'auto'}>
                            <Select size={'xs'} name="deductibles" value={form.deductibles} onChange={handleChange}>
                            <option value="0">0</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="2500">2500</option>
                            <option value="3000">3000</option>
                            <option value="5000">5000</option>
                            <option value="10000">10000</option>
                            </Select>
                        </Box>
                        </Box>
                        <Box mt={'5px'}>
                            <Text pb={'5px'}>Cover Pre-existing*</Text>
                            <Flex justifyContent={'space-around'}>
                                <Flex gap={'5px'}>
                                    <input type="radio" name="preExisting" value="Yes" onChange={handleChange} />
                                    <Text>Yes</Text> 
                                </Flex>
                                <Flex gap={'5px'}>
                                    <input type="radio" name="preExisting" value="No" onChange={handleChange} />
                                    <Text>No</Text>
                                </Flex>
                            </Flex>
                            <Box>
                        <Button size={'xs'} mt={'20px'} background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} onClick={handleSubmit}>Submit</Button>
                    </Box>
                        </Box>
                    </TabPanel>
                    <TabPanel textAlign={'left'}>
                    <Box>
                    {travelerDob.map((item, index) => {
                      return (    
                            <Flex>
                            <Box>
                            <Text>Date Of Birth</Text>
                            <div className="date-picker-container">
                              <DatePicker
                                selected={item.dob}
                                onChange={(date) => handleChangeTraveler(index, date,'dob')}
                                dateFormat="yyyy-MM-dd"
                                className="custom-date-picker"
                                maxDate={getMaxSelectableDate()}
                                minDate={getMinSelectableDate()} 
                                placeholderText='yyyy-mm-dd'
                                showYearDropdown
                                showMonthDropdown
                                yearDropdownItemNumber={15}
                                scrollableYearDropdown
                                // ref={datePickerRefsDob.current[index]}
                              />
                              <FaCalendarAlt className="date-icon" />
                            </div>
                            </Box>
                            {index > 0 && (
                              <Flex justifyContent={'start'}>
                                <IoMdCloseCircle cursor={'pointer'}
                                  style={{fontSize:'16px',color:buttonBackgroundColor}}
                                  onClick={() => handleRemoveTravellerDob(index)}
                                />
                              </Flex>
                            )}
                            </Flex>
                      )})}
                      <IoMdAdd style={{fontWeight:'bold',color:'teal'}} cursor={'pointer'} onClick={handleAddTravellerDob}/>
                      </Box>
                            <Box mt={'10px'}>
                                <Text pb={'5px'}>Start Date</Text>
                                <Box w={'99%'} m={'auto'}>
                                    <Input size={'xs'} type='date'  name="startDate" value={familyForm.startDate} onChange={handleFamilyChange} max={getMaxDate()} min={new Date().toISOString().split("T")[0]}/>
                                </Box>
                            </Box>
                            <Box mt={'15px'}>
                                <Text pb={'5px'}>End Date</Text>
                                <Box w={'99%'} m={'auto'}>
                                    <Input size={'xs'} type='date' name="endDate" readOnly value={familyForm.endDate}/>
                                </Box>
                            </Box>
                            <Box mt={'15px'}>
                            <Text pb={'5px'}>Coverages</Text>
                            <Box w={'99%'} m={'auto'}>
                                <Select size={'xs'} name="sumInsured" value={familyForm.sumInsured} onChange={handleFamilyChange}>
                                <option value="10000">10000</option>
                                <option value="25000">25000</option>
                                <option value="50000">50000</option>
                                <option value="100000">100000</option>
                                <option value="150000">150000</option>
                                <option value="300000">300000</option>
                                </Select>
                            </Box>
                            </Box>
                            <Box mt={'10px'}>
                              <Text pb={'2px'}>Deductibles</Text>
                              <Box w={'99%'} m={'auto'}>
                                  <Select size={'xs'} name="deductibles" value={familyForm.deductibles} onChange={handleFamilyChange}>
                                  <option value="0">0</option>
                                  <option value="100">100</option>
                                  <option value="250">250</option>
                                  <option value="500">500</option>
                                  <option value="1000">1000</option>
                                  <option value="2500">2500</option>
                                  <option value="3000">3000</option>
                                  <option value="5000">5000</option>
                                  <option value="10000">10000</option>
                                  </Select>
                              </Box>
                              </Box>
                            <Box mt={'15px'}>
                                <Text pb={'5px'}>Cover Pre-existing*</Text>
                                <Flex justifyContent={'space-around'}>
                                    <Flex gap={'5px'}>
                                        <input type="radio" name="preExisting" value="Yes" onChange={handleFamilyChange} />
                                        <Text>Yes</Text> 
                                    </Flex>
                                    <Flex gap={'5px'}>
                                        <input type="radio" name="preExisting" value="No" onChange={handleFamilyChange} />
                                        <Text>No</Text>
                                    </Flex>
                                </Flex>
                                <Box>
                            <Button size={'xs'} mt={'10px'} background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} onClick={handleFamilySubmit}>Submit</Button>
                        </Box>
                            </Box>
                    </TabPanel>
                </TabPanels>
                </Tabs>
            </Box>            
        </Box>
        
        

    <Box borderRadius={'5px'} w={['98%','80%','80%']}>
        <Box background={'white'} mb={'120px'} h={'90vh'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' overflowY="auto" p="10px">
            <Box>
                <Text fontSize={'13px'} fontFamily={'Poppins, sans-serif'}>Super visa insurance for (age {age} yrs) for 365 days, {form.preExisting==='No'?'excluded':'included'} coverage for pre-existing medical conditions</Text>
            </Box>
            <Box mt={'35px'} mb={'30px'}>
            {responseData.map(item => (
                <Accordion defaultIndex={[0]} allowMultiple>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='center'>
                                <Flex direction={['column','column','row']} justifyContent={'space-around'} alignItems={'center'}>
                                <Flex w={['65%','15%','15%']} p={'30px'}>
                                      <ImageItem imageBytes={item.image} />
                                    </Flex>
                                     <Flex w={['95%','20%','20%']} direction={'column'} fontSize={'18px'} fontWeight={'600'}>
                                       {item.policyAmount} $
                                      </Flex>
                                     <Box w={['95%','25%','25%']}>
                                        <Text fontSize={'12px'}>{item.description}</Text>
                                     </Box>
                                     <Flex direction={'column'} justifyContent={'center'} w={['90%','20%','20%']}>
                                     <Flex justifyContent={'center'} gap={'10px'} p={'5px'} fontSize={'16px'} fontWeight={'600'} alignItems={'center'}>
                                     <Button size={'xs'} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} background={buttonBackgroundColor} color={buttonColor} onClick={() => handleRateModal(item)}>Save</Button>
                                    <Button size={'xs'} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} background={buttonBackgroundColor} color={buttonColor} onClick={() => handleRateModal(item)}> Buy</Button>
                                     </Flex>
                                     <Flex justifyContent={'center'} gap={'5px'} pb={'17px'}>
                                    <input type="radio" onClick={() => handleAddToCompare(item.name)} />
                                    <Text>Add to compare</Text>
                                </Flex>
                                     </Flex>
                                  </Flex>
                            </Box>
                                <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Box background={'#F4F4F4'} w={'95%'} p={'20px'} m={'auto'}>
                                <Flex direction={['column','column','row']} justifyContent={'space-around'}>
                                    <Box w={['95%','30%','30%']}>
                                        <Text fontWeight={'600'}>Eligibility</Text>
                                        <Text color={'#666'} fontSize={'12px'} fontFamily={'"Aspira W01",sans-serif'}>{item.eligibility}</Text>
                                    </Box>
                                    <Box w={['95%','30%','30%']}>
                                    <Text fontWeight={'600'}>Coverages</Text>
                                    <Box color={'#666'} fontSize={'12px'} fontFamily={'"Aspira W01",sans-serif'}>
                                        {item.coverage}
                                        </Box>
                                    </Box>
                                    <Box w={['95%','30%','30%']}>
                                    <Text fontWeight={'600'}>Exclusions</Text>
                                    <Text color={'#666'} fontSize={'12px'} fontFamily={'"Aspira W01",sans-serif'}>{item.exclusion}</Text>
                                    <Text mt={'10px'} color={buttonBackgroundColor} fontSize={'14px'} fontFamily={'"Aspira W01",sans-serif'}>Policy wording</Text>
                                    <Text color={buttonBackgroundColor} fontSize={'14px'} fontFamily={'"Aspira W01",sans-serif'}>View policy pdf</Text>
                                    </Box>
                                </Flex>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
                 ))}
            </Box>
        </Box>
        </Box>
    </Flex>
</Box>
    

    {selectedItems.length > 0 && (
        <Box boxShadow='rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset' fontFamily={'Poppins, sans-serif'} position="fixed" bottom="10px" right="10px" background="white" border="1px solid grey" p="10px" borderRadius="5px" fontSize={'13px'}>
          <Text fontWeight="bold">Selected for Comparison:</Text>
          <Box mt="5px">
            {selectedItems.map(item => (
              <Text key={item.id}>{item.name}</Text>
            ))}
          </Box>
          <Button size={'xs'} mt="10px" background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} onClick={handleCompare}>
            Compare
          </Button>
        </Box>
      )}
    <ComparedProductsModal isOpen={isComparedModalOpen} onClose={handleComparedModalClose} items={comparedData}/>
    <GetRateModal isOpen={isRateModalOpen} onClose={handleRateModalClose} onSubmit={handleBuyNow}/>
    <LoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} onSubmit={handleBuyNowAfterLogin} />
    </>
  )
}

export default SuperVisaCompare
