import { CUSTOMER_LOGIN, CUSTOMER_SIGNUP } from "../../../constants/ApplicationUrl";
import Api from "../../../dependencies/utils/Api";
export const CustomerSignupService = {
    async customerSignupList(params) {
        console.log(params,'params2');
        try {
            const response = await Api.POST(CUSTOMER_SIGNUP, params);
            console.log(response,'response');
            const { data: { message, status, data } = {} } = response;
            if (status) {
                return { message, status, data };
            } else {
                return { message, status };
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    customerLoginList(params) {
        return Api.POST(CUSTOMER_LOGIN, params).then((response) => {
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
    async advisorSignupList(params) {
        try {
            const response = await Api.POST(ADVISOR_SIGNUP, params);
            const {status} = response;
            console.log(response,'resss')
            if (status) {
                return response;
            } else {
                return status;
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
};

