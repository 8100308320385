// import { PRODUCT_LIST } from "../../constants/actionConstants";

// const initialState = {
//   products: [],
// };

// // eslint-disable-next-line import/no-anonymous-default-export
// export default (state = initialState, action) => {
//   switch (action.type) {
//     case PRODUCT_LIST:
//       return { ...state, products: action.payload };
//     default:
//       return state;
//   }
// };

import { PRODUCT_LIST } from '../../constants/actionConstants';

const initialState = {
    products: []
};

// Name the reducer function
const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_LIST:
      return { ...state, products: action.payload };
    default:
      return state;
  }
};

// Export the named function as the default export
export default productReducer;
