// import {IS_LOADING} from '../../constants/actionConstants';

// const initialState = {
//     isLoading: false
// };

// // eslint-disable-next-line import/no-anonymous-default-export
// export default (state = initialState, action) => {
//   switch(action.type) {
//       case IS_LOADING:
//           return {...state, isLoading: action.payload};
//     default:
//       return state;
//   }
// }

import { IS_LOADING } from '../../constants/actionConstants';

const initialState = {
    isLoading: false
};

// Name the reducer function
const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

// Export the named function as the default export
export default loaderReducer;
