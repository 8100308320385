export const IS_LOADING = 'IS_LOADING';
export const SET_USER = 'SET_USER'
export const SET_LOGGED_IN = 'SET_LOGGED_IN'
export const PRODUCT_LIST = 'PRODUCT_LIST'
export const CUSTOMER_SIGNUP = "CUSTOMER_SIGNUP";
export const CUSTOMER_LOGIN = "CUSTOMER_LOGIN";
export const VTC_COMPARSION_TYPE="VTC_COMPARSION_TYPE";
export const FORGET_PASSWORD_TYPE="FORGET_PASSWORD_TYPE";
export const ADVISOR_LOGIN = "ADVISOR_LOGIN";
export const CUSTOMER_GET_ALL="CUSTOMER_GET_ALL";
export const ADMIN_PROLFILE="ADMIN_PROLFILE";
export const AVSIOR_PERSONAL_DETAILS_EDIT_TYPE="AVSIOR_PERSONAL_DETAILS_EDIT_TYPE";
export const AVSIOR_LICENSE_DETAILS_EDIT_TYPE="AVSIOR_LICENSE_DETAILS_EDIT_TYPE";
export const ADVISOR_CORPORATION_DETAILS_TYPE="ADVISOR_CORPORATION_DETAILS_TYPE";
export const ADVISOR_REFERNCE_DETAILS_TYPE="ADVISOR_REFERNCE_DETAILS_TYPE";
export const ADVISOR_PROFILE_LIST="ADVISOR_PROFILE_LIST"



export const isLoading = (data) => {
    return {
        type: IS_LOADING,
        payload: data
    };
}