import React from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import { MdTravelExplore } from "react-icons/md";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../Home/Style.css'
import tugo from '../../assets/img/tugo.jpg'
import { Link } from "react-router-dom";
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor } from "../../Utils/UniversalColors";
import imgg from '../../assets/img/imgg.png'



const TravelComparison = () => {
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };


return (
    <Box pb={'50px'}>
      <Box backgroundSize={'cover'} backgroundRepeat={'no-repeat'} backgroundPosition={'right'} backgroundImage={'https://envato.bdevs.net/tourigo/wp-content/uploads/2024/05/landing-bg.png'} pt={'50px'} pb={'50px'}>
        <Flex>
        <Box w={'45%'} pl={'52px'} pt={'40px'}>
          <Text fontFamily='Manrope, sans-serif' fontWeight={'700'} color={'rgb(75 89 102)'} fontSize={'32px'} >Find the Best Travel Insurance in Canada</Text> 
          <Text fontSize={'16px'} fontFamily={'poppins'} pt={'20px'}>Compare the cheapest travel insurance quotes to get the best coverage available.</Text>
          <Box pt={'30px'}>
            <Flex>
                <Link to='/travel-comparison-form'>
                <Button
                _hover={{
                  background: buttonHoverBackground,
                  color: buttonHoverColor
                }}
                size={'md'}
                backgroundColor={buttonBackgroundColor}
                color={buttonColor}
                fontFamily={'Poppins, sans-serif'}
                fontWeight={'500'}
                fontSize={'13px'}
              >
                Get A Quote
              </Button>
              </Link>
            </Flex>
          </Box>
        </Box>
        <Flex justifyContent={'center'} w={'50%'} m={'auto'}>
          <Flex justifyContent={'center'}>
          <Image mt={'-10px'} w={'450px'} src={imgg}/>
          </Flex>
        </Flex>
        </Flex>
      </Box>

      <Box w={'80%'} m={'auto'} mt={'30px'}>
        <Flex justifyContent={'space-around'}>
          <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(255 234 233)',backgroundImage:'none'}} backgroundColor={'rgb(255 234 233)'} p={'10px'}>
          <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
            <Flex justifyContent={'center'}>
          <img width="48" height="48" src="https://img.icons8.com/parakeet/48/user.png" alt="user"/>              
            </Flex>
          <Text mt={'10px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Super Visa</Text>
              <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
          </Box>
          </Box>

          <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(236 240 255)',backgroundImage:'none'}} backgroundColor={'rgb(236 240 255)'} p={'10px'}>
          <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
            <Flex justifyContent={'center'}>
          <img width="48" height="48" src="https://img.icons8.com/parakeet/48/user.png" alt="user"/>              
            </Flex>
          <Text mt={'10px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Visitor Insurance</Text>
              <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
          </Box>
          </Box>


          <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(253 225 245)',backgroundImage:'none'}} backgroundColor={'rgb(253 225 245)'} p={'10px'}>
          <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
            <Flex justifyContent={'center'}>
          <img width="48" height="48" src="https://img.icons8.com/parakeet/48/user.png" alt="user"/>              
            </Flex>
          <Text mt={'10px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Student Visa</Text>
              <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
          </Box>
          </Box>

          <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(249 249 217)',backgroundImage:'none'}} backgroundColor={'rgb(249 249 217)'} p={'10px'}>
          <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
            <Flex justifyContent={'center'}>
          <img width="48" height="48" src="https://img.icons8.com/parakeet/48/user.png" alt="user"/>              
            </Flex>
          <Text mt={'10px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Single Trip</Text>
              <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
          </Box>
          </Box>

        </Flex>
      </Box>



      <Box w={'95%'} m={'auto'} mt={'120px'}>
        <Flex justifyContent={'space-between'}>
          <Box p={'25px'} background={'#ededef'} w={'45%'} h={'auto'} borderRadius={'5px'}>
          <Text fontFamily='Manrope, sans-serif' fontWeight={'700'} color={'rgb(75 89 102)'} fontSize={'24px'} >Super Visa</Text> 
          <Box fontSize={'14px'} p={'20px'}>
                    <ul style={{lineHeight:'28px'}}>
                        <li> Min of CAD 100,000 of Sum Insured for min duration of 365 days.</li>
                        <li> No Cancellation Penalty.</li>
                        <li>Age from 15 days to 90 Years & 3 Product Choices Standard, Enhanced and Premium.</li>
                        <li>Just 90 days stability condition up to age 74.</li>
                        <li>Monthly Payments Available.</li>
                        <li> Covers you for any unforeseen Emergency Medical, Physician & Hospitalization expenses, Diagnostics, Prescriptions and Dental etc.</li>
                    </ul>
                    </Box>
          </Box>
          <Box p={'25px'} background={'#ededef'} w={'45%'} h={'auto'} borderRadius={'5px'}>
          <Text fontFamily='Manrope, sans-serif' fontWeight={'700'} color={'rgb(75 89 102)'} fontSize={'24px'} >Single Trip</Text> 
          <Box fontSize={'14px'} p={'20px'} pl={'90px'}>
                    <ul style={{lineHeight:'28px'}}>
                        <li> Min of CAD 100,000 of Sum Insured for min duration of 365 days.</li>
                        <li> No Cancellation Penalty.</li>
                        <li>Age from 15 days to 90 Years & 3 Product Choices Standard, Enhanced and Premium.</li>
                        <li>Just 90 days stability condition up to age 74.</li>
                        <li>Monthly Payments Available.</li>
                        <li> Covers you for any unforeseen Emergency Medical, Physician & Hospitalization expenses, Diagnostics, Prescriptions and Dental etc.</li>
                    </ul>
                    </Box>
          </Box>
        </Flex>
      </Box>



      <Box w={'80%'} m={'auto'} mt={'140px'}>
        <Text fontFamily='Manrope, sans-serif' fontWeight={'700'} color={'rgb(75 89 102)'} fontSize={'24px'} textAlign={'center'} pt={'20px'}>We Compare Rates From Best</Text>
        <Box mt={'50px'} overflow="hidden">
        <Carousel
          swipeable
          draggable
          responsive={responsive}
          infinite
          showDots
          autoPlay
          autoPlaySpeed={5000}
          keyBoardControl
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-20-px"
        >
            <Flex w={'150px'} alignItems={'center'} direction={'column'} p={'20px'} border={'1px solid grey'} borderRadius={'10px'}>
              <Image src={tugo}/>
                <Text pt={'15px'} fontSize={'18px'} fontFamily={'Open Sans,sans-serif'}>Tugo</Text>
            </Flex>
            <Flex w={'150px'} alignItems={'center'} direction={'column'} p={'20px'} border={'1px solid grey'} borderRadius={'10px'}>
              <Image src={tugo}/>
                <Text pt={'15px'} fontSize={'18px'} fontFamily={'Open Sans,sans-serif'}>Tugo</Text>
            </Flex>
            <Flex w={'150px'} alignItems={'center'} direction={'column'} p={'20px'} border={'1px solid grey'} borderRadius={'10px'}>
              <Image src={tugo}/>
                <Text pt={'15px'} fontSize={'18px'} fontFamily={'Open Sans,sans-serif'}>Tugo</Text>
            </Flex>
            <Flex w={'150px'} alignItems={'center'} direction={'column'} p={'20px'} border={'1px solid grey'} borderRadius={'10px'}>
              <Image src={tugo}/>
                <Text pt={'15px'} fontSize={'18px'} fontFamily={'Open Sans,sans-serif'}>Tugo</Text>
            </Flex>
            <Flex w={'150px'} alignItems={'center'} direction={'column'} p={'20px'} border={'1px solid grey'} borderRadius={'10px'}>
              <Image src={tugo}/>
                <Text pt={'15px'} fontSize={'18px'} fontFamily={'Open Sans,sans-serif'}>Tugo</Text>
            </Flex>
            <Flex w={'150px'} alignItems={'center'} direction={'column'} p={'20px'} border={'1px solid grey'} borderRadius={'10px'}>
              <Image src={tugo}/>
                <Text pt={'15px'} fontSize={'18px'} fontFamily={'Open Sans,sans-serif'}>Tugo</Text>
            </Flex>
          </Carousel>
        </Box>
        <Flex justifyContent={'center'} mt={'50px'}>
          <Link to='/travel-all-providers'>
              <Button size={'sm'} fontFamily={'Open Sans,sans-serif'} border={`1px solid ${buttonBackgroundColor}`} color={buttonBackgroundColor} borderRadius={'20px'} background={'white'}>View All Providers</Button>
          </Link>
            </Flex>
      </Box>
    </Box>
  )
}

export default TravelComparison
