import { combineReducers } from 'redux';
import loaderReducer from './loader.reducer';
import productReducer from './product.Reducer';


const appReducer = combineReducers({
    loader: loaderReducer,
    products: productReducer,
});

export default appReducer;