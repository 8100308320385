export const API_URL = () => process.env.REACT_APP_API_BASE;

export const SENT_LOGIN_OTP = `${API_URL()}/api/client-login`
export const LOGIN_OTP_VERIFY = `${API_URL()}/api/client-login-verify`
export const SERVICE_LIST = `${API_URL()}/service-url`
export const CUSTOMER_SIGNUP=`${API_URL()}/users/createuser`
export const CUSTOMER_LOGIN = `${API_URL()}/api/v1/login`;
export const VTC_COMPARSION = `${API_URL()}/comparisonCalculation`;
export const STUDENT_COMPARISON = `${API_URL()}/comparisonCalculationForStudent`;
export const SAVE_QUOTE= `${API_URL()}/Quote/generateComparisonQuote`;
export const GET_QUOTE_BY_CUSTOMER = `${API_URL()}/Quote/getListOfCompareQuotationBeanByUser`
export const VIEW_SINGLE_QUOTE = `${API_URL()}/Quote/getComparisionQuoteByQuotationNo`
export const FORGET_PASSWORD=`${API_URL()}/api/passwordreset/reset-password`;
export const ADVISOR_LOGIN = `${API_URL()}/api/v1/advisorlogin`;
export const ADVISOR_SIGNUP = `${API_URL()}/users/createAdvisor`;
export const CANCEL_QUOTE=`${API_URL()}/Quote/cancelComparisionQuote?quotationNumber=`
export const CUSTOMER_PROFILE = `${API_URL()}/users/getUser`;
export const ADVISOR_PROFILE = `${API_URL()}/users/getUser`;
export const CUSTOMER_EDIT_PROFILE = `${API_URL()}/api/v1/users/updateCustomer`;
export const ADVISOR_PERSONAL_DETAILS=`${API_URL()}/users/updateAdvisor`
export const ADVISOR_LICENSE_DETAILS_EDIT=`${API_URL()}/rest/v1/advisor/addLicenceDetails`;
export const ADVISOR_CORPORATION_DETAILS=`${API_URL()}/rest/v1/advisor/addCorporationDetails`;
export const ADVISOR_REFRENCE_DETAILS=`${API_URL()}/rest/v1/advisor/addReferencesDetails`;
export const ADVISOR_LICENCE_DETAILS=`${API_URL()}/rest/v1/advisor/addLicenceDetails`;
export const DOWNLOAD_DESTINATION_POLICY_WORDING=`${API_URL()}/file/downloadFileOfDestinationPolicyWording`