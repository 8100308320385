import { Box, Button, Flex, Input, Progress, Select, Stack, Step, StepIcon, StepIndicator, StepSeparator, StepStatus, Stepper, Text, useSteps } from '@chakra-ui/react'
import React from 'react'
import { buttonBackgroundColor, buttonColor } from '../../../Utils/UniversalColors'
import { Link } from 'react-router-dom'

const TravelComparisonForm = () => {
    const steps = [
        { title: 'First', description: 'Enter Details' },
        { title: 'Second', description: 'Quotes' },
        { title: 'Third', description: 'Buy Quotes' },
      ]

      const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
      })
    
      const activeStepText = steps[activeStep].description


return (
    <Box background={'#f6f6f6'} pb={'40px'}>
        <hr />
        {/* <Stack w={'40%'} m={'auto'} mt={'50px'}>
                <Stepper size='sm' index={activeStep} gap='0'>
                    {steps.map((step, index) => (
                    <Step key={index} gap='0'>
                        <StepIndicator>
                        <StepStatus complete={<StepIcon />} />
                        </StepIndicator>
                        <StepSeparator _horizontal={{ ml: '0' }} />
                    </Step>
                    ))}
                </Stepper>
                <Text>
                    Step {activeStep + 1}: <b>{activeStepText}</b>
                </Text>
        </Stack> */}
        
        <Box fontSize={'14px'} background={'white'} w={'85%'} m={'auto'} p={'30px'} mt={'40px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px;'>
            <Flex gap={'50px'} justifyContent={'center'} pt={'20px'}>
                <Box>
                    <Text pb={'5px'} color={'rgb(119 119 119)'} fontFamily={'Manrope, sans-serif'}>Your Postal Code</Text>
                    <Input placeholder='postal code' w={'300px'} size={'md'} />
                </Box>
                <Box>
                    <Text pb={'5px'} color={'rgb(119 119 119)'} fontFamily={'Manrope, sans-serif'}>Trip Destination</Text>
                    <Input placeholder='destination' w={'300px'} size={'md'} />
                </Box>
                <Box>
                    <Text pb={'5px'} color={'rgb(119 119 119)'} fontFamily={'Manrope, sans-serif'}>Birth Date of Oldest traveller</Text>
                    <Input type='date' w={'300px'} size={'md'} />
                </Box>
            </Flex>
            <Flex gap={'50px'} justifyContent={'center'} pt={'20px'}>
                <Box>
                    <Text pb={'5px'} color={'rgb(119 119 119)'} fontFamily={'Manrope, sans-serif'}>Departure Date</Text>
                    <Input type='date' w={'300px'} size={'md'} />
                </Box>
                <Box>
                    <Text pb={'5px'} color={'rgb(119 119 119)'} fontFamily={'Manrope, sans-serif'}>Return Date</Text>
                    <Input type='date' w={'300px'} size={'md'} />
                </Box>
                <Box>
                    <Text pb={'5px'} color={'rgb(119 119 119)'} fontFamily={'Manrope, sans-serif'}>Trip cost per traveller</Text>
                    <Input type='number' w={'300px'} size={'md'} />
                </Box>
            </Flex>
            <Flex gap={'50px'} justifyContent={'center'} pt={'20px'}>
                <Box>
                    <Text pb={'5px'} color={'rgb(119 119 119)'} fontFamily={'Manrope, sans-serif'}>Number of Travellers</Text>
                    <Input w={'300px'} size={'md'} />
                </Box>
                <Box>
                    <Text pb={'5px'} color={'rgb(119 119 119)'} fontFamily={'Manrope, sans-serif'}>Gender</Text>
                    {/* <Input w={'300px'} size={'md'} /> */}
                    <Select w={'300px'}>
                        <option value="">Select</option>
                        <option value="">Male</option>
                        <option value="">Female</option>
                    </Select>
                </Box>
                <Box>
                    <Text pb={'5px'} color={'rgb(119 119 119)'} fontFamily={'Manrope, sans-serif'}>Any Travellers smokes?
                </Text>
                    <Input w={'300px'} size={'md'} />
                </Box>
            </Flex>
            <Flex pl={'65px'} justifyContent={'start'} pt={'20px'}>
                <Box>
                    <Text pb={'5px'} color={'rgb(119 119 119)'} fontFamily={'Manrope, sans-serif'}>Email Address</Text>
                    <Input placeholder='email' w={'300px'} size={'md'} />
                </Box>
            </Flex>
            <Flex gap={'10px'} pl={'65px'} alignItems={'center'} justifyContent={'start'} pt={'30px'}>
                <input type="radio" />
            <Text pb={'5px'} color={'rgb(119 119 119)'} fontFamily={'Manrope, sans-serif'}>Yes, I'd like to receive offers such as Ratesbot renewal service and newsletters, via email/SMS/phone from Travel Comparison Group Ltd. and its family of companies. I may unsubscribe at any time.Click here for contest rules.</Text>
            </Flex>
                <Flex mt={'30px'} alignItems={'center'} justifyContent={'center'}>
                    <Link to={'/travel-comparison-quotes'}>
                    <Button background={buttonBackgroundColor} color={buttonColor}>Get Quotes</Button>
                    </Link>
                </Flex>
        </Box>


    </Box>
  )
}

export default TravelComparisonForm
