import { Box, Flex, Grid, GridItem, Image, Input, Select, Text } from '@chakra-ui/react'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const CompareTravelProducts = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { items } = location.state || { items: [] };


return (
    <Box background={'#f6f6f6'}>
        <hr />
        {/* <Box w={'95%'} m={'auto'} mt={'50px'}>
            <Text fontSize={'22px'} fontFamily={'Manrope, sans-serif'}>Compare Supervisa Product according your needs.</Text>
        </Box> */}
        <Box w={'95%'} m={'auto'} pb={'120px'} mt={'30px'}>
            <Flex gap={'50px'}>
                <Box w={'20%'} cursor={'pointer'} background={'white'} color={'rgb(119 119 119)'} border={'1px solid rgb(238 238 238)'} fontSize={'15px'} fontWeight={'500'} fontFamily={'Manrope, sans-serif'}>
                <Flex justifyContent={'center'} alignItems={'center'} h={'90px'}>
                    <Text p={'15px'}>Filters & Criteria</Text>
                </Flex>
                <hr />
                <Box p={'10px'}>
                    <Text p={'10px'} pt={'0px'} mt={'10px'}>Select DOB</Text>
                    <Flex w={'80%'} m={'auto'}>
                    <Input type='date' size={'sm'}/>
                    </Flex>
                </Box>
                </Box>
            <Box w={'80%'}>
            <Grid templateColumns='repeat(3, 1fr)' gap={'25px'}>
                {/* <GridItem background={'white'} border={'1px solid rgb(238 238 238)'} fontSize={'15px'} fontWeight={'600'} fontFamily={'Manrope, sans-serif'}>
                <Flex justifyContent={'center'} alignItems={'center'} h={'150px'} border={'1px solid rgb(238 238 238)'}>
                    <Text p={'15px'}>Provider</Text>
                </Flex>
                <Box border={'1px solid rgb(238 238 238)'}>
                    <Text p={'15px'}>Sum Insured</Text>
                </Box>
                <Box border={'1px solid rgb(238 238 238)'}>
                    <Text p={'15px'}>Standard</Text>
                </Box>
                <Box border={'1px solid rgb(238 238 238)'}>
                    <Text p={'15px'}>Enhanced</Text>
                </Box>
                <Box border={'1px solid rgb(238 238 238)'}>
                    <Text p={'15px'}>Premium</Text>
                </Box>
                <Box border={'1px solid rgb(238 238 238)'}>
                    <Text p={'15px'}>Description</Text>
                </Box>
                </GridItem> */}
                <GridItem cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.24) 0px 3px 8px'}} background={'white'} color={'rgb(119 119 119)'} border={'1px solid rgb(238 238 238)'} fontSize={'15px'} fontWeight={'500'} fontFamily={'Manrope, sans-serif'}>
                <Flex h={'150px'} justifyContent={'center'} alignItems={'center'}>
                    <Image w={'50%'} h={'50%'} src='https://www.desttravel.com/images/Destination_Travel_Logo_2018_Bilingual_1.png' />
                </Flex>
                    <Box border={'1px solid rgb(238 238 238)'} p={'15px'}>
                        <Select size={'sm'}>
                            <option value="">Select</option>
                            <option value="25000">$25000</option>
                            <option value="50000">$50000</option>
                        </Select>
                    </Box>
                    <Box border={'1px solid rgb(238 238 238)'} _hover={{background:'#f6f6f6'}}>
                        <Text fontSize={'13px'} w={'90%'} m={'auto'}>Standard</Text>
                        <Box p={'15px'}>
                        <Flex justifyContent={'space-around'}>
                            <Box>
                               <Text fontSize={'12px'}>Cost with pre-existing</Text>
                                <Text textAlign={'center'}>$25.00</Text>
                            </Box>
                            <Box>
                               <Text fontSize={'12px'}>Cost without pre-existing</Text>
                                <Text textAlign={'center'}>$25.00</Text>
                            </Box>
                        </Flex>
                        </Box>
                    </Box>
                    <Box border={'1px solid rgb(238 238 238)'} _hover={{background:'#f6f6f6'}}>
                        <Text fontSize={'13px'} w={'90%'} m={'auto'}>Enhanced</Text>
                        <Box p={'15px'}>
                        <Flex justifyContent={'space-around'}>
                            <Box>
                               <Text fontSize={'12px'}>Cost with pre-existing</Text>
                                <Text textAlign={'center'}>$25.00</Text>
                            </Box>
                            <Box>
                               <Text fontSize={'12px'}>Cost without pre-existing</Text>
                                <Text textAlign={'center'}>$25.00</Text>
                            </Box>
                        </Flex>
                        </Box>
                    </Box>
                    <Box border={'1px solid rgb(238 238 238)'} _hover={{background:'#f6f6f6'}}>
                        <Text fontSize={'13px'} w={'90%'} m={'auto'}>Premium</Text>
                        <Box p={'15px'}>
                        <Flex justifyContent={'space-around'}>
                            <Box>
                               <Text fontSize={'12px'}>Cost with pre-existing</Text>
                                <Text textAlign={'center'}>$25.00</Text>
                            </Box>
                            <Box>
                               <Text fontSize={'12px'}>Cost without pre-existing</Text>
                                <Text textAlign={'center'}>$25.00</Text>
                            </Box>
                        </Flex>
                        </Box>
                    </Box>
                    <Box fontSize={'13px'} p={'15px'} border={'1px solid rgb(238 238 238)'}>
                        <ul>
                            <li> Min of CAD 100,000 of Sum Insured for min duration of 365 days.</li>
                            <li> No Cancellation Penalty.</li>
                            <li>Age from 15 days to 90 Years & 3 Product</li>
                            <li>Just 90 days stability condition up to age 74.</li>
                        </ul>
                    <Text textAlign={'right'} color={'blue'} p={'2px'}>More Details...</Text>
                    </Box>            
                </GridItem>
                <GridItem cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.24) 0px 3px 8px'}} background={'white'} color={'rgb(119 119 119)'} border={'1px solid rgb(238 238 238)'} fontSize={'15px'} fontWeight={'500'} fontFamily={'Manrope, sans-serif'}>
                <Flex h={'150px'} justifyContent={'center'} alignItems={'center'}>
                    <Image w={'50%'} h={'40%'} src='https://travelmedicare.com/public/users/images/logo.png' />
                </Flex>
                    <Box border={'1px solid rgb(238 238 238)'} p={'15px'}>
                        <Select size={'sm'}>
                            <option value="">Select</option>
                            <option value="25000">$25000</option>
                            <option value="50000">$50000</option>
                        </Select>
                    </Box>
                    <Box border={'1px solid rgb(238 238 238)'} _hover={{background:'#f6f6f6'}}>
                        <Text fontSize={'13px'} w={'90%'} m={'auto'}>Standard</Text>
                        <Box p={'15px'}>
                        <Flex justifyContent={'space-around'}>
                            <Box>
                               <Text fontSize={'12px'}>Cost with pre-existing</Text>
                                <Text textAlign={'center'}>$25.00</Text>
                            </Box>
                            <Box>
                               <Text fontSize={'12px'}>Cost without pre-existing</Text>
                                <Text textAlign={'center'}>$25.00</Text>
                            </Box>
                        </Flex>
                        </Box>
                    </Box>
                    <Box border={'1px solid rgb(238 238 238)'} _hover={{background:'#f6f6f6'}}>
                        <Text fontSize={'13px'} w={'90%'} m={'auto'}>Enhanced</Text>
                        <Box p={'15px'}>
                        <Flex justifyContent={'space-around'}>
                            <Box>
                               <Text fontSize={'12px'}>Cost with pre-existing</Text>
                                <Text textAlign={'center'}>$25.00</Text>
                            </Box>
                            <Box>
                               <Text fontSize={'12px'}>Cost without pre-existing</Text>
                                <Text textAlign={'center'}>$25.00</Text>
                            </Box>
                        </Flex>
                        </Box>
                    </Box>
                    <Box border={'1px solid rgb(238 238 238)'} _hover={{background:'#f6f6f6'}}>
                        <Text fontSize={'13px'} w={'90%'} m={'auto'}>Premium</Text>
                        <Box p={'15px'}>
                        <Flex justifyContent={'space-around'}>
                            <Box>
                               <Text fontSize={'12px'}>Cost with pre-existing</Text>
                                <Text textAlign={'center'}>$25.00</Text>
                            </Box>
                            <Box>
                               <Text fontSize={'12px'}>Cost without pre-existing</Text>
                                <Text textAlign={'center'}>$25.00</Text>
                            </Box>
                        </Flex>
                        </Box>
                    </Box>
                    <Box fontSize={'13px'} p={'15px'} border={'1px solid rgb(238 238 238)'}>
                        <ul>
                            <li> Min of CAD 100,000 of Sum Insured for min duration of 365 days.</li>
                            <li> No Cancellation Penalty.</li>
                            <li>Age from 15 days to 90 Years & 3 Product</li>
                            <li>Just 90 days stability condition up to age 74.</li>
                        </ul>
                    <Text textAlign={'right'} color={'blue'} p={'2px'}>More Details...</Text>
                    </Box>            
                </GridItem>
            </Grid>
            </Box>
            </Flex>
        </Box>
      
    </Box>
  )
}

export default CompareTravelProducts


