import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Image,
  Button,
  Text,
  useDisclosure,
  Input,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
// import SideBox from '../../../Utils/SideBox/SideBox';
import { FaRegEdit } from 'react-icons/fa';
import { AiOutlineLogout } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { DragHandleIcon } from '@chakra-ui/icons';
import Api from '../../dependencies/utils/Api';
import { CUSTOMER_EDIT_PROFILE, CUSTOMER_PROFILE } from '../../constants/ApplicationUrl';
import { storage } from '../../dependencies/store/storage';

const CustomerProfile = () => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [profileData, setProfileData] = useState([]);
  const [formData, setFormData] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    phone: '',
  });
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
  });

  const { id } = JSON.parse(localStorage.getItem('code_dev1'));

  useEffect(() => {
    Api.GET(`${CUSTOMER_PROFILE}`).then((res)=>{
    // dispatch(customerProfile()).then((res) => {
      const { status, data } = res;
      if (status === 200) {
        setProfileData(data);
        setFormData({
          id: id,
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          email: data.email || '',
          address: data.address || '',
          phone: data.phone || '',
        });
      }
    });
  }, [id, dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear previous error message on change
    setFormErrors({
      ...formErrors,
      [name]: '',
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
    };

    // Validation rules
    if (!formData.firstName.trim()) {
      newErrors.firstName = 'First Name is required';
      valid = false;
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = 'Last Name is required';
      valid = false;
    }
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
      valid = false;
    }
    if (!formData.phone.trim()) {
      newErrors.phone = 'Phone Number is required';
      valid = false;
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = 'Phone Number must be 10 digits';
      valid = false;
    }
    if (!formData.address.trim()) {
      newErrors.address = 'Address is required';
      valid = false;
    }

    setFormErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
        Api.POST(`${CUSTOMER_EDIT_PROFILE}`,formData).then((res)=>{
    //   dispatch(customerProfileEdit(formData)).then((res) => {
        const { status, data } = res;
        if (status === 200) {
          onClose();
          setProfileData(data);
          // Optionally, update profileData state or handle success message
        }
      });
    }
  };

  const handleLogout = () => {
    storage.clear();
    window.location.href = '/';
  };

  return (
    <div>
      {/* <SideBox /> */}
      <Box w={['90%','70%','70%']} m={'auto'} pt={'120px'} mb={'60px'}>
        <Flex justifyContent={'space-between'} gap={'15px'}>
          <Box>
            <Text fontSize={'20px'} color={'teal'}>
              Profile Information
            </Text>
          </Box>
          <Flex direction={['column','column','row']} gap={['10px','40px','40px']}>
            {/* <Link to={'/support'}>
          <Button
              _hover={{ color: 'white', backgroundColor: 'teal' }}
              size={'sm'}
              leftIcon={<DragHandleIcon />}
              color={'white'}
              backgroundColor={'teal'}
              >
              Support
            </Button>
              </Link> */}
            <Button
              _hover={{ color: 'white', backgroundColor: 'teal' }}
              leftIcon={<FaRegEdit />}
              size={'xs'}
              onClick={onOpen}
              color={'white'}
              backgroundColor={'teal'}
            >
              Edit
            </Button>
            <Button
              _hover={{ color: 'white', backgroundColor: 'teal' }}
              leftIcon={<AiOutlineLogout />}
              size={'xs'}
              onClick={handleLogout}
              color={'white'}
              backgroundColor={'teal'}
            >
              Logout
            </Button>
          </Flex>
        </Flex>
        <Flex justifyContent={'space-around'} borderTop={'2px solid teal'} mt={'10px'}>
          <Box display={['none','block','block']} w={'30%'} mt={'50px'}>
            <Image
              borderRadius={'50%'}
              border={'1px solid grey'}
              w={'200px'}
              src='https://www.whitechapelgallery.org/wp-content/uploads/2020/07/blank-head-profile-pic-for-a-man.jpg'
            />
          </Box>
          <Box textAlign={'left'} w={['95%','60%','60%']}>
            <Box pl={['10px','30px','30px']}>
              <Flex pt={'30px'} gap={'40px'}>
                <Box>
                  <Text>First Name</Text>
                  <Input size={'sm'}
                    backgroundColor={'#f1f1f1'}
                    color={'grey'}
                    value={profileData.firstName}
                    readOnly
                  />
                </Box>
                <Box>
                  <Text>Last Name</Text>
                  <Input size={'sm'}
                    backgroundColor={'#f1f1f1'}
                    color={'grey'}
                    value={profileData.lastName}
                    readOnly
                  />
                </Box>
              </Flex>
              <Flex pt={'20px'} gap={'40px'}>
                <Box>
                  <Text>Email</Text>
                  <Input size={'sm'}
                    backgroundColor={'#f1f1f1'}
                    color={'grey'}
                    value={profileData.email}
                    readOnly
                  />
                </Box>
                <Box>
                  <Text>Phone</Text>
                  <Input size={'sm'}
                    backgroundColor={'#f1f1f1'}
                    color={'grey'}
                    value={profileData.phone}
                    readOnly
                  />
                </Box>
              </Flex>
              <Box>
                <Text mt={'10px'}>Address</Text>
                <Textarea size={'sm'} backgroundColor={'#f1f1f1'} color={'grey'} value={profileData.address} readOnly />
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit} style={{padding:'10px',fontSize:'14px'}}>
              <Text>First Name</Text>
              <Input
                size={'xs'}
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                isInvalid={!!formErrors.firstName}
              />
              {formErrors.firstName && (
                <Text color="red" fontSize="sm">
                  {formErrors.firstName}
                </Text>
              )}
              <Text>Last Name</Text>
              <Input size={'xs'}
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                isInvalid={!!formErrors.lastName}
              />
              {formErrors.lastName && (
                <Text color="red" fontSize="sm">
                  {formErrors.lastName}
                </Text>
              )}
              <Text>Email</Text>
              <Input size={'xs'}
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                readOnly
                isInvalid={!!formErrors.email}
              />
              <Text>Phone Number</Text>
              <Input size={'xs'}
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                isInvalid={!!formErrors.phone}
              />
              {formErrors.phone && (
                <Text color="red" fontSize="sm">
                  {formErrors.phone}
                </Text>
              )}
              <Text>Address</Text>
              <Textarea size={'xs'}
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                isInvalid={!!formErrors.address}
              />
              {formErrors.address && (
                <Text color="red" fontSize="sm">
                  {formErrors.address}
                </Text>
              )}
              <Flex justifyContent={'center'} mt={'12px'}>
                <Button size={'sm'} mr={3} onClick={onClose}>
                  Close
                </Button>
                <Button size={'sm'} type="submit" colorScheme="teal">
                  Submit
                </Button>
              </Flex>
            </form>
          
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CustomerProfile;
