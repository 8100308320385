import {
  SET_LOGGED_IN,
  SET_USER,
  isLoading,
} from "../constants/actionConstants";
import { storage } from "./store/storage";

export const setLogin = (params) => async (dispatch) => {
  const { user, token } = storage.getUser();
  dispatch({
    type: SET_USER,
    payload: user,
  });
  dispatch({
    type: SET_LOGGED_IN,
    payload: true,
  });
};