import { toast } from "react-toastify";

import { ADVISOR_PROFILE, isLoading,ADVISOR_CALCULATEVTC, ADVISOR_GETQUOTES,ADVISOR_REFERNCE_DETAILS_TYPE, FORGET_PASSWORD_TYPE, ADVISOR_PROFILE_LIST, AVSIOR_PERSONAL_DETAILS_EDIT_TYPE } from '../../constants/actionConstants'

import { AdvisorLoginService, CustomerLoginService, CustomerSignupService} from "./service";

// export const advisorSignupList = (params) => async (dispatch) => {
//     dispatch(isLoading(true));
//     try{
//     let res = await AdvisorSignupService.advisorSignupList(params);
//     const {status, data} = res
//     if(status===200 || status===201){
//         dispatch({
//             type: ADVISOR_SIGNUP,
//             payload: data,
//             });
//             toast('Advisor Created Successfully.')
//         }else{
//             alert(data.message)
//             }
//             dispatch(isLoading(false));
//     return status
// }catch(err){
//     console.log(err,'err')
// }
// }

// export const advisorLoginList = (params) => async (dispatch) => {
//     dispatch(isLoading(true));
//     let loginList = await AdvisorLoginService.advisorLoginList(params);
//     const {status, message, data} = loginList
//     if(status === 200) {
//         dispatch({
//             type: ADVISOR_LOGIN,
//             payload: data
//         })
//         toast.success("Advisor Successfully Logged In")
//     }else {
//         // toast.error(message)
//         alert(message)
//     }
//     dispatch(isLoading(false));
//     return {status, data}
// }

export const advisorProfile = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdvisorLoginService.advisorProfile(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: ADVISOR_PROFILE_LIST,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
};

export const advisorRefrence = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let licenseDetails = await AdvisorLoginService.advisorRefrence(params);
    const {status, message, data} = licenseDetails
    if(status === 200) {
        dispatch({
            type: ADVISOR_REFERNCE_DETAILS_TYPE,
            payload: message,data
        })
        toast.success(" Successfully Saved Details")
    }else {
        toast(message)
    }
    dispatch(isLoading(false));
    return licenseDetails
}

export const advisorPersonalDetails = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try{
        let licenseDetails = await AdvisorLoginService.advisorPersonalDetails(params);
        const {status, message, data} = licenseDetails
        if(status === 200) {
            dispatch({
                type: AVSIOR_PERSONAL_DETAILS_EDIT_TYPE,
                payload: message,data
            })
            toast.success(" Successfully")
        }else {
            toast.success(message)
        }
      dispatch(isLoading(false));
        return licenseDetails
    }
    catch{
        // console.error(error);
        dispatch(isLoading(false));
        // throw error;
    }
   

}

export const advisorLoginForgetPassword = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let loginList = await AdvisorLoginService.advisorLoginForgetPassword(params);
    const {status, message, data} = loginList
    if(status === 200) {
        dispatch({
            type: FORGET_PASSWORD_TYPE,
            payload: data
        })
        toast.success("Reset Link Has Been Sent To Your Mail, Thanks")
    }else {
        // toast.error(message)
        alert(message)
    }
    dispatch(isLoading(false));
    return {status, data}
}