import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Grid, GridItem, Image, Input, Select, Tab, TabList, TabPanel, TabPanels, Tabs, Text, background, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor } from '../../Utils/UniversalColors'
import { useNavigate } from 'react-router-dom'
import '../../fonts/fonts.css'
// import { vtvCompareList } from './dependencies/action'
import { useDispatch } from 'react-redux'
import ImageItem from '../../Utils/UniversalColors/ImageItem'
import Api from '../../dependencies/utils/Api'
import { STUDENT_COMPARISON } from '../../constants/ApplicationUrl'
import { ComparedProductsModal, GetRateModal } from '../ComparedProductsModal'
import LoginModal from '../Supervisa/LoginModal'


const StudentCompare = () => {
    const navigate=useNavigate()
    const toast = useToast();
    const [age,setAge]=useState('')
    const [isComparedModalOpen,setIsComparedModalOpen]=useState(false)
    const [isRateModalOpen,setIsRateModalOpen]=useState(false)
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [buyNowData,setBuyNowData]=useState([])
    const [comparedData,setComparedData]=useState([])
    const [form, setForm] = useState({
      policyType: '',
      dob: '',
      startDate: '',
      endDate: '',
      sumInsured: '',
      deductibles: '0',
      preExisting: '',
    });
    const dispatch=useDispatch()
    const [responseData, setResponseData] = useState([]);
    const [duration,setDuration]=useState([])
    const [selectedItems, setSelectedItems] = useState([]);
    const [itemsData,setItemsData]=useState([])
      
      const handleAddToCompare = (name) => {
        setSelectedItems((prevItems) => {
          if (prevItems.some((item) => item.name === name)) {
            toast({
              title: 'Item already added to compare.',
              status: 'info',
              duration: 2000,
              isClosable: true,
            });
            return prevItems;
          }
          return [...prevItems, { name }];
        });
      };
    
      const handleCompare = () => {
        if (selectedItems.length > 1) {
          const itemsWithDetails = selectedItems.map((selectedItem) => {
            const itemDetails = itemsData.find((item) => item.name === selectedItem.name);
            return {
              ...selectedItem,
              ...itemDetails,
            };
          });
          const item={
            datas:itemsWithDetails,
            row:form
          }
          setComparedData(item)
          setIsComparedModalOpen(true)
          // navigate('/compared-products', { state: { items: item } });
        } else {
          toast({
            title: 'Select at least two items to compare.',
            status: 'warning',
            duration: 2000,
            isClosable: true,
          });
        }
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "endDate") {
          const startDate = new Date(form.startDate);
          const endDate = new Date(value);
          const diffInMilliseconds = endDate - startDate;
          const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
          if (endDate < startDate) {
              alert("Last date cannot be earlier than the first date");
              setForm({ ...form, endDate: "" });
              setDuration("");
              return
          } else if (diffInMilliseconds > oneYearInMilliseconds) {
              alert("Duration cannot exceed 1 year");
              setForm({ ...form, endDate: "" });
              setDuration("");
              return
          } else {
              const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24)) + 1;
              console.log(durationInDays,'drrr')
              setDuration(durationInDays);
          }
        }
        setForm((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };

      useEffect(() => {
        const validateDateFields = () => {
          const { firstDate, endDate, dob } = form;
          if (dob) {
            const selectedDate = new Date(dob);
            const today = new Date();
            const ageInMilliseconds = today - selectedDate;
            const ageInDays = ageInMilliseconds / (1000 * 60 * 60 * 24);
            const ageInYears = ageInDays / 365.25;
            setAge(Math.floor(ageInYears))
            const minDate = new Date();
            minDate.setDate(today.getDate() - 15);
            if (selectedDate >= minDate) {
              alert("Users must be at least 15 days older than today.");
              setForm((prevState) => ({
                ...prevState,
                dob: '',
              }));
              return;
            }
          }
          if (endDate) {
            const startDate = new Date(firstDate);
            const endDateValue = new Date(endDate);
            const diffInMilliseconds = endDateValue - startDate;
            const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;

            if (endDateValue < startDate) {
              alert("Last date cannot be earlier than the first date");
              setForm((prevState) => ({
                ...prevState,
                endDate: '',
              }));
              return;
            } else if (diffInMilliseconds > oneYearInMilliseconds) {
              alert("Duration cannot exceed 1 year");
              setForm((prevState) => ({
                ...prevState,
                endDate: '',
              }));
              return;
            } else {
              const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24) + 1);
              setDuration(durationInDays);
            }
          }
        };

        validateDateFields();
      }, [form]);

      const handleSubmit = async () => {
        const today = new Date();
        const dob = new Date(form.dob);
        const age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        const dayDiff = today.getDate() - dob.getDate();

        if (age > 65 || (age === 65 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)))) {
            alert('Date of birth cannot be more than 65 years ago');
            return;
        }
        if(!form.startDate){
          alert('Please enter start date')
          return;
        }
        if(!form.endDate){
          alert("Please enter end date")
          return;
        }
        if(!form.dob){
          alert("Please enter dob")
          return;
        }
        const payload = {
          policyType: 'Standard',
          dob: [form.dob],
          startDate: form.startDate,
          endDate: form.endDate,
        };
        console.log(payload,'payload')
            Api.POST(`${STUDENT_COMPARISON}`,payload).then((result)=>{
                setResponseData(result.data);
                setItemsData(result.data)
            })
      }

      const handleRateModal=(data)=>{
        setBuyNowData(data)
        setIsRateModalOpen(true)
      }

      const handleRateModalClose=()=>{
        setIsRateModalOpen(false)
      }

      const handleBuyNow = () => {
        const storedData = localStorage.getItem("code_dev1");
        if (!storedData) {
          setIsLoginModalOpen(true);
          return;
        }
        const item = {
          datas: buyNowData,
          row:form
        };
        navigate('/single-quote', { state: { item } });
      };

      const handleBuyNowAfterLogin=()=>{
        const item = {
          datas: buyNowData,
          row:form
        };
        navigate('/single-quote', { state: { item } });
      }

      const handleComparedModalClose=()=>{
        setIsComparedModalOpen(close)
      }

  // const base64ToUrl = base64String => `data:image/jpeg;base64,${base64String}`;
  
  
  // const convertImagesToUrls = data => {
  //     return data.map(item => ({
  //       ...item,
  //       image: base64ToUrl(item.image)
  //     }));
  //   };
  
  
  
    const getMaxDate = () => {
        const today = new Date();
        today.setDate(today.getDate() + 180); // Add 180 days
        return today.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
      };


return (
    <>

<Box pt={['78px','98px','98px']} background={'#efefef'} position={['absolute','fixed','fixed']} h={'100vh'} w={'100%'}>
    <Flex w={'98%'} m={'auto'} gap={'20px'} h={['auto','100vh','100vh']} direction={['column','column','row']}>    
        <Box borderRadius={'5px'}
            boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' 
            background={'white'} 
            w={['98%','20%','20%']} 
            p={'20px'} 
            fontFamily={'Poppins, sans-serif'} fontSize={'12px'} >
            <Text fontFamily={'Montserrat,sans-serif'} textAlign={'center'} fontSize='22px' fontWeight='600' p={'10px'}>Student Insurance</Text>
            <hr />
            <Box>
                {/* <Text pt={'10px'}>Policy Type*</Text> */}
                     <Box mt={'20px'}>
                    <Text pb={'10px'}>Date Of Birth</Text>
                            <Box w={'99%'} m={'auto'}>
                                <Input size={'xs'} type='date'  name="dob" value={form.dob} onChange={handleChange}/>
                            </Box>
                        </Box>
                        <Box mt={'10px'}>
                            <Text pb={'2px'}>Start Date</Text>
                            <Box w={'99%'} m={'auto'}>
                                <Input max={getMaxDate()} min={new Date().toISOString().split("T")[0]} size={'xs'} type='date'  name="startDate" value={form.startDate} onChange={handleChange}/>
                            </Box>
                        </Box>
                        <Box mt={'10px'}>
                            <Text pb={'2px'}>End Date</Text>
                            <Box w={'99%'} m={'auto'}>
                                <Input size={'xs'} type='date' name="endDate" value={form.endDate} onChange={handleChange}/>
                            </Box>
                        </Box>
                <Button size={'xs'} mt={'20px'} background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} onClick={handleSubmit}>Submit</Button>
            </Box>            
        </Box>
        
        

    <Box borderRadius={'5px'} w={['98%','80%','80%']}>
        <Box background={'white'} mb={'120px'} h={'90vh'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' overflowY="auto" p="10px">
            <Box>
                <Text fontSize={'13px'} fontFamily={'Poppins, sans-serif'}>Super visa insurance for (age {age} yrs) for {duration} days, {form.preExisting===true?'excluded':'included'} coverage for pre-existing medical conditions</Text>
            </Box>
            <Box mt={'35px'} mb={'30px'}>
            {responseData.map(item => (
                <Accordion defaultIndex={[1]} allowMultiple>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'>
                                <Flex direction={['column','column','row']} justifyContent={'space-around'} alignItems={'center'}>
                                <Box w={['45%','15%','10%']}>
                                      <ImageItem imageBytes={item.image} />
                                    </Box>
                                     <Flex w={['95%','20%','20%']} direction={'column'} fontSize={'18px'} fontWeight={'600'}>
                                     {item.policyAmount} $
                                      </Flex>
                                     <Box w={['95%','25%','25%']}>
                                        <Text fontSize={'12px'}>
                                        $5M coverage / $250 deductible <br />
                                        Covid-19 Coverage Included.</Text>
                                     </Box>
                                     <Box>
                                     <Flex gap={'10px'} p={'15px'} fontSize={'16px'} fontWeight={'600'} alignItems={'center'}>
                                     <Button size={'xs'} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} background={buttonBackgroundColor} color={buttonColor} onClick={() => handleRateModal(item)}>Save</Button>
                                    <Button size={'xs'} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} background={buttonBackgroundColor} color={buttonColor} onClick={() => handleRateModal(item)}> Buy</Button>
                                     </Flex>
                                     <Flex justifyContent={'center'} gap={'5px'} pb={'17px'}>
                                    <input type="radio" onClick={() => handleAddToCompare(item.name)} />
                                    <Text>Add to compare</Text>
                                </Flex>
                                     </Box>
                                  </Flex>
                                </Box>
                                <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Box background={'#F4F4F4'} w={'95%'} p={'20px'} m={'auto'}>
                                <Flex direction={['column','column','row']} justifyContent={'space-around'}>
                                    <Box w={['95%','30%','30%']}>
                                        <Text fontWeight={'600'}>Eligibility</Text>
                                        <Text color={'#666'} fontSize={'12px'} fontFamily={'"Aspira W01",sans-serif'}>{item.eligibility}</Text>
                                    </Box>
                                    <Box w={['95%','30%','30%']}>
                                    <Text fontWeight={'600'}>Coverages</Text>
                                    <Box color={'#666'} fontSize={'12px'} fontFamily={'"Aspira W01",sans-serif'}>
                                        {item.coverage}
                                        </Box>
                                    </Box>
                                    <Box w={['95%','30%','30%']}>
                                    <Text fontWeight={'600'}>Exclusions</Text>
                                    <Text color={'#666'} fontSize={'12px'} fontFamily={'"Aspira W01",sans-serif'}>{item.exclusion}</Text>
                                    <Text mt={'10px'} color={buttonBackgroundColor} fontSize={'14px'} fontFamily={'"Aspira W01",sans-serif'}>Policy wording</Text>
                                    <Text color={buttonBackgroundColor} fontSize={'14px'} fontFamily={'"Aspira W01",sans-serif'}>View policy pdf</Text>
                                    </Box>
                                </Flex>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
                 ))}
            </Box>
        </Box>
        </Box>
    </Flex>
</Box>
    

    {selectedItems.length > 0 && (
        <Box fontFamily={'Poppins, sans-serif'} position="fixed" bottom="10px" right="10px" background="white" border="1px solid grey" p="10px" borderRadius="5px" boxShadow="md" fontSize={'13px'}>
          <Text fontWeight="bold">Selected for Comparison:</Text>
          <Box mt="5px">
            {selectedItems.map(item => (
              <Text key={item.id}>{item.name}</Text>
            ))}
          </Box>
          <Button size={'xs'} mt="10px" background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} onClick={handleCompare}>
            Compare
          </Button>
        </Box>
      )}
      <ComparedProductsModal isOpen={isComparedModalOpen} onClose={handleComparedModalClose} items={comparedData}/>
       <GetRateModal isOpen={isRateModalOpen} onClose={handleRateModalClose} onSubmit={handleBuyNow}/>
       <LoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} onSubmit={handleBuyNowAfterLogin} />
    </>
  )
}

export default StudentCompare
