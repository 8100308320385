import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor } from '../../../Utils/UniversalColors'
import supervisa from '../../../assets/img/supervisa.png'
import visitor from '../../../assets/img/visitor.png'
import student from '../../../assets/img/student.png'



const UserDashboard = () => {




return (
    <Box background={'white'}>
    <Box pl={'52px'} backgroundSize={'cover'} textAlign={'center'} backgroundRepeat={'no-repeat'} backgroundPosition={'right'}  pt={['20px','50px','140px']} pb={'50px'} background={'#fff4f4'}>
        <Text fontFamily='Manrope, sans-serif' fontWeight={'700'} color={'rgb(75 89 102)'} fontSize={['28px','32px','32px']} >Find the Best Travel Insurance in Canada</Text> 
        <Text fontSize={['14px','16px','16px']} fontFamily={'poppins'} pt={['10px','20px','20px']}>Compare the cheapest travel insurance quotes to get the best coverage available.</Text>
        </Box>

        <Box w={'80%'} m={'auto'} mt={'30px'} pb={'120px'}>
        <Flex direction={['column','column','row']} justifyContent={['center','center','space-around']}>
            <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(255 234 233)',backgroundImage:'none'}} backgroundColor={'rgb(255 234 233)'} p={'10px'}>
            <Link to={'/super-visa-compare'}>
            <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
            <Flex justifyContent={'center'}>
            <img width="68" height="48" src={supervisa} alt="user"/>              
            </Flex>
            <Text mt={'10px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Super Visa <br />Insurance</Text>
                <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
            </Box>
            </Link>
            </Box>
            <Link to={'/visitor-compare'}>
            <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(236 240 255)',backgroundImage:'none'}} backgroundColor={'rgb(236 240 255)'} p={'10px'}>
            <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
            <Flex justifyContent={'center'}>
            <img width="48" height="48" src={visitor} alt="user"/>              
            </Flex>
            <Text mt={'10px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Visitor  <br /> Insurance</Text>
                <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
            </Box>
            </Box>
            </Link>

            <Link to={'/student-compare'}>
            <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(253 225 245)',backgroundImage:'none'}} backgroundColor={'rgb(253 225 245)'} p={'10px'}>
            <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
            <Flex justifyContent={'center'}>
            <img width="48" height="48" src={student} alt="user"/>              
            </Flex>
            <Text mt={'10px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Student Travel <br />Inurance</Text>
                <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
            </Box>
            </Box>
            </Link>

            <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(249 249 217)',backgroundImage:'none'}} backgroundColor={'rgb(249 249 217)'} p={'10px'}>
            <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
            <Flex justifyContent={'center'}>
            <img width="48" height="48" src="https://img.icons8.com/parakeet/48/user.png" alt="user"/>              
            </Flex>
            <Text mt={'10px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Travel <br />Insurance</Text>
                <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
            </Box>
            </Box>

        </Flex>
        </Box>

       











{/* 
      <Box background={'#f0f0f0'} pt={'120px'} w={'80%'} m={'auto'} pb={'70px'}>
            <Flex gap={'20px'} direction={['column','column','row']} justifyContent={'space-around'}>
                <Box _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} borderRadius={'5px'} p={'10px'} boxShadow='rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' background={'white'} w={['96%','30%','30%']}>
                    <Flex justifyContent={'center'} pt={'15px'}>
                        <Image w={'60px'} src="https://img.freepik.com/free-vector/international-passport-3d-vector-illustration-documents-traveling-abroad-cartoon-style-isolated-white-background-summer-tourism-vacation-travel-concept_778687-1721.jpg?t=st=1711104162~exp=1711107762~hmac=e01a7b17d795fc4ca924a7da010ff26390362ff1613a0a8539ff14c5cc53247c&w=740"/>
                    </Flex>
                    <Text textAlign={'center'} pt={'10px'}>
                        Super Visa Insurance
                    </Text>
                    <Box fontSize={'12px'} p={'20px'}>
                    <ul>
                        <li> Min of CAD 100,000 of Sum Insured for min duration of 365 days.</li>
                        <li> No Cancellation Penalty.</li>
                        <li>Age from 15 days to 90 Years & 3 Product Choices Standard, Enhanced and Premium.</li>
                        <li>Just 90 days stability condition up to age 74.</li>
                        <li>Monthly Payments Available.</li>
                        <li> Covers you for any unforeseen Emergency Medical, Physician & Hospitalization expenses, Diagnostics, Prescriptions and Dental etc.</li>
                    </ul>
                    </Box>
                    <Flex justifyContent={'center'} pb={'20px'}>
                    <Link to='/super-visa-compare'>
                    <Button size={'sm'} _hover={{color:'white'}} backgroundColor={buttonBackgroundColor} color={buttonColor}>Buy Now</Button>
                    </Link>
                    </Flex>
                </Box>
                <Box _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} borderRadius={'5px'} p={'10px'} boxShadow='rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' background={'white'} w={['96%','30%','30%']}>
                    <Flex justifyContent={'center'} pt={'15px'}>
                        <Image w={'50px'} src="https://img.freepik.com/free-vector/meeting-point-location-pin-design_25030-78591.jpg?t=st=1711019429~exp=1711023029~hmac=a46be01bea14599c1449e4f18a995999f8be3ca97e190f3f135fb53e11f8379e&w=740"/>
                    </Flex>
                    <Text textAlign={'center'} pt={'10px'}>
                        Visitor Insurance
                    </Text>
                    <Box fontSize={'12px'} p={'20px'}>
                    <ul>
                        <li>Policy can be bought for duration of Trip up to max continuous period of 2 years.</li>
                        <li>  No Cancellation Penalty.</li>
                        <li>Age from 15 days to 90 Years & 3 Product Choices Standard, Enhanced and Premium.</li>
                        <li>Just 90 days stability condition up to age 74.</li>
                        <li>Monthly Payments Available.</li>
                        <li> Covers you for any unforeseen Emergency Medical, Physician & Hospitalization expenses, Diagnostics, Prescriptions and Dental etc.</li>
                    </ul>
                    </Box>
                    <Flex justifyContent={'center'} pb={'20px'}>
                    <Link to='/visitor-compare'>
                    <Button size={'sm'} _hover={{color:'white'}} backgroundColor={buttonBackgroundColor} color={buttonColor}>Buy Now</Button>
                    </Link>
                    </Flex>
                </Box>
                <Box _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} borderRadius={'5px'} p={'10px'} boxShadow='rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' background={'white'} w={['96%','30%','30%']}>
                    <Flex justifyContent={'center'} pt={'15px'}>
                        <Image w={'60px'} src="https://img.freepik.com/free-vector/academic-excellence-illustration_24877-52355.jpg?t=st=1711019454~exp=1711023054~hmac=6c7987360f9c7154270147fc984e9bc4655c5f3312fdda7ccd25235103e6cc2a&w=740"/>
                    </Flex>
                    <Text textAlign={'center'} pt={'10px'}>
                        Student Insurance
                    </Text>
                    <Box fontSize={'12px'} p={'20px'}>
                    <ul>
                        <li> Just Pay $495 per year for $2 million Coverage for Standard plan.</li>
                        <li> Age from 15 days to 65 Years & 3 Product Choices Standard, Enhanced and Premium.</li>
                        <li> Parents and Legal Guardians of the Insured Student Can be added to the Policy.</li>
                        <li>Covers you for Emergency Medical & Medical Expenses as per Policy Conditions.</li>
                        <li> Extended Benefits Include Physical Examination, Maternity Coverage, Accidental Death.</li>
                    </ul>
                    </Box>
                    <Flex justifyContent={'center'} pb={'20px'}>
                    <Link to='/student-compare'>
                    <Button size={'sm'} _hover={{color:'white'}} backgroundColor={buttonBackgroundColor} color={buttonColor}>Buy Now</Button>
                    </Link>
                    </Flex>
                </Box>
            </Flex>
        </Box> */}




       






    </Box>
  )
}

export default UserDashboard
