import { Box,Flex,Text,Input,Button,FormControl,FormLabel, Image, useToast} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { advisorLoginForgetPassword } from '../Login/dependencies/action';

const CustomerForgetPassword = () => {


  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ email: "" });
  const toast = useToast();
  const dispatch = useDispatch();

    const handleChange = (e) => {
      setEmail(e.target.value);
      setErrors({ email: "" });
    };

    const handleForgotPassword = () => {
      const params = { email: email };
      dispatch(advisorLoginForgetPassword(params)).then((res) => {
        const { status, message } = res;
        if (status === 200 || status === 201) {
          
        } else {
          toast({
            title: "Error",
            description: message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      });
    };

return (
    <Box m="auto">
    <Flex direction={["column", "column", "row", "row"]} pt={"60px"}>
      <Box
        w={["95%", "95%", "50%", "50%"]}
        p={"70px"}
        h={"100vh"}
        display={["none", "none", "block"]}
        color={"white"}
        background={"#272939"}
        backgroundImage={
          "https://travelmedicare.com/public/users/images/bg-02.jpg"
        }
        backgroundSize={"cover"}
        backgroundPosition={"center"}
      >
        {/* <Image src='https://experiortravel.com/public/users/images/bg-02.jpg'/> */}
      </Box>
      <Box
        p={5}
        w={["99%", "95%", "50%", "50%"]}
        pt={"40px"}
        textAlign={"center"}
      >
        <Flex justifyContent={"center"}>
          <Image
            w={"130px"}
            src={"https://travelmedicare.com/public/users/images/logo.png"}
          />
        </Flex>
        <Text
          fontFamily={"poppins"}
          mt={["30px", "1px", "10px", "30px"]}
          pb={["20px", "20px", "80px", "20px"]}
        >
         Customer Forget Password
        </Text>
        <FormControl isRequired w={["70%"]} m={"auto"}>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            placeholder="Enter email"
            name="username"
            onChange={handleChange}
          />
          <Text color="red.500">{errors.username}</Text>
          <Button
            mt={4}
            width="100%"
            onClick={handleForgotPassword}
            color={"white"}
            backgroundColor={"teal"}
            _hover={{color:'white'}}
          >
            Reset Password
          </Button>
        </FormControl>
        <Text mt={"35px"}>Don't have an account?</Text>
        <Flex justifyContent={"center"} mt={"15px"}>
          <Link to="/advisorRegister">
            <Button color={"white"}
            backgroundColor={"teal"}
            _hover={{color:'white'}}>
              Register Here
            </Button>
          </Link>
        </Flex>
      </Box>
    </Flex>
  </Box>
  )
}

export default CustomerForgetPassword
