import { ChakraProvider } from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import Loader from '../src/Components/Common/Loader/loadingAnimation'
import AppRoutes from "./routes";

function App() {
  return (
    <ChakraProvider>
      <Loader />

      <ToastContainer autoClose={5000} />

      <AppRoutes />

    </ChakraProvider>
  );
}

export default App;
