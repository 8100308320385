import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Image, Text, Input,Select } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor } from '../../Utils/UniversalColors';
import { validateEmail, validateMobileNumber } from '../../Utils/UniversalColors/Validation';
import Api from '../../dependencies/utils/Api';
import { SAVE_QUOTE } from '../../constants/ApplicationUrl';
import axios from 'axios';
import ImageItem from '../../Utils/UniversalColors/ImageItem';

const ViewSingleQuote = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { item } = location.state || { items: [] };
    console.log(item,'item images')
    const { token, id } = JSON.parse(localStorage.getItem("code_dev1")) || {};
    console.log("token",token)
    const [waitingPeriod, setWaitingPeriod] = useState("0");
    const [duration,setDuration]=useState('')
    const [policyLimit,setPolicyLimit]=useState(item.datas.sumInsured)
    const [dedecutibles,setDeductibles]=useState('0')
    const [policyTypeSuperVisa,setPolicyTypeSuperVisa]=useState('1')
    const [PlanType,setPlanType]=useState('Standard')
    // const [amount,setAmount]=useState()
    const [checked, setChecked] = useState(item.datas.familyCoverage);
    const [amount,setAmount]=useState(item.datas.policyAmount)
    const [formData, setFormData] = useState({
        firstDate: item.row.startDate,
        lastDate:item.row.endDate,
        departureDate: "",
        departureMaxDate: "",
      });
    const [policyHolder, setPolicyHolder] = useState({
    policyOwnerName: '',
    policyOwnerDob: '',
    policyOwnerEmail: '',
    policyOwnerAddress: '',
    policyOwnerPostalCode: '',
    policyOwnerCity: '',
    policyOwnerPhone: ''
    });
    const initialTraveler = {
        name: "",
        gender: "",
        dob: "",
        relation: "",
        benificiaryDob: "",
        benificiaryName: "",
      };
  
    const [addTravelers, setAddTravelers] = useState([initialTraveler]);

    useEffect(() => {
        if (item.datas.dob && Array.isArray(item.datas.dob) && item.datas.dob.length > 0) {
          const travelersFromDobs = item.datas.dob.map(dob => ({
            ...initialTraveler,
            dob: dob
          }));
          setAddTravelers(travelersFromDobs);
        }
        if (item && item.datas && Array.isArray(item.datas.premiumAmount) && item.datas.premiumAmount.length > 0) {
          setAmount(item.datas.premiumAmount[0]);
      }
      }, [item.datas.dob,item]);
    const handleChangeTraveler = (index, e) => {
    const { name, value } = e.target;
    if (name === "dob" || name === "benificiaryDob") {
        const selectedDate = new Date(value);
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - 15);
        if (selectedDate >= minDate) {
            alert("Users must be at least 15 days older than today.");
            return;
        }
    }
    setAddTravelers((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = { ...updatedState[index], [name]: value };
        return updatedState;
    });
    };
    const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (formData.firstDate.length === 0) {
    }
    if (formData.lastDate.length === 0) {
    }
    if (formData.departureDate.length === 0) {
    }
    if (name === "firstDate") {
        const startDate = new Date(value);
        const departureMaxDate = new Date(startDate);
        departureMaxDate.setDate(departureMaxDate.getDate());
        const formattedMaxDate = departureMaxDate.toISOString().split("T")[0];
        setFormData((prevData) => ({
        ...prevData,
        departureMaxDate: formattedMaxDate,
        }));        
    }
    };
    const handlePolicyOwnerChange = (e) => {
        const { name, value } = e.target;
        setPolicyHolder({ ...policyHolder, [name]: value });
        const selectedPolicyHolder = addTravelers.find((traveler) => traveler.name === value);
        if (selectedPolicyHolder) {
          setPolicyHolder({ ...policyHolder, policyOwnerDob: selectedPolicyHolder.dob, policyOwnerName: selectedPolicyHolder.name });
        }
      };
   
    const handleGetQuote = () =>{
    const isAnyBeneficiaryDetailEmpty = addTravelers.some(
        (traveler) =>
            traveler.name === "" ||
        traveler.gender === "" ||
        traveler.benificiaryName === "" ||
        traveler.benificiaryDob === "" ||
        traveler.relation === ""
    );
    if (isAnyBeneficiaryDetailEmpty) {
        alert("Please fill in all mandatory details.")
        return;
    }
    if(!formData.departureDate){
        alert("Please enter date of departure")
        return;
    }
    if(policyHolder.policyOwnerName==="" || policyHolder.policyOwnerAddress==="" || policyHolder.policyOwnerCity===""|| policyHolder.policyOwnerDob==="" || policyHolder.policyOwnerEmail==""){
        alert("Please enter mandatory fields.")
        return;
    }
    if (policyHolder.policyOwnerEmail === 0 || !validateEmail(policyHolder.policyOwnerEmail)){
        alert('Please enter a valid email');
        return;
    }
    if(policyHolder.policyOwnerPhone === 0 || !validateMobileNumber(policyHolder.policyOwnerPhone)){
        alert("Please enter a valid mobile.")
        return;
    }  
    const startDate = new Date(formData.firstDate);
    const endDate = new Date(formData.lastDate);
    const diffInMilliseconds = endDate - startDate;
    const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24)+1);
    setDuration(durationInDays);
    const updatedTravelers = addTravelers.map((traveler) => ({
        nameOfInsured: traveler.name,
        gender: traveler.gender,
        dateOfBirth: traveler.dob,
        beneficiaryRelationToInsured: traveler.relation,
        beneficiaryName: traveler.benificiaryName,
        beneficiaryDOB: traveler.benificiaryDob,
        costWithPreExisting:traveler.costWithPreExisting,
        costWithoutPreExisting:traveler.costWithoutPreExisting,
        selectedKey:traveler.selectedKey
    }));  
    const dataToSend = {
        email: policyHolder.policyOwnerEmail,
        firstDateOfCover: formData.firstDate,
        lastDateOfCover:formData.lastDate,
        policyEndDate: formData.lastDate,
        duration:parseInt(duration),
        country:1,
        province:'Ontario',
        departureFromCountryOfOrigin:formData.departureDate,
        waitingPeriod:waitingPeriod,
        policyLimit:policyLimit,
        deductible:dedecutibles,
        paymentFrequency:policyTypeSuperVisa,
        policyOwner:policyHolder.policyOwnerName,
        policyOwnerDateOfBirth:policyHolder.policyOwnerDob,
        policyOwnerEmail:policyHolder.policyOwnerEmail,
        policyOwnerAddress:policyHolder.policyOwnerAddress,
        policyOwnerPostalCode:policyHolder.policyOwnerPostalCode,
        policyOwnerCity:policyHolder.policyOwnerCity,
        policyOwnerPhone:policyHolder.policyOwnerPhone,
        planType:PlanType,
        insuredTravellerDetailsRequest: updatedTravelers,
        policyType:'VTC',
        amount: amount,
        familyCoverage:checked,
        companyName:item.datas.name
    };
        console.log(dataToSend,'dataTo Send')
        // axios.post(`${SAVE_QUOTE}`,dataToSend).then((res)=>{
          axios.post(`${SAVE_QUOTE}`, dataToSend, {
            headers: {
              'Authorization': `Bearer ${token}` // Add your token here
            }
          })
          .then((res) => {
            console.log(res.data);
            alert('Quote generated')
            navigate('/all-quotes')
          })
          .catch((error) => {
            // Handle the error here
            console.error(error);
          });
        // })
    }

    useEffect(() => {
      if (!formData.departureDate) {
        setWaitingPeriod("NA");
      } else {
        const today = new Date(formData.firstDate);
        const thirtyDaysAgo = new Date(today);
        thirtyDaysAgo.setDate(today.getDate() - 30);
        const selectedDate = new Date(formData.departureDate);
        const firstDate = new Date(formData.firstDate);
        const isSameDate = selectedDate.toDateString() === firstDate.toDateString();      
        const isWithinLastThirtyDays = selectedDate >= thirtyDaysAgo && selectedDate < today;
        if (isSameDate) {
          setWaitingPeriod("NA");
        } else {
          setWaitingPeriod(isWithinLastThirtyDays ? "48 Hour" : "8 days");
        }
      }
    }, [formData.departureDate, formData.firstDate]);


return (
    <Box background={'#efefef'} >
    <Box w={'80%'} m={'auto'}>
      <Flex pt={'100px'} pb={'120px'} justifyContent={'space-around'}>
        <Box w={'30%'} background={'white'} pt={'30px'} pb={'20px'} boxShadow="0 8px 16px rgba(0, 0, 0, 0.2)">
        <Box w={'90%'} textAlign={'center'} fontFamily={'Montserrat, Sans-serif'}>
                <Flex justifyContent={'center'} textAlign={'center'} w='100px' m={'auto'} h={'45px'} alignItems={'center'} pb={'7px'}>
                <ImageItem imageBytes={item.datas.image} />
                </Flex>
                <hr />
                <Flex fontWeight={'700'} justifyContent={'center'} alignItems={'center'} direction={'column'} pt={'15px'} pb={'15px'} >
                   {item.datas.policyAmount} $
                </Flex>
                <hr />
                {/* <Flex justifyContent={'center'} p={'10px'}>
                    <Button size={'xs'} background={buttonBackgroundColor} color={buttonColor}>Buy Now</Button>
                </Flex> */}
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Name</Text>
                    <Text fontSize={'14px'} pb={'15px'}>{item.datas.name}</Text>
                    <hr />
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Insurnace Policy</Text>
                    <Text color={'blue'} fontSize={'14px'} pb={'15px'}>View Sample Policy</Text>
                    <hr />
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Coverage Amount</Text>
                    <Text fontSize={'14px'} pb={'15px'}>$ {item.datas.sumInsured}</Text>
                    <hr />
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Deductibles</Text>
                    <Text fontSize={'14px'} pb={'15px'}>{item.datas.deductible}</Text>
                    <hr />
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Emergency Hospital Services</Text>
                    <Text fontSize={'14px'} pb={'15px'}>Up to Maximum Policy Limit</Text>
                    <hr />
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Emergency Medical Services/Treatment</Text>
                    <Text fontSize={'14px'} pb={'15px'}>Up to Maximum Policy Limit</Text>
                    {/* <hr /> */}
                    <Box mt={'15px'}>
                    <Accordion allowMultiple w={'80%'} m={'auto'}>
                        <AccordionItem>
                            <h2>
                            <AccordionButton>
                                <Box fontSize={'12px'} as='span' flex='1' textAlign='left'>
                                Exclusions
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} background={'#efefef'}>
                                <Text fontSize={'12px'}>{item.datas.exclusion}</Text>
                            
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                            <AccordionButton>
                                <Box fontSize={'12px'} as='span' flex='1' textAlign='left'>
                                Coverages
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} background={'#efefef'}>
                                <Text fontSize={'12px'}>{item.datas.coverage}</Text>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                            <AccordionButton>
                                <Box fontSize={'12px'} as='span' flex='1' textAlign='left'>
                                Eligiblity
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel background={'#efefef'} pb={4}>
                                <Text fontSize={'12px'}>{item.datas.eligibility}</Text>
                            
                            </AccordionPanel>
                        </AccordionItem>
                        </Accordion>
                    </Box> 
        </Box> 
        </Box>
        <Box w={'65%'} background={'white'} boxShadow="0 8px 16px rgba(0, 0, 0, 0.2)">
            <div style={{ width: "100%",maxWidth: "800px",margin: "auto",padding: "18px"}}>
              <p style={{
                  fontFamily: "poppins",
                  fontSize: "16px",
                  fontWeight: 400,
                  marginBottom: "20px",
                  borderBottom: "2px solid #088183",
                }}
              >
                Traveller Details
              </p>
              {addTravelers.map((item, index) => {
                return (
                  <Box mb={'7px'} backgroundColor={'#f3f3f3'} border={'1px solid #80808026'} fontSize={'13px'} fontFamily={'Open Sans, sans-serif'}>
                  <Flex key={index} direction={['column','column','row']} gap={['10px','20px','20px']} p={'18px'} pb={'10px'} m={'auto'}>
                    <Box w={['90%','90%','30%']}>
                      <span style={{ marginBottom: "8px" }}>Name Of Insured</span>
                      <Input background={'white'} name="name" value={item.name} placeholder='Insured Name'
                        onChange={(e) => handleChangeTraveler(index, e)} size='xs' border={'1px solid #d7d7d7'} min={new Date().toISOString().split('T')[0]} />
                    </Box>
                    <Box w={['90%','90%','30%']}>
                      <span style={{ marginBottom: "8px" }}>Gender</span>
                      <Select background={'white'} size={'xs'} border={'1px solid #d7d7d7'}
                         value={item.gender}
                         name="gender"
                        onChange={(e) => handleChangeTraveler(index, e)}>
                          <option value="">Select</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </Select>
                    </Box>
                    <Box w={['90%','90%','30%']}>
                      <span style={{ marginBottom: "8px" }}>Date of Birth</span>
                      <Input readOnly background={'white'} name="dob"
                        onChange={(e) => handleChangeTraveler(index, e)}
                        value={item.dob} size='xs' type='date' border={'1px solid #d7d7d7'} max={new Date().toISOString().split('T')[0]} />
                    </Box>
                    </Flex>

                  <Flex key={index} direction={['column','column','row']} gap={['10px','20px','20px']} p={'18px'} pt={'5px'} pb={'20px'}>
                      <Box w={['90%','90%','30%']}>
                        <span style={{ marginBottom: "8px" }}>
                          Beneficiary Name
                        </span>
                        <Input background={'white'} name="benificiaryName" placeholder="Name"
                          onChange={(e) => handleChangeTraveler(index, e)}
                          value={item.benificiaryName} size='xs' border={'1px solid #d7d7d7'} />
                      </Box>
                      <Box w={['90%','90%','30%']}>
                        <span style={{ marginBottom: "8px" }}>Relation</span>
                        <Input background={'white'} name="relation"
                          onChange={(e) => handleChangeTraveler(index, e)}
                          value={item.relation}
                          placeholder="Relation to Insured" size='xs' border={'1px solid #d7d7d7'} />
                      </Box>
                      <Box w={['90%','90%','30%']}>
                      <span style={{ marginBottom: "8px" }}>Beneficiary DOB</span>
                      <Input background={'white'} onChange={(e) => handleChangeTraveler(index, e)}
                        name="benificiaryDob"
                        value={item.benificiaryDob}
                        placeholder="Select Date and Time" size='xs' type='date' border={'1px solid #d7d7d7'} max={new Date().toISOString().split('T')[0]} />
                      </Box>
                  </Flex>
                     </Box>
                );
              })}        
              <Box fontSize={'13px'} fontFamily={'Open Sans, sans-serif'} gap={['10px','20px','20px']}>
              <Flex p={'18px'} direction={['column','column','row']} gap={['10px','20px','20px']} fontSize={'14px'} pb={'15px'}>
                <Box w={['90%','90%','30%']}>
                  <span style={{ marginBottom: "8px" }}>Date of Departure</span>
                  <Input name="departureDate" max={formData.firstDate}
                    onChange={handleChange} size='xs' type='date' border={'1px solid #d7d7d7'} />
                </Box>
                {/* <Flex direction={'column'}>
                  <span>Waiting Period</span>
                  <input
                    placeholder="Basic usage"
                    name="waiting"
                    value={waitingPeriod}
                  />
                </Flex> */}
                <Box>
                  {/* <Text>Family Coverage</Text>
                <Checkbox pt={'7px'} onChange={handleCheckbox} size="md" colorScheme="green"></Checkbox> */}
                </Box>
                </Flex>
                {/* <hr/> */}
               <p style={{
                  fontFamily: "poppins",
                  fontSize: "16px",
                  fontWeight: 400,
                  marginBottom: "20px",
                  borderBottom: "2px solid #088183",
                  paddingTop:'10px'
                }}
              >
                Contact Details
              </p>
                <Flex fontFamily={'Open Sans, sans-serif'} p={'18px'} pt={'1px'} direction={['column','column','row']} gap={['10px','20px','20px']} fontSize={'13px'} pb={'10px'} mt={['10px','30px','30px']}>
                  <Box w={['90%','90%','30%']}>
                  <Text>Policy Owner*</Text>
                  <Select background={'white'} size={'xs'} border={'1px solid #d7d7d7'} name="policyOwnerName" value={policyHolder.policyOwnerName} onChange={handlePolicyOwnerChange}>
                      <option value="">Select Policy Owner</option>
                      {addTravelers.map((traveler, index) => (
                      <option key={index} value={traveler.name}>
                          {traveler.name}
                      </option>
                      ))}
                  </Select>
                  </Box>
                  <Box w={['90%','90%','30%']}>
                  <Text>Policy Owner Date Of Birth*</Text>
                  <Input type='date' size='xs' readOnly name="policyOwnerDob" value={policyHolder.policyOwnerDob} onChange={handlePolicyOwnerChange} border={'1px solid #d7d7d7'} />            
                  </Box>
                  <Box w={['90%','90%','30%']}>
                  <Text>Policy Owner Email*</Text>
                  <Input placeholder='Email' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerEmail" value={policyHolder.policyOwnerEmail} onChange={handlePolicyOwnerChange}/>    
                  </Box>
                  </Flex>
                  <Flex fontFamily={'Open Sans, sans-serif'} p={'18px'} pt={'1px'} direction={['column','column','row']} gap={['10px','20px','20px']} fontSize={'13px'} pb={'20px'} mt={['10px','20px','10px']}>
                  <Box w={['90%','90%','30%']}>
                  <Text>Policy Owner Postal Code*</Text>
                  <Input placeholder='Postal code' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerPostalCode" value={policyHolder.policyOwnerPostalCode} onChange={handlePolicyOwnerChange}/>   
                  </Box>
                  <Box w={['90%','90%','30%']}>
                  <Text>Policy Owner City*</Text>
                  <Input placeholder='City' size='xs' border={'1px solid #d7d7d7'} name="policyOwnerCity" value={policyHolder.policyOwnerCity} onChange={handlePolicyOwnerChange} />   
                  </Box>
                  <Box w={['90%','90%','30%']}>
                  <Text>Policy Owner Phone*</Text>
                  <Input placeholder='Phone' size='xs' border={'1px solid #d7d7d7'} name="policyOwnerPhone" value={policyHolder.policyOwnerPhone} onChange={handlePolicyOwnerChange} />            
                  </Box>
                  </Flex>
                  <Box p={'18px'} pt={'1px'} pb='30px' w={['90%','90%','30%']}>
                  <Text fontFamily={'Open Sans, sans-serif'} fontSize={'13px'}>Policy Owner Address*</Text>
                  <Input placeholder='Address' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerAddress" value={policyHolder.policyOwnerAddress} onChange={handlePolicyOwnerChange} />     
                  </Box>
                  <Flex justifyContent={'end'}>
                  <Button size={'sm'} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} background={buttonBackgroundColor} color={buttonColor} onClick={handleGetQuote} > Proceed</Button>
                  </Flex>
              </Box>
            </div>
        </Box>
      </Flex>
    </Box>
    </Box>
  )
}

export default ViewSingleQuote


