import { SupervisaQuicknoteList, advisorHandleCancellation, advisorHandleCorrectionExtendDate, advisorHandleCorrectionInsuredDetails, advisorHandleCorrectionNonFinancial } from "./action";
import Api from "../../dependencies/utils/Api";
import axios from "axios";
import { ADVISOR_PERSONAL_DETAILS, ADVISOR_PROFILE, ADVISOR_REFRENCE_DETAILS } from "../../constants/ApplicationUrl";

// export const AdvisorSignupService = {
//     async advisorSignupList(params) {
//         try {
//             const response = await Api.POST(ADVISOR_SIGNUP, params);
//             const {status} = response;
//             console.log(response,'resss')
//             if (status) {
//                 return response;
//             } else {
//                 return status;
//             }
//         } catch (error) {
//             console.error(error);
//             throw error;
//         }
//     },
// };


export const AdvisorLoginService = {
    // advisorLoginList(params) {
    //     return Api.POST(ADVISOR_LOGIN, params).then((response) => {
    //         const { data, status} = response
    //         if(status === 200) {
    //             return {data, status}
    //         }else {
    //             const {data: {message} = {}} = response
    //             return {message, status}
    //         }
    //     })
    // },

    advisorProfile() {
        return Api.GET(ADVISOR_PROFILE).then((response) => {
            console.log(response,'advisorprofile')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
  
    advisorRefrence(params) {
        return Api.POST(ADVISOR_REFRENCE_DETAILS,params).then((response) => {
            console.log(response,'response')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

    advisorPersonalDetails(params) {
        return Api.PUTDATA(ADVISOR_PERSONAL_DETAILS,params).then((response) => {
          console.log(response,"responsefaraz")
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
}