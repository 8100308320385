import { CUSTOMER_LOGIN, CUSTOMER_SIGNUP, VTC_COMPARSION_TYPE, isLoading } from "../../../constants/actionConstants";
import { CustomerSignupService, VtcCompareListService } from "./service";
import { toast } from "react-toastify";


export const vtvCompareList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let signUpList = await VtcCompareListService.vtvCompareList(params);
    const {status, message, data} = signUpList

    if (status === 201 || status === 200) {
        dispatch({
          type: VTC_COMPARSION_TYPE,
          payload: message, data
        })
      
      } else {
        toast.success(message)
      }
    
      dispatch(isLoading(false));

      return signUpList
}


// export const assingMenus = ( params) => async (dispatch) => {
//     dispatch(isLoading(true));
//     let adminSatatus = await AdminUserService.assingMenus( params);
//     const { status, message, data } = adminSatatus
  
//     if (status === 201 || status === 200) {
//       dispatch({
//         type: ASSING_MENUS_TYPE,
//         payload: message, data
//       })
//       toast.success(" Successfully")
//     } else {
//       toast.success(message)
//     }
  
//     dispatch(isLoading(false));
  
//     return adminSatatus
//   }