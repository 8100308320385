import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Grid, GridItem, Image, Input, Select, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor } from '../../Utils/UniversalColors'
import { useNavigate } from 'react-router-dom'
import '../../fonts/fonts.css'


const TravelCompare = () => {
    const navigate=useNavigate()
    const toast = useToast();
    const [selectedItems, setSelectedItems] = useState([]);

  const itemData = {
    destination: { name: 'Destination Travel', monthly: '$64.03', annual: '$768.33',image:'https://storage.googleapis.com/visitorinsurancezone/images/partners/destination.png' },
    travelmedicare: { name: 'Travel Medicare', monthly: '$60.00', annual: '$720.00',image:'https://travelmedicare.com/public/users/images/logo.png' },
    tugo: { name: 'Tugo Insurance', monthly: '$70.00', annual: '$840.00',image:'https://storage.googleapis.com/visitorinsurancezone/images/partners/tugo.png' },
    manulife: { name: 'Manulife Insurance', monthly: '$75.00', annual: '$900.00',image:'https://storage.googleapis.com/visitorinsurancezone/images/partners/tugo.png' },
  };

  // Function to add item to the compare list
  const handleAddToCompare = (id, name) => {
    setSelectedItems(prevItems => {
      if (prevItems.some(item => item.id === id)) {
        toast({
          title: "Item already added to compare.",
          status: "info",
          duration: 2000,
          isClosable: true,
        });
        return prevItems;
      }
      return [...prevItems, { id, name }];
    });
  };

  // Function to navigate to the comparison page
  const handleCompare = () => {
    if (selectedItems.length > 1) {
      const itemsWithDetails = selectedItems.map(item => ({
        ...item,
        ...itemData[item.id]
      }));
      navigate('/compared-products', { state: { items: itemsWithDetails } });
    } else {
      toast({
        title: "Select at least two items to compare.",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    }
  };



return (
    <>
<Box pt={'98px'} background={'#efefef'} position={'fixed'} h={'100vh'} w={'100%'}>
    <Flex w={'98%'} m={'auto'} gap={'20px'} h={'100vh'}>    
        <Box 
            boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' 
            background={'white'} 
            w={'20%'} 
            p={'20px'} 
            fontFamily={'Poppins, sans-serif'} fontSize={'12px'} >
            <Text fontFamily={'Montserrat,sans-serif'} textAlign={'center'} fontSize='22px' fontWeight='600' p={'10px'}>Travel Insurance</Text>
            <hr />
            <Box mt={'15px'}>
                <Text pb={'8px'}>Trip Type*</Text>
                <Flex justifyContent={'space-around'}>
                    <Flex gap={'5px'}>
                        <input type="radio" />
                        <Text>Single Trip</Text> 
                    </Flex>
                    <Flex gap={'5px'}>
                        <input type="radio" />
                        <Text>Multi-trip</Text>
                    </Flex>
                </Flex>
            </Box>
            <Box mt={'15px'}>
                <Text pb={'5px'}>Type Of policy</Text>
                <Box w={'80%'} m={'auto'}>
                    <Select size={'xs'}>
                        <option value="">Select</option>
                        <option value="">Single</option>
                        <option value="">Couple</option>
                        <option value="">Family</option>
                    </Select>
                </Box>
            </Box>
            <Box mt={'15px'}>
                <Text>Applicant DOB</Text>
                <Box w={'80%'} m={'auto'}>
                    <Input size={'xs'} type='date'/>
                </Box>
            </Box>
            <Box mt={'15px'}>
                <Text>Start Date</Text>
                <Box w={'80%'} m={'auto'}>
                    <Input size={'xs'} type='date'/>
                </Box>
            </Box>
            <Box mt={'15px'}>
                <Text>End Date</Text>
                <Box w={'80%'} m={'auto'}>
                    <Input size={'xs'} type='date'/>
                </Box>
            </Box>
            <Box mt={'15px'}>
                <Text>Cover Pre-existing*</Text>
                <Flex justifyContent={'space-around'}>
                    <Flex gap={'5px'}>
                        <input type="radio" />
                        <Text>Yes</Text> 
                    </Flex>
                    <Flex gap={'5px'}>
                        <input type="radio" />
                        <Text>No</Text>
                    </Flex>
                </Flex>
            </Box>
            <Flex justifyContent={'center'} mt={'15px'}>
                <Button size={'xs'} background={buttonBackgroundColor} color={buttonColor}>Submit</Button>
            </Flex>
        </Box>

    <Box w={'80%'}>
        <Box background={'white'} mb={'120px'} h={'90vh'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' overflowY="auto" p="10px">
            <Box>
                <Text fontSize={'13px'} fontFamily={'Poppins, sans-serif'}>Super visa insurance for (age 40yrs) for 365 days, excluding coverage for pre-existing medical conditions</Text>
            </Box>
            <Box mt={'35px'} mb={'30px'}>
                <Accordion defaultIndex={[0]} allowMultiple>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'>
                                <Flex justifyContent={'space-around'} alignItems={'center'}>
                                    <Box w={'15%'}>
                                     <Image w={'100px'} src='https://www.desttravel.com/images/Destination_Travel_Logo_2018_Bilingual_1.png' />
                                     </Box>
                                     <Flex fontSize={'16px'} fontWeight={'600'} direction={'column'} alignItems={'center'}>
                                     <Text>$64.03/month</Text>
                                     <Text>$768.56 Annually</Text>
                                     </Flex>
                                     <Box>
                                        <Text fontSize={'12px'}>
                                        $5M coverage / $250 deductible <br />
                                        Covid-19 Coverage Included.</Text>
                                     </Box>
                                     <Box>
                                     <Flex gap={'10px'} p={'15px'} fontSize={'16px'} fontWeight={'600'} alignItems={'center'}>
                                     <Button size={'xs'} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} background={buttonBackgroundColor} color={buttonColor}>Save</Button>
                                    <Button size={'xs'} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} background={buttonBackgroundColor} color={buttonColor}> Buy</Button>
                                     </Flex>
                                     <Flex justifyContent={'center'} gap={'5px'} pb={'17px'}>
                                    <input type="radio"  onClick={() => handleAddToCompare('destination', 'Destination')} />
                                    <Text>Add to compare</Text>
                                </Flex>
                                     </Box>
                                  </Flex>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Box background={'#F4F4F4'} w={'95%'} p={'20px'} m={'auto'}>
                                <Flex justifyContent={'space-around'}>
                                    <Box w={'30%'}>
                                        <Text fontWeight={'600'}>About</Text>
                                        <Text color={'#666'} fontSize={'12px'} fontFamily={'"Aspira W01",sans-serif'}>LS Travel develops and sells life, health and travel insurance products for both individuals and groups. Their products are distributed in all provinces (except Nova Scotia, P.E.I. and Newfoundland). By focusing on meeting</Text>
                                    </Box>
                                    <Box w={'30%'}>
                                    <Text fontWeight={'600'}>Coverages</Text>
                                    <Box color={'#666'} fontSize={'12px'} fontFamily={'"Aspira W01",sans-serif'}>
                                    <ul>
                                            <li>$5M coverage / $250 deductible</li>
                                            <li>Price quoted does not include coverage for Covid-19.</li>
                                            <li>Coverage may be available for an extra-premium. Call for details.</li>
                                            <li>Medical questionnaire may be required</li>
                                        </ul>
                                        </Box>
                                    </Box>
                                    <Box w={'30%'}>
                                    <Text fontWeight={'600'}>Exclusions</Text>
                                    <Text color={'#666'} fontSize={'12px'} fontFamily={'"Aspira W01",sans-serif'}>LS Travel develops and sells life, health and travel insurance products for both individuals and groups.</Text>
                                    <Text mt={'10px'} color={buttonBackgroundColor} fontSize={'14px'} fontFamily={'"Aspira W01",sans-serif'}>Policy wording</Text>
                                    <Text color={buttonBackgroundColor} fontSize={'14px'} fontFamily={'"Aspira W01",sans-serif'}>View policy pdf</Text>
                                    </Box>
                                </Flex>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'>
                                <Flex justifyContent={'space-around'} alignItems={'center'}>
                                    <Box w={'15%'}>
                                     <Image w={'100px'} src='https://travelmedicare.com/public/users/images/logo.png' />
                                     </Box>
                                     <Flex fontSize={'16px'} fontWeight={'600'} direction={'column'} alignItems={'center'}>
                                     <Text>$64.03/month</Text>
                                     <Text>$768.56 Annually</Text>
                                     </Flex>
                                     <Box>
                                        <Text fontSize={'12px'}>
                                        $5M coverage / $250 deductible <br />
                                        Covid-19 Coverage Included.</Text>
                                     </Box>
                                     <Box>
                                     <Flex gap={'10px'} p={'15px'} fontSize={'16px'} fontWeight={'600'} alignItems={'center'}>
                                     <Button size={'xs'} background={buttonBackgroundColor} color={buttonColor}>Save</Button>
                                    <Button size={'xs'} background={buttonBackgroundColor} color={buttonColor}> Buy</Button>
                                     </Flex>
                                     <Flex justifyContent={'center'} gap={'5px'} pb={'17px'}>
                                    <input type="radio"  onClick={() => handleAddToCompare('travelmedicare', 'Travelmedicare')} />
                                    <Text>Add to compare</Text>
                                </Flex>
                                     </Box>
                                  </Flex>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                       <AccordionPanel pb={4}>
                            <Box background={'#F4F4F4'} w={'95%'} p={'20px'} m={'auto'}>
                                <Flex justifyContent={'space-around'}>
                                    <Box w={'30%'}>
                                        <Text fontWeight={'600'}>About</Text>
                                        <Text color={'#666'} fontSize={'12px'} fontFamily={'"Aspira W01",sans-serif'}>LS Travel develops and sells life, health and travel insurance products for both individuals and groups. Their products are distributed in all provinces (except Nova Scotia, P.E.I. and Newfoundland). By focusing on meeting</Text>
                                    </Box>
                                    <Box w={'30%'}>
                                    <Text fontWeight={'600'}>Coverages</Text>
                                    <Box color={'#666'} fontSize={'12px'} fontFamily={'"Aspira W01",sans-serif'}>
                                    <ul>
                                            <li>$5M coverage / $250 deductible</li>
                                            <li>Price quoted does not include coverage for Covid-19.</li>
                                            <li>Coverage may be available for an extra-premium. Call for details.</li>
                                            <li>Medical questionnaire may be required</li>
                                        </ul>
                                        </Box>
                                    </Box>
                                    <Box w={'30%'}>
                                    <Text fontWeight={'600'}>Exclusions</Text>
                                    <Text color={'#666'} fontSize={'12px'} fontFamily={'"Aspira W01",sans-serif'}>LS Travel develops and sells life, health and travel insurance products for both individuals and groups.</Text>
                                    <Text mt={'10px'} color={buttonBackgroundColor} fontSize={'14px'} fontFamily={'"Aspira W01",sans-serif'}>Policy wording</Text>
                                    <Text color={buttonBackgroundColor} fontSize={'14px'} fontFamily={'"Aspira W01",sans-serif'}>View policy pdf</Text>
                                    </Box>
                                </Flex>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                    {/* <AccordionItem>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'>
                                <Flex justifyContent={'space-around'} alignItems={'center'}>
                                    <Box w={'15%'}>
                                     <Image src='https://www.desttravel.com/images/Destination_Travel_Logo_2018_Bilingual_1.png' />
                                     </Box>
                                     <Flex fontSize={'16px'} fontWeight={'600'} direction={'column'} alignItems={'center'}>
                                     <Text>$64.03/month</Text>
                                     <Text>$768.56 Annually</Text>
                                     </Flex>
                                     <Box>
                                        <Text fontSize={'12px'}>
                                        $5M coverage / $250 deductible <br />
                                        Covid-19 Coverage Included.</Text>
                                     </Box>
                                     <Box>
                                     <Flex gap={'10px'} p={'15px'} fontSize={'16px'} fontWeight={'600'} alignItems={'center'}>
                                     <Button size={'sm'} background={buttonBackgroundColor} color={buttonColor}>Save</Button>
                                    <Button size={'sm'} background={buttonBackgroundColor} color={buttonColor}> Buy</Button>
                                     </Flex>
                                     <Flex justifyContent={'center'} gap={'5px'} pb={'17px'}>
                                    <input type="radio"  onClick={() => handleAddToCompare('destination', 'Destination')} />
                                    <Text>Add to compare</Text>
                                </Flex>
                                     </Box>
                                  </Flex>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        <AccordionPanel pb={4}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                            commodo consequat.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'>
                                <Flex justifyContent={'space-around'} alignItems={'center'}>
                                    <Box w={'15%'}>
                                     <Image src='https://www.desttravel.com/images/Destination_Travel_Logo_2018_Bilingual_1.png' />
                                     </Box>
                                     <Flex fontSize={'16px'} fontWeight={'600'} direction={'column'} alignItems={'center'}>
                                     <Text>$64.03/month</Text>
                                     <Text>$768.56 Annually</Text>
                                     </Flex>
                                     <Box>
                                        <Text fontSize={'12px'}>
                                        $5M coverage / $250 deductible <br />
                                        Covid-19 Coverage Included.</Text>
                                     </Box>
                                     <Box>
                                     <Flex gap={'10px'} p={'15px'} fontSize={'16px'} fontWeight={'600'} alignItems={'center'}>
                                     <Button size={'sm'} background={buttonBackgroundColor} color={buttonColor}>Save</Button>
                                    <Button size={'sm'} background={buttonBackgroundColor} color={buttonColor}> Buy</Button>
                                     </Flex>
                                     <Flex justifyContent={'center'} gap={'5px'} pb={'17px'}>
                                    <input type="radio"  onClick={() => handleAddToCompare('destination', 'Destination')} />
                                    <Text>Add to compare</Text>
                                </Flex>
                                     </Box>
                                  </Flex>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        <AccordionPanel pb={4}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                            commodo consequat.
                        </AccordionPanel>
                    </AccordionItem> */}
                </Accordion>
            </Box>
        </Box>
        </Box>
    </Flex>
</Box>
    

    {selectedItems.length > 0 && (
        <Box fontFamily={'Poppins, sans-serif'} position="fixed" bottom="10px" right="10px" background="white" border="1px solid grey" p="10px" borderRadius="5px" boxShadow="md" fontSize={'13px'}>
          <Text fontWeight="bold">Selected for Comparison:</Text>
          <Box mt="5px">
            {selectedItems.map(item => (
              <Text key={item.id}>{item.name}</Text>
            ))}
          </Box>
          <Button size={'xs'} mt="10px" background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} onClick={handleCompare}>
            Compare
          </Button>
        </Box>
      )}
    </>
  )
}

export default TravelCompare
