import { CUSTOMER_LOGIN, CUSTOMER_SIGNUP, isLoading } from "../../../constants/actionConstants";
import { CustomerSignupService } from "./service";
// import { toast } from "react-toastify";


export const customerSignupList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let signUpList = await CustomerSignupService.customerSignupList(params);
    const {status, message, data} = signUpList

    dispatch({
      type: CUSTOMER_SIGNUP,
      payload: message,
      data,
    });
    dispatch(isLoading(false));
    return status
}

export const customerLoginList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let loginList = await CustomerSignupService.customerLoginList(params);
    const {status, message, data} = loginList
    
    if(status === 200) {
        dispatch({
            type: CUSTOMER_LOGIN,
            payload: data
        })
        // toast.success("User Successfully Logged In")
    }else {
        // toast.error(message)
    }
    
    dispatch(isLoading(false));

    return {status, data}
}


export const advisorSignupList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try{
    let res = await CustomerSignupService.advisorSignupList(params);
    const {status, data} = res
    if(status===200 || status===201){
        dispatch({
            type: ADVISOR_SIGNUP,
            payload: data,
            });
            toast('Advisor Created Successfully.')
        }else{
            alert(data.message)
            }
            dispatch(isLoading(false));
    return status
}catch(err){
    console.log(err,'err')
}
}