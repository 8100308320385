import React from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react'

const GetQuotesForm = () => {




return (
    <Box>
        <hr />
    <Box w={'85%'} m={'auto'} mt={'50px'} p={'50px'} mb={'120px'}>
        <Tabs variant='enclosed'>
            <TabList>
                <Tab>Supervisa Insurnace</Tab>
                <Tab>Visitor Insurnace</Tab>
                <Tab>Travel Insurance</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>




                </TabPanel>
                <TabPanel>
                <p>two!</p>
                </TabPanel>
                <TabPanel>
                <p>three!</p>
                </TabPanel>
            </TabPanels>
            </Tabs>
    </Box>
    </Box>
  )
}

export default GetQuotesForm
