import { Box, Text,Flex, Button } from '@chakra-ui/react'
import React from 'react'
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor } from '../../../Utils/UniversalColors'

const TravelAllProviders = () => {



return (
    <Box>
        <hr />
        <Box w={'95%'} m={'auto'} mt={'45px'}>
            <Text fontFamily='Manrope, sans-serif' fontWeight={'700'} color={'rgb(75 89 102)'} fontSize={'32px'}>Our Insurance Partners</Text>
            <Box w={'90%'} m={'auto'}>
            <Text lineHeight={'28px'} mt={'7px'} color={'rgb(119 119 119)'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>By providing quotes from such a broad range of providers across multiple product lines, we can help you find the most competitive rates in a way no broker or agent can. Each of our partners offers a variety of products and quality customer service. We work with Canada's best insurance companies. Find out more about some of them here.</Text>
            </Box>
        </Box>
        <Box w={'80%'} m={'auto'} mt={'120px'} pb={'90px'}>
        <Flex mt={'50px'} justifyContent={'space-around'}>
          <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(255 234 233)',backgroundImage:'none'}} backgroundColor={'rgb(255 234 233)'} p={'10px'}>
          <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
            <Flex justifyContent={'center'}>
          <img width="48" height="48" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2Nj44SlJ7TWuHM7neRd5jhouXp2aYsuJ4AA&s" alt="user"/>              
            </Flex>
          <Text mt={'10px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Tugo</Text>
              <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
          </Box>
          </Box>

          <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(236 240 255)',backgroundImage:'none'}} backgroundColor={'rgb(236 240 255)'} p={'10px'}>
          <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
            <Flex justifyContent={'center'}>
          <img width="68" height="68" src="https://m.economictimes.com/thumb/msid-62829088,width-1200,height-900,resizemode-4,imgsize-17401/manulife-webcite.jpg" alt="user"/>              
            </Flex>
          <Text mt={'10px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Manu Life</Text>
              <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
          </Box>
          </Box>


          <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(253 225 245)',backgroundImage:'none'}} backgroundColor={'rgb(253 225 245)'} p={'10px'}>
          <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
            <Flex justifyContent={'center'}>
          <img width="98" height="98" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvYiu8tiPsacAyA3RoUb3nlbKiQgdFd2e4dw&s" alt="user"/>              
            </Flex>
          <Text mt={'30px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Expitrate Group</Text>
              <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
          </Box>
          </Box>

          <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(249 249 217)',backgroundImage:'none'}} backgroundColor={'rgb(249 249 217)'} p={'10px'}>
          <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
            <Flex justifyContent={'center'}>
          <img width="78" height="98" src="https://static.rates.ca/images/travelers-insurance-logo-min.max-160x160.png" alt="user"/>              
            </Flex>
          <Text mt={'30px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Travelers</Text>
              <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
          </Box>
          </Box>

        </Flex>

        <Flex mt={'120px'} justifyContent={'space-around'}>
          <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(255 234 233)',backgroundImage:'none'}} backgroundColor={'rgb(255 234 233)'} p={'10px'}>
          <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
            <Flex justifyContent={'center'}>
          <img width="68" height="98" src="https://static.rates.ca/images/ScoopInsurance_Logo_Primary.max-160x160.png" alt="user"/>              
            </Flex>
          <Text mt={'30px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Scoop Insurance</Text>
              <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
          </Box>
          </Box>

          <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(236 240 255)',backgroundImage:'none'}} backgroundColor={'rgb(236 240 255)'} p={'10px'}>
          <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
            <Flex justifyContent={'center'}>
          <img width="68" height="98" src="https://static.rates.ca/images/logo-Economical-Insurance_YORD5tb.max-160x160.png" alt="user"/>              
            </Flex>
          <Text mt={'20px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Economical</Text>
              <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
          </Box>
          </Box>


          <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(253 225 245)',backgroundImage:'none'}} backgroundColor={'rgb(253 225 245)'} p={'10px'}>
          <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
            <Flex justifyContent={'center'}>
          <img width="48" height="48" src="https://static.rates.ca/images/coachman-insurance-logo-min.max-160x160.png" alt="user"/>              
            </Flex>
          <Text mt={'10px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Coachman</Text>
              <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
          </Box>
          </Box>

          <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(249 249 217)',backgroundImage:'none'}} backgroundColor={'rgb(249 249 217)'} p={'10px'}>
          <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
            <Flex justifyContent={'center'}>
          <img width="48" height="48" src="https://static.rates.ca/images/gore-mutual-insurance-logo-min.max-160x160.png" alt="user"/>              
            </Flex>
          <Text mt={'20px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Core Mutual</Text>
              <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
          </Box>
          </Box>

        </Flex>
      </Box>

        <Box textAlign={'center'} background={'#ededef'} mt={'60px'} p={'40px 5px'}>
            <Text fontFamily='Manrope, sans-serif' fontWeight={'700'} color={'rgb(75 89 102)'} fontSize={'32px'}>Dont waste time calling around</Text>
            <Text lineHeight={'28px'} mt={'7px'} color={'rgb(119 119 119)'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>Use TravelComparison to shop</Text>
            <Flex justifyContent={'center'} mt={'15px'}>
                <Button size={'sm'} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} backgroundColor={buttonBackgroundColor} color={buttonColor}>Get Quotes</Button>
            </Flex>
        </Box>
    </Box>
  )
}

export default TravelAllProviders


