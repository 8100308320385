import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  Circle,
  Input,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Dummy from "../../assets/img/Insta.png";
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor } from "../../Utils/UniversalColors";
import tugo from '../../assets/img/tugo.jpg'
import imgg from '../../assets/img/imgg.png'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import supervisa from '../../assets/img/supervisa.png'
import visitor from '../../assets/img/visitor.png'
import student from '../../assets/img/student.png'



function Homepage() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };




return (
  <>
    <Box pb={'50px'} pt={['60px','80px','80px']}>
    <Box backgroundSize={'cover'} backgroundRepeat={'no-repeat'} backgroundPosition={'right'} backgroundImage={'https://envato.bdevs.net/tourigo/wp-content/uploads/2024/05/landing-bg.png'} pt={['20px','50px','50px']} pb={'50px'}>
      <Flex direction={['column-reverse','column','row']}>
      <Box w={['90%','95%','45%']} pl={'52px'} pt={'40px'}>
        <Text fontFamily='Manrope, sans-serif' fontWeight={'700'} color={'rgb(75 89 102)'} fontSize={['28px','32px','32px']} >Find the Best Travel Insurance in Canada</Text> 
        <Text fontSize={['14px','16px','16px']} fontFamily={'poppins'} pt={['10px','20px','20px']}>Compare the cheapest travel insurance quotes to get the best coverage available.</Text>
        <Box pt={'30px'}>
          <Flex>
              <Link to='/super-visa-compare'>
              <Button
              _hover={{
                background: buttonHoverBackground,
                color: buttonHoverColor
              }}
              size={'md'}
              backgroundColor={buttonBackgroundColor}
              color={buttonColor}
              fontFamily={'Poppins, sans-serif'}
              fontWeight={'500'}
              fontSize={'13px'}
            >
              Get A Quote
            </Button>
            </Link>
          </Flex>
        </Box>
      </Box>
      <Flex w={['95%','95%','45%']} justifyContent={'center'} m={'auto'}>
        <Flex justifyContent={'center'}>
        <Image mt={'-10px'} w={'450px'} src={imgg}/>
        </Flex>
      </Flex>
      </Flex>
    </Box>

    <Box w={'80%'} m={'auto'} mt={'30px'}>
      <Flex direction={['column','column','row']} justifyContent={['center','center','space-around']}>
        <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(255 234 233)',backgroundImage:'none'}} backgroundColor={'rgb(255 234 233)'} p={'10px'}>
        <Link to={'/super-visa-compare'}>
        <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
          <Flex justifyContent={'center'}>
        <img width="68" height="48" src={supervisa} alt="user"/>              
          </Flex>
        <Text mt={'10px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Super Visa <br />Insurance</Text>
            <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
        </Box>
        </Link>
        </Box>
        <Link to={'/visitor-compare'}>
        <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(236 240 255)',backgroundImage:'none'}} backgroundColor={'rgb(236 240 255)'} p={'10px'}>
        <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
          <Flex justifyContent={'center'}>
        <img width="48" height="48" src={visitor} alt="user"/>              
          </Flex>
        <Text mt={'10px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Visitor  <br /> Insurance</Text>
            <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
        </Box>
        </Box>
        </Link>

        <Link to={'/student-compare'}>
        <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(253 225 245)',backgroundImage:'none'}} backgroundColor={'rgb(253 225 245)'} p={'10px'}>
        <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
          <Flex justifyContent={'center'}>
        <img width="48" height="48" src={student} alt="user"/>              
          </Flex>
        <Text mt={'10px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Student Travel <br />Inurance</Text>
            <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
        </Box>
        </Box>
        </Link>

        <Box cursor={'pointer'} transition={'all 0.3s ease-in-out'} backgroundImage='linear-gradient(360deg, #fff, #fff, transparent)' _hover={{ transition:'all 0.3s ease-in-out',backgroundColor:'rgb(249 249 217)',backgroundImage:'none'}} backgroundColor={'rgb(249 249 217)'} p={'10px'}>
        <Box background={'white'} boxShadow={'0 5px 15px rgba(88, 110, 125, 0.1)'} w={'150px'} p={'10px'} textAlign={'center'}>
          <Flex justifyContent={'center'}>
        <img width="48" height="48" src="https://img.icons8.com/parakeet/48/user.png" alt="user"/>              
          </Flex>
        <Text mt={'10px'} color={'rgb(75 89 102)'} fontFamily={'Manrope, sans-serif'} fontWeight={'600'} fontSize={'15px'}>Travel <br />Insurance</Text>
            <Text mt={'7px'} color={'rgb(119 119 119)'} fontSize={'13px'} fontWeight={'300'} fontFamily={"Poppins, sans-serif"}>300 items</Text>
        </Box>
        </Box>

      </Flex>
    </Box>



    <Box w={'95%'} m={'auto'} mt={'120px'} boxshadow={"rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;"}>
      <Flex direction={['column','column','row']} justifyContent={'space-between'} gap={'20px'}>
        <Box p={'25px'} background={'#ededef'} w={['95%','95%','45%']} h={'auto'} borderRadius={'5px'}>
        <Text fontFamily='Manrope, sans-serif' fontWeight={'700'} color={'rgb(75 89 102)'} fontSize={'24px'} >Super Visa</Text> 
        <Box fontSize={'14px'} p={'20px'}>
                  <ul style={{lineHeight:'28px'}}>
                      <li> Min of CAD 100,000 of Sum Insured for min duration of 365 days.</li>
                      <li> No Cancellation Penalty.</li>
                      <li>Age from 15 days to 90 Years & 3 Product Choices Standard, Enhanced and Premium.</li>
                      <li>Just 90 days stability condition up to age 74.</li>
                      <li>Monthly Payments Available.</li>
                      <li> Covers you for any unforeseen Emergency Medical, Physician & Hospitalization expenses, Diagnostics, Prescriptions and Dental etc.</li>
                  </ul>
                  </Box>
        </Box>
        <Box p={'25px'} background={'#ededef'} w={['95%','95%','45%']} h={'auto'} borderRadius={'5px'}>
        <Text fontFamily='Manrope, sans-serif' fontWeight={'700'} color={'rgb(75 89 102)'} fontSize={'24px'} >Single Trip</Text> 
        <Box fontSize={'14px'} p={'20px'} pl={['10px','90px','40px']}>
                  <ul style={{lineHeight:'28px'}}>
                      <li>Most Visitors get visa for 6 months and they need to renew after 6 months may get up to 10 years multiple entry</li>
                      <li>Minimum Sum Insured limit of $25,000 and no minimum Trip Duration</li>
                      <li>Have a valid travel document, like a passport</li>
                      <li>Be in good health</li>
                      <li>Have no criminal or immigration-related convictions</li>
                      <li>Convince an immigration officer that you will leave Canada at the end of your visit</li>
                  </ul>
                  </Box>
        </Box>
      </Flex>
    </Box>



    {/* <Box w={'89%'} m={'auto'} mt='95px'>
      <Flex direction={['column','column','row']} justifyContent={['center','center','space-around']} gap={'20px'}>
      <Box p={'20px'}>
            <Flex justifyContent={'center'}>
              <Image src="https://static.rates.ca/images/RDOT_Icon_Travel_140x140_Red_1.2e16d0ba.fill-50x50.png"/>
            </Flex>
            <Text pt={'7px'} fontFamily={'Montserrat, Sans-serif'} fontSize={'24px'} textAlign={'center'}>Tell us about your trip!</Text>
            <Text pt={'5px'} fontFamily={'Montserrat, Sans-serif'} fontSize={'16px'} textAlign={'center'}>Answer a few basic questions about your upcoming trip.</Text>
        </Box>

        <Box p={'20px'}>
            <Flex justifyContent={'center'}>
              <Image w={'60px'} src="https://img.freepik.com/free-vector/recommendations-icon_632498-3980.jpg?t=st=1721132908~exp=1721136508~hmac=6d54071f72e8de52e9350fd26db47607b35e06d781b5168ecfc35ad457fc4d60&w=740"/>
            </Flex>
            <Text pt={'1px'} fontFamily={'Montserrat, Sans-serif'} fontSize={'24px'} textAlign={'center'}>Compare Your Quotes</Text>
            <Text pt={'5px'} fontFamily={'Montserrat, Sans-serif'} fontSize={'16px'} textAlign={'center'}>Instantly compare travel insurance policies from Canada’s top providers.</Text>
        </Box>

        <Box p={'20px'}>
            <Flex justifyContent={'center'}>
              <Image src="https://static.rates.ca/images/RDOT_Icon_Travel_140x140_Red_1.2e16d0ba.fill-50x50.png"/>
            </Flex>
            <Text pt={'7px'} fontFamily={'Montserrat, Sans-serif'} fontSize={'24px'} textAlign={'center'}>Choose your policy</Text>
            <Text pt={'5px'} fontFamily={'Montserrat, Sans-serif'} fontSize={'16px'} textAlign={'center'}>Find the best rate and travel coverage for your trip.</Text>
        </Box>

        <Box p={'20px'}>
            <Flex justifyContent={'center'}>
              <Image w={'50px'} src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6d/Eo_circle_teal_checkmark.svg/2048px-Eo_circle_teal_checkmark.svg.png"/>
            </Flex>
            <Text pt={'7px'} fontFamily={'Montserrat, Sans-serif'} fontSize={'24px'} textAlign={'center'}>Get Covered</Text>
            <Text pt={'5px'} fontFamily={'Montserrat, Sans-serif'} fontSize={'16px'} textAlign={'center'}>Connect with your chosen provider and secure your rate.</Text>
        </Box>

      </Flex>
    </Box> */}



    <Box w={'90%'} m={'auto'} mt={['70px','140px','140px']}>
      <Text fontFamily='Manrope, sans-serif' fontWeight={'700'} color={'rgb(75 89 102)'} fontSize={'24px'} textAlign={'center'} pt={'20px'}>We Compare Rates From Best</Text>
      <Box mt={'50px'} overflow="hidden">
      <Carousel
        swipeable
        draggable
        responsive={responsive}
        infinite
        showDots
        autoPlay
        autoPlaySpeed={1000}
        keyBoardControl
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-20-px"
      >
          <Flex w={'250px'} alignItems={'center'} direction={'column'} p={'20px'} border={'1px solid grey'} borderRadius={'10px'}>
            <Image w={'70px'} h={'50px'} src={tugo}/>
              <Text pt={'15px'} fontSize={'18px'} fontFamily={'Open Sans,sans-serif'}>Tugo</Text>
          </Flex>
          <Flex w={'250px'} alignItems={'center'} direction={'column'} p={'20px'} border={'1px solid grey'} borderRadius={'10px'}>
            <Image w={'90px'} h={'50px'} src={'https://travelmedicare.com/public/users/images/logo.png'}/>
              <Text pt={'15px'} fontSize={'18px'} fontFamily={'Open Sans,sans-serif'}>Travel Medicare</Text>
          </Flex>
          <Flex w={'250px'} alignItems={'center'} direction={'column'} p={'20px'} border={'1px solid grey'} borderRadius={'10px'}>
            <Image w={'90px'} h={'50px'} src={'https://advisor.manulife.ca/content/dam/consumer-portal/logo/en/manulife-home-100.svg'}/>
              <Text pt={'15px'} fontSize={'18px'} fontFamily={'Open Sans,sans-serif'}>Manulife</Text>
          </Flex>
          <Flex w={'250px'} alignItems={'center'} direction={'column'} p={'20px'} border={'1px solid grey'} borderRadius={'10px'}>
            <Image w={'90px'} h={'50px'} src={'https://static.rates.ca/images/travelers-insurance-logo-min.max-160x160.png'}/>
              <Text pt={'15px'} fontSize={'18px'} fontFamily={'Open Sans,sans-serif'}>Travelers</Text>
          </Flex>
        </Carousel>
      </Box>
      <Flex justifyContent={'center'} mt={'50px'}>
        <Link to='/travel-all-providers'>
            <Button size={'sm'} fontFamily={'Open Sans,sans-serif'} border={`1px solid ${buttonBackgroundColor}`} color={buttonBackgroundColor} borderRadius={'20px'} background={'white'}>View All Providers</Button>
        </Link>
          </Flex>
    </Box>
  </Box>
  </>
)
}

export default Homepage
