import { Box, Button, Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { buttonBackgroundColor, buttonColor } from '../../../Utils/UniversalColors';
import { FaCircleInfo } from "react-icons/fa6";




const TravelCompariosnQuotes = () => {



return (
    <Box background={'#f7f7f7'} pb={'160px'}>
        <hr />
        <Box background={'white'} w={'80%'} m={'auto'} p={'30px'} mt={'60px'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px'>
            <Flex borderTop={'1px solid grey'} fontFamily={'Manrope, sans-serif'} justifyContent={'space-around'} p={'20px'} alignItems={'center'}>
                <Image src='https://www.quote.rates.ca/pics/logos/travel/BC-1.3.gif' />
                <Text> <span style={{color:'black',fontWeight:'800',fontSize:'26px'}}>$152</span> cost pertrip</Text>
                <Text>$7M coverage / $250 deductible <br />
                        Covid-19 Coverage Included. <br />
                        Read Policy Wordings for full details. <br />
                        No medical form up to age 79</Text>
                <Box>
                    <Button mb={'20px'} size={'sm'} background={buttonBackgroundColor} color={buttonColor}>Buy Now</Button>
                    <Text>Compare Plan</Text>
                </Box>
                {/* <Tooltip hasArrow label='No. of Days policy will be active.' bg='gray.300' color='black' placement='top-start'> */}
                <FaCircleInfo />
                {/* </Tooltip> */}
            </Flex>
            <Flex borderTop={'1px solid grey'} fontFamily={'Manrope, sans-serif'} justifyContent={'space-around'} p={'20px'} alignItems={'center'}>
                <Image src='https://www.quote.rates.ca/pics/logos/travel/BC-1.3.gif' />
                <Text> <span style={{color:'black',fontWeight:'800',fontSize:'26px'}}>$152</span> cost pertrip</Text>
                <Text>$7M coverage / $250 deductible <br />
                        Covid-19 Coverage Included. <br />
                        Read Policy Wordings for full details. <br />
                        No medical form up to age 79</Text>
                <Box>
                    <Button mb={'20px'} size={'sm'} background={buttonBackgroundColor} color={buttonColor}>Buy Now</Button>
                    <Text>Compare Plan</Text>
                </Box>
                {/* <Tooltip hasArrow label='Click for more information'> */}
                <FaCircleInfo />
                {/* </Tooltip> */}
            </Flex>
            <Flex borderTop={'1px solid grey'} fontFamily={'Manrope, sans-serif'} justifyContent={'space-around'} p={'20px'} alignItems={'center'}>
                <Image src='https://www.quote.rates.ca/pics/logos/travel/BC-1.3.gif' />
                <Text> <span style={{color:'black',fontWeight:'800',fontSize:'26px'}}>$152</span> cost pertrip</Text>
                <Text>$7M coverage / $250 deductible <br />
                        Covid-19 Coverage Included. <br />
                        Read Policy Wordings for full details. <br />
                        No medical form up to age 79</Text>
                <Box>
                    <Button mb={'20px'} size={'sm'} background={buttonBackgroundColor} color={buttonColor}>Buy Now</Button>
                    <Text>Compare Plan</Text>
                </Box>
                {/* <Tooltip hasArrow label='Click for more information'> */}
                <FaCircleInfo />
                {/* </Tooltip> */}
            </Flex>
        </Box>
      
    </Box>
  );
}

export default TravelCompariosnQuotes;


