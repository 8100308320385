export const ROLES = {
  CUSTOMER: "Customer",
  ADVISOR: "Advisor",
  ADMIN: "Admin",
  AGA: "AGA",
  MGA: "MGA",
  RAVI:"Ravi",
  PARTNER:'PARTNER',
  SUPERADMIN:"Super Admin"
};
