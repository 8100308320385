import { ADVISOR_LOGIN, FORGET_PASSWORD_TYPE, isLoading } from "../../../constants/actionConstants";
import { LoginService } from "./service";



export const advisorLoginForgetPassword = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let loginList = await LoginService.advisorLoginForgetPassword(params);
    const {status, message, data} = loginList
    if(status === 200) {
        dispatch({
            type: FORGET_PASSWORD_TYPE,
            payload: data
        })
        alert("Reset Link Has Been Sent To Your Mail, Thanks")
    }else {
        
        alert(message)
    }
    dispatch(isLoading(false));
    return {status, data}
}

export const advisorLoginList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let loginList = await LoginService.advisorLoginList(params);
    const {status, message, data} = loginList
    if(status === 200) {
        dispatch({
            type: ADVISOR_LOGIN,
            payload: data
        })
        // toast.success("Advisor Successfully Logged In")
    }else {
        // toast.error(message)
        alert(message)
    }
    dispatch(isLoading(false));
    return {status, data}
}