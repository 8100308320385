import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor } from '../../Utils/UniversalColors';
import { useNavigate } from 'react-router-dom';
import ImageItem from '../../Utils/UniversalColors/ImageItem';
import LoginModal from '../Supervisa/LoginModal';



export const ComparedProductsModal = ({ isOpen, onClose, onSubmit, items = { datas: [] } }) => {
  const navigate=useNavigate()
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [buyNowData,setBuyNowData]=useState([])
  console.log(items,'itemss')
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  const handleBuyNow = (data) => {
    setBuyNowData(data)
    setIsLoginModalOpen(true)
  };

  const handleBuyNowAfterLogin=()=>{
    const item = {
      datas: buyNowData,
      row:items.row
    };
    navigate('/single-quote', { state: { item } });
  }

  if (!items || !Array.isArray(items.datas)) {
    return null;
  }


return (
    <Box> 
      <Modal scrollBehavior='inside' zIndex={'999999999'} size={'5xl'} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg='none'
      backdropFilter='auto'
      backdropInvert='80%'
      backdropBlur='2px' />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
          <Box pb={'20px'} color={'black'}>
    <Box mt={'20px'} background={'white'} borderRadius={'7px'}>
        <Box pt={'50px'} overflow="hidden"> 
            <Carousel
                swipeable
                draggable
                responsive={responsive}
                infinite
                showDots
                autoPlay
                autoPlaySpeed={5000}
                keyBoardControl
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-20-px"
                >
                {items.datas.map(item => (
                <Box w={'90%'} textAlign={'center'} fontFamily={'Montserrat, Sans-serif'}>
                <Flex justifyContent={'center'} textAlign={'center'} w='100px' m={'auto'} h={'45px'} alignItems={'center'}>
                  <ImageItem imageBytes={item.image} />
                </Flex>
                <Flex justifyContent={'center'} alignItems={'center'} direction={'column'} mt={'15px'} >
                {item.policyAmount} $
                </Flex>
                <Flex justifyContent={'center'} p={'10px'}>
                    <Button size={'xs'} background={buttonBackgroundColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} color={buttonColor} onClick={() => handleBuyNow(item)}>Buy Now</Button>
                </Flex>
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Name</Text>
                    <Text fontSize={'14px'} pb={'15px'}>{item.name}</Text>
                    <hr />
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Insurnace Policy</Text>
                    <Text color={'blue'} fontSize={'14px'} pb={'15px'}>View Sample Policy</Text>
                    <hr />
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Coverage Amount</Text>
                    <Text fontSize={'14px'} pb={'15px'}>$ {item.sumInsured}</Text>
                    <hr />
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Deductibles</Text>
                    <Text fontSize={'14px'} pb={'15px'}>{item.deductibles}</Text>
                    <hr />
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Emergency Hospital Services</Text>
                    <Text fontSize={'14px'} pb={'15px'}>Up to Maximum Policy Limit</Text>
                    <hr />
                    <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Emergency Medical Services/Treatment</Text>
                    <Text fontSize={'14px'} pb={'15px'}>Up to Maximum Policy Limit</Text>
                    {/* <hr /> */}
                    <Box mt={'15px'}>
                    <Accordion defaultIndex={[1]} allowMultiple w={'80%'} m={'auto'}>
                        <AccordionItem>
                            <h2>
                            <AccordionButton>
                                <Box fontSize={'12px'} as='span' flex='1' textAlign='left'>
                                Exclusions
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} background={'#efefef'}>
                                <Text fontSize={'12px'}>{item.exclusion}</Text>
                            
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                            <AccordionButton>
                                <Box fontSize={'12px'} as='span' flex='1' textAlign='left'>
                                Coverages
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} background={'#efefef'}>
                                <Text fontSize={'12px'}>{item.coverage}</Text>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                            <AccordionButton>
                                <Box fontSize={'12px'} as='span' flex='1' textAlign='left'>
                                Eligiblity
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel background={'#efefef'} pb={4}>
                                <Text fontSize={'12px'}>{item.eligibility}</Text>
                            
                            </AccordionPanel>
                        </AccordionItem>
                        </Accordion>
                    </Box> 
                    </Box>    
                ))}
                </Carousel>
                </Box>
            </Box>
        </Box>

          </ModalBody>

          {/* <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
      <LoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} onSubmit={handleBuyNowAfterLogin} />
    </Box>
  )
}



export const GetRateModal = ({isOpen,onClose,onSubmit})=>{


const handleBuyNow=()=>{
  onSubmit()
  onClose()
}


return(
      <div>
      <Modal isOpen={isOpen} onClose={onClose} isCentered zIndex={99999999} size={'md'}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader fontSize={'14px'} fontFamily={'poppins'}>Great! Let's get you that rate.</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box fontFamily={'Open Sans,sans-serif'} fontSize={'14px'}>
                <Text pt={'5px'} pl={'10px'}>In three simple steps you can purchase your policy,</Text>
                <Text pl={'10px'} pb={'7px'}>easily and securely, online.
                </Text>
                <Text fontSize={'10px'} pt={'5px'} pl={'10px'}>By clicking "Buy Online", I give permission to TRAVELMEDICARE to transfer my quote information and contact details to The Destination: Travel Group Inc. in order to complete the purchase of travel insurance.</Text>
                <Text fontSize={'10px'} pt={'5px'} pl={'10px'}>TRAVELMEDICARE values your privacy.</Text>
                <Text fontSize={'10px'} pt={'5px'} pl={'10px'}>For details, see our privacy policy.</Text>
                </Box>                    
              </ModalBody>
              <ModalFooter>
                <Flex gap="50px" justifyContent='center' alignItems={'center'}>
                  <Button onClick={handleBuyNow} mr={3} _hover={{backgroundColor:buttonHoverBackground,color:buttonHoverColor}} background={buttonBackgroundColor} color={buttonColor} size={'sm'}>Buy Online</Button>
                  <Text color={'blue'} fontSize={'12px'}>Speak to a representative 9306454204</Text>
                </Flex>
              </ModalFooter>
            </ModalContent>
      </Modal>

      </div>
  )
}

