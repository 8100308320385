// utils/universalColor.js
export const primaryColor = '#2E3749';

export const primaryBackgroundColor = "#196761"

export const headingsColor='black'

export const subheadingColor='#064d98'

export const buttonBackgroundColor = 'teal'

export const buttonHoverBackground = '#FFD470'

export const buttonHoverColor='black'

export const buttonColor = 'white'

export const fontFamily = 'poppins'

export const new_FooterColor = "#6F8FAF";

export const dashboardBackgroundColor = '#eee'