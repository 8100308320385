import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Image,Button, Input, Select, Text, Checkbox } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Api from '../../../dependencies/utils/Api';
import { VIEW_SINGLE_QUOTE } from '../../../constants/ApplicationUrl';
import axios from 'axios';
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor } from '../../../Utils/UniversalColors';

const ViewQuote = () => {
    // const { token} = JSON.parse(localStorage.getItem("code_dev1"));
    const params=useParams()
    const {id}=params;
    const [data,setData]=useState([]);
    const [companyName,setCompanyName]=useState('')
    console.log("data",data)


    useEffect(() => {
        Api.GET(`${VIEW_SINGLE_QUOTE}/${id}`).then((res) => {
            console.log(res, 'resssssss');
            setData([res.data]);
            setCompanyName(res.data.companyName)
        }).catch(error => {
            console.error('Error fetching data:', error);
        });
    }, [id]);


return (
    <Box background={'#efefef'} >
        {data.map(item=>( 
            <Box w={['95%','80%','80%']} m={'auto'}>
            <Flex direction={['column','column','row']} pt={'100px'} pb={'120px'} justifyContent={'space-around'}>
                <Box w={['98%','30%','30%']} background={'white'} pt={'30px'} boxShadow="0 8px 16px rgba(0, 0, 0, 0.2)">
                <Box w={'90%'} textAlign={'center'} fontFamily={'Montserrat, Sans-serif'}>
                        <Flex justifyContent={'center'}>
                        <Image w='120px' h={'45px'} src={item.image} />
                        </Flex>
                        {/* <Flex justifyContent={'center'} alignItems={'center'} direction={'column'} h={'70px'} mt={'10px'} >
                        {item.premiumAmount.map((ele, index) => (
                            <Text fontWeight={'bold'} color={'#307EF2'} key={index}>{Math.round(ele)} $</Text>
                        ))}
                        </Flex> */}
                        <hr />
                        <Flex justifyContent={'center'} p={'10px'}>
                            <Button size={'xs'} background={buttonBackgroundColor} color={buttonColor}>Buy Now</Button>
                        </Flex>
                            <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Name</Text>
                            <Text fontSize={'14px'} pb={'15px'}>{item.policyHolderName}</Text>
                            <hr />
                            <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Quotation Number</Text>
                            <Text color={'blue'} fontSize={'14px'} pb={'15px'}>{item.quotationNo}</Text>
                            <hr />
                            <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Coverage Amount</Text>
                            <Text fontSize={'14px'} pb={'15px'}>CAD {item.policyLimit}</Text>
                            <hr />
                            <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Amount</Text>
                            <Text fontSize={'14px'} pb={'15px'}>{item.quoteAmount} CAD</Text>
                            <hr />
                            <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Emergency Hospital Services</Text>
                            <Text fontSize={'14px'} pb={'15px'}>Up to Maximum Policy Limit</Text>
                            <hr />
                            <Text mt={'15px'} color={'#666666'} fontSize={'11px'}>Emergency Medical Services/Treatment</Text>
                            <Text fontSize={'14px'} pb={'15px'}>Up to Maximum Policy Limit</Text>
                            {/* <hr /> */}
                            <Box mt={'15px'}>
                            <Accordion defaultIndex={[0]} allowMultiple w={'80%'} m={'auto'}>
                                <AccordionItem>
                                    <h2>
                                    <AccordionButton>
                                        <Box fontSize={'12px'} as='span' flex='1' textAlign='left'>
                                        Exclusions
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4} background={'#efefef'}>
                                        <Text fontSize={'12px'}>{item.exclusion}</Text>
                                    
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                    <AccordionButton>
                                        <Box fontSize={'12px'} as='span' flex='1' textAlign='left'>
                                        Coverages
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4} background={'#efefef'}>
                                        <Text fontSize={'12px'}>{item.coverage}</Text>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                    <AccordionButton>
                                        <Box fontSize={'12px'} as='span' flex='1' textAlign='left'>
                                        Eligiblity
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    </h2>
                                    <AccordionPanel background={'#efefef'} pb={4}>
                                        {/* <Text fontSize={'12px'}>{item.datas.eligibility}</Text> */}
                                    
                                    </AccordionPanel>
                                </AccordionItem>
                                </Accordion>
                            </Box> 
                </Box> 
                </Box>
                <Box w={['98%','65%','65%']} background={'white'} boxShadow="0 8px 16px rgba(0, 0, 0, 0.2)">
                    <div style={{ width: "100%",maxWidth: "800px",margin: "auto",padding: "18px"}}>
                        {
                            companyName!=="Travel Medicare"?<Box>
                                <Text fontFamily={'Monteserrat, Sans-serif'} fontSize={'13px'} color={'red'}>Thank you for purchasing from Travel Medicare! One of our support team members will connect with you shortly to complete the policy purchase.</Text>
                            </Box>:'' 
                        }
                    <p
                        style={{
                        fontFamily: "poppins",
                        fontSize: "18px",
                        fontWeight: 400,
                        marginBottom: "20px",
                        borderBottom: "2px solid #088183",
                        }}
                    >
                        Traveller Details
                    </p>
                    {item.listOfInsured.map(ele=>(
                        <Box mb={'7px'} backgroundColor={'#f3f3f3'} border={'1px solid #80808026'} fontSize={'13px'} fontFamily={'Open Sans, sans-serif'}>
                        <Flex direction={['column','column','row']} gap={['10px','20px','20px']}
                            style={{
                            margin: "auto",
                            padding: "18px",
                            }}>
                            <Box w={['90%','90%','30%']}>
                            <span style={{ marginBottom: "8px" }}>Name Of Insured</span>
                            <Input size='xs' background={'white'} name="name" value={ele.insuredName}
                               />
                            </Box>
                            <Box w={['90%','90%','30%']}>
                            <span style={{ marginBottom: "8px" }}>Gender</span>
                            <Select background={'white'} size={'xs'} border={'1px solid #d7d7d7'}
                                value={ele.insuredGender}
                                name="gender">
                                <option value="">Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                </Select>
                            </Box>
                            <Box w={['90%','90%','30%']}>
                            <span style={{ marginBottom: "8px" }}>Date of Birth</span>
                            <Input readOnly background={'white'} name="dob"
                                value={ele.insuredDob} size='xs' type='date' border={'1px solid #d7d7d7'} max={new Date().toISOString().split('T')[0]} />
                            </Box>
                            </Flex>
                            <Flex direction={['column','column','row']} gap={['10px','20px','20px']} p={'18px'} pt={'5px'} pb={'20px'}>
                            <Box w={['90%','90%','30%']}>
                                <span style={{ marginBottom: "8px" }}>
                                Beneficiary Name
                                </span>
                                <Input background={'white'} name="benificiaryName" placeholder="Name"
                                value={ele.beneficiaryName} size='xs' border={'1px solid #d7d7d7'} />
                            </Box>
                            <Box w={['90%','90%','30%']}>
                                <span style={{ marginBottom: "8px" }}>Relation</span>
                                <Input background={'white'} name="relation" value={ele.beneficiaryRelationToInsured}
                                placeholder="Relation" size='xs' border={'1px solid #d7d7d7'} />
                            </Box>
                            <Box w={['90%','90%','30%']}>
                            <span style={{ marginBottom: "8px" }}>Beneficiary DOB</span>
                            <Input background={'white'}
                                name="benificiaryDob"
                                value={ele.beneficiaryDob}
                                placeholder="Select Date and Time" size='xs' type='date' border={'1px solid #d7d7d7'} max={new Date().toISOString().split('T')[0]} />
                            </Box>
                            </Flex>
                            </Box>
                    ))}
                    <div
                        style={{
                        gap: "20px",
                        margin: "auto",
                        padding: "18px",
                        }}
                    >
                        <Flex fontFamily={'Open Sans, sans-serif'} direction={['column','column','row']} justifyContent={'space-between'} fontSize={'14px'} pb={'15px'}>
                        <Box w={['90%','90%','30%']}>
                        <span style={{ marginBottom: "8px" }} fontSize='13px'>Departure from country of origin</span>
                        <Input name="departureDate" value={item.departureFromCountryOfOrigin} 
                             size='xs' type='date' border={'1px solid #d7d7d7'} />
                        </Box>
                        {/* <Flex direction={'column'}>
                        <span>Waiting Period</span>
                        <input
                            placeholder="Basic usage"
                            name="waiting"
                            value={item.waitingPeriod}
                        />
                        </Flex> */}
                        <Box >
                        <Text>Family Coverage</Text>
                        <Checkbox checked={item.familyCoverage===1?true:false} readOnly pt={'7px'} size="md" colorScheme="green"></Checkbox>
                        </Box>
                        </Flex>
                        <hr/>
                        <Flex fontFamily={'Open Sans, sans-serif'} direction={['column','column','row']} gap={['10px','20px','20px']} fontSize={'14px'} pb={'20px'} mt={['10px','30px','30px']}>
                        <Box w={['90%','90%','30%']}>
                        <Text>Policy Owner*</Text>
                        <Input type='text' size='xs' name="policyOwnerDob" value={item.policyHolderName} border={'1px solid #d7d7d7'} /> 
                        </Box>
                        <Box w={['90%','90%','30%']}>
                        <Text>Policy Owner Date Of Birth*</Text>
                        <Input type='date' size='xs' name="policyOwnerDob" value={item.policyHolderDob} border={'1px solid #d7d7d7'} />            
                        </Box>
                        <Box w={['90%','90%','30%']}>
                        <Text>Policy Owner Email*</Text>
                        <Input placeholder='Email' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerEmail" value={item.policyHolderEmail}/>    
                        </Box>
                        </Flex>
                        <Flex fontFamily={'Open Sans, sans-serif'} direction={['column','column','row']} gap={['10px','20px','20px']} fontSize={'14px'} pb={'20px'} mt={['10px','20px','2px']}>
                        <Box w={['90%','90%','30%']}>
                        <Text>Policy Owner Postal Code*</Text>
                        <Input placeholder='Postal code' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerPostalCode" value={item.policyHolderPostalCode}/>   
                        </Box>
                        <Box w={['90%','90%','30%']}>
                        <Text>Policy Owner City*</Text>
                        <Input placeholder='city' size='xs' border={'1px solid #d7d7d7'} name="policyOwnerCity" value={item.policyHolderCity}/>   
                        </Box>
                        <Box w={['90%','90%','30%']}>
                        <Text>Policy Owner Phone*</Text>
                        <Input placeholder='phone' size='xs' border={'1px solid #d7d7d7'} name="policyOwnerPhone" value={item.policyHolderPhone} />            
                        </Box>
                        </Flex>
                        <Box fontFamily={'Open Sans, sans-serif'} pb='30px' w={['90%','90%','30%']}>
                        <Text fontFamily={'Open Sans, sans-serif'} fontSize={'12px'}>Policy Owner Address*</Text>
                        <Input placeholder='Address' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerAddress" value={item.policyHolderAddress}/>     
                        </Box>
                        {
                           companyName==="Travel Medicare"?
                           <Flex fontFamily={'Open Sans, sans-serif'} justifyContent={'end'}>
                            <Button size={'sm'} background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}}>Proceed</Button>
                        </Flex>:''
                        }
                    </div>
                    </div>
                </Box>
            </Flex>
            </Box>
                ))}
    </Box>
  )
}

export default ViewQuote
