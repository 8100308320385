import React, { useState, useEffect, useRef } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf'; // Import jsPDF for PDF generation
import 'jspdf-autotable';
import {
  Flex, Box, Select, Tooltip, Button, Input, ChakraProvider, FormControl, theme, FormLabel
} from '@chakra-ui/react';
import { useDispatch } from "react-redux";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useNavigate } from 'react-router-dom';
import { SiMicrosoftexcel } from "react-icons/si";
import { FaRegFilePdf } from "react-icons/fa6";
import { BsFiletypeCsv } from "react-icons/bs";
import { buttonBackgroundColor, buttonColor, primaryColor } from '../../Utils/UniversalColors';
import Api from '../../dependencies/utils/Api';
import { CANCEL_QUOTE, GET_QUOTE_BY_CUSTOMER } from '../../constants/ApplicationUrl';
import axios from 'axios';
import '../../Utils/UniversalColors/TableStyles.css';

const AllQuotes = () => {
  const pagination = true;
  const paginationPageSizeSelector = [10, 20, 50, 100];
  const dispatch = useDispatch();
  const [quotesData, setQuotesData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const gridRef = useRef(null);
  const { token, id } = JSON.parse(localStorage.getItem("code_dev1"));

  useEffect(() => {
    const handleResize = () => {
      if (gridRef.current && gridRef.current.api) {
        if (window.innerWidth > 768) {
          gridRef.current.api.sizeColumnsToFit();
        } else {
          gridRef.current.api.resetColumnState();
        }
      }
    };
    if (gridRef.current && gridRef.current.api) {
      handleResize();
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    getAllQuotes();
    setColDefs([
      { field: "employeeName", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 150, filter: true },
      { field: "companyName", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 210, filter: true },
      { field: "product", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 110, filter: true },
      { field: "quotationNo", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 150, filter: true },
      { field: "dateOfQuote", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 170, filter: true },
      { field: "customerName", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 150 },
      { field: "amount", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 80, filter: true },
      {
        field: "Action", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 180,
        cellRenderer: props => {
          return <>
            <Tooltip bg='white' placement='right-start' color={'black'} borderRadius={'5px'} fontFamily={'Open Sans,sans-serif'} p={'10px'} border='1px solid teal' label='You can view, send ,edit and cancel quote here.'>
              <Select onChange={(e) => handleChange(e, props)} size={'xs'} style={{ backgroundColor: '#96ffff', border: '1px solid black' }}>
                <option value="">Select</option>
                <option value="view">View Quote</option>
                <option value="send">Send Quote</option>
                <option value="cancel">Cancel Quote</option>
              </Select>
            </Tooltip>
          </>;
        }
      }
    ]);
  }, []);

  const handleChange = (e, props) => {
    const { data } = props;
    if (e.target.value === 'view') {
      navigate(`/view-quote/${data.quotationNo}`)
    }
    if (e.target.value === 'send') {
      alert('Quote has been sent successfully');
    }
    if (e.target.value === 'cancel') {
      const result = window.confirm("Are you sure you want to delete?");
      if (result) {
        Api.DELETE(`${CANCEL_QUOTE}${data.quotationNo}`).then((res) => {
          console.log(res);
          if (res.status === 200) {
            getAllQuotes();
          } else {
            alert('Something went wrong');
          }
        }).catch((err) => {
          console.error(err);
          alert('Something went wrong');
        });
      }
    }
  };

  const getAllQuotes = () => {
    Api.GET(GET_QUOTE_BY_CUSTOMER).then((res) => {
      if (res.status === 200 && Array.isArray(res.data) && res.data.length > 0) {
        console.log(res.data,'reeeeeee')
        setQuotesData(res.data);
        const formattedData = res.data.map((quote, index) => ({
          id: index + 1,
          employeeName: quote.policyHolderName,
          companyName: quote.companyName,
          product: quote.product,
          quotationNo: quote.quotationNo,
          dateOfQuote: quote.createdAt,
          customerName: quote.policyHolderName,
          amount: `$ ${quote.quoteAmount}`,
        }));
        setRowData(formattedData);
      } else {
        alert('No quotes data available.');
      }
    }).catch((err) => {
      console.error(err);
      alert('Something went wrong while fetching quotes.');
    });
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredRowData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
    XLSX.writeFile(workbook, 'quotations.xlsx');
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    const tableColumn = ['Employee Name', 'Company Name', 'Product', 'Quotation No', 'Date of Quote', 'Customer Name', 'Amount'];
    const tableRows = [];
  
    filteredRowData.forEach((row) => {
      const rowData = [
        row.employeeName,
        row.companyName,
        row.product,
        row.quotationNo,
        row.dateOfQuote,
        row.customerName,
        row.amount
      ];
      tableRows.push(rowData);
    });
  
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });
  
    doc.save('quotations.pdf');
  };
  

  const filteredRowData = rowData.filter(row =>
    Object.values(row).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <Box background={'#f6f6f6'} h={'auto'} minHeight={'100vh'}>
      <Box w={['90%', '99%', '86%']} m={'auto'} style={{ paddingTop: '90px', paddingBottom: '90px' }}>
        <Flex direction={['column', 'column', 'row']} justifyContent={'space-between'} mt={['5px', '15px', '25px']}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
            <Tooltip hasArrow label='Download sheet pdf' bg='gray.300' color='black' placement='top-start' w={'180px'}>
              <Button size={'sm'} leftIcon={<FaRegFilePdf />} style={{ color: buttonColor, backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</Button></Tooltip>
            <Tooltip hasArrow label='Download sheet excel' bg='gray.300' color='black' placement='top-start' w={'180px'}>
              <Button leftIcon={<SiMicrosoftexcel />} size={'sm'} style={{ color: buttonColor, backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}>Excel</Button></Tooltip>
          </div>
          <div>
            <ChakraProvider theme={theme}>
              <FormControl variant="floating" id="search">
                {/* <FormLabel size={'sm'}>Search</FormLabel> */}
                <Input
                  borderRadius={5}
                  placeholder='Search...'
                  size={'sm'}
                  background={'white'}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </FormControl>
            </ChakraProvider>
          </div>
        </Flex>
        <Box pb={'70px'} pt={['15px', '1px', '1px']}>
          {rowData.length > 0 ? (
            <div className='ag-theme-quartz' style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
              <AgGridReact
                rowData={filteredRowData}
                columnDefs={colDefs}
                domLayout='autoHeight'
                paginationPageSizeSelector={paginationPageSizeSelector}
                pagination={pagination}
                paginationPageSize={10}
                onGridReady={(params) => {
                  params.api.sizeColumnsToFit();
                }}
              />
            </div>
          ) : (
            <div className='ag-theme-quartz' style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
              <AgGridReact
                ref={gridRef}
                rowData={filteredRowData}
                columnDefs={colDefs}
                domLayout='autoHeight'
                paginationPageSizeSelector={paginationPageSizeSelector}
                pagination={pagination}
                paginationPageSize={10}
                onGridReady={(params) => {
                  if (window.innerWidth > 768) {
                    params.api.sizeColumnsToFit();
                  }
                }}
              />
            </div>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AllQuotes;
