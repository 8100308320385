import React, { useState } from "react";
import {
  Box,
  Flex,
  FormControl,
  Input,
  Button,
  FormLabel,
  Image,
  Text,
  InputGroup,
  InputRightElement,
  useToast,
  useTheme,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Tabs,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor } from "../../Utils/UniversalColors";
import { customerLoginList } from "../Register/dependencies/action";
import { storage } from "../../dependencies/store/storage";
import { advisorLoginForgetPassword, advisorLoginList } from "./dependencies/action";
// import { advisorLoginList } from "../Dependencies/action";



const Login = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();
  const theme=useTheme()
  const [isHCaptchaLoaded, setIsHCaptchaLoaded] = useState(false);
  
  
  const [show, setShow] = useState(false);
  const [values, setValues] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });

  const handleClick = () => setShow(!show);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [name]: "" });
  
    // Validate email format
    if (name === "username" && !/\S+@\S+\.\S+/.test(value)) {
      setErrors({ ...errors, [name]: "Please enter a valid email address" });
    }
  
    // Validate password length
    if (name === "password" && value.length < 6) {
      setErrors({
        ...errors,
        [name]: "Password must be at least 6 characters long",
      });
    }
  };
  const handleCustomerLogin = async () => {
    const { username, password } = values;
    let newErrors = { ...errors };
  
    // Validate email
    if (!/\S+@\S+\.\S+/.test(username)) {
      newErrors.username = "Please enter a valid email address";
    }
  
    // Validate password
    if (password.length === 0) {
      newErrors.password = "Password is required";
    }
  
    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      return;
    }
  
    // If validation passes, proceed with login dispatch
    dispatch(customerLoginList(values)).then((res) => {
      const { status, data } = res;
      if (status === 200) {
        storage.setUser(data);
        navigate("/dashboard");
        window.location.reload();
      }
    });
  };
  
  const handleAdvisorLogin = async () => {
    const { username, password } = values;
    let newErrors = { ...errors };
  
    // Validate email
    if (!/\S+@\S+\.\S+/.test(username)) {
      newErrors.username = "Please enter a valid email address";
    }
  
    // Validate password
    if (password.length === 0) {
      newErrors.password = "Password is required";
    }
  
    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      return;
    }
  
    // If validation passes, proceed with login dispatch
    dispatch(advisorLoginList(values)).then((res) => {
      const { status, data } = res;
      if (status === 200) {
        storage.setUser(data);
        navigate("/dashboard");
        window.location.reload();
      }
    });
  };
  
  const handleForgotPassword = () => {
    const params = { email: email };
    dispatch(advisorLoginForgetPassword(params)).then((res) => {
      const { status, message } = res;
      if (status === 200 || status === 201) {
       
      } else {
        toast({
          title: "Error",
          description: message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Box m="auto" w={"100%"}>
      <Flex justifyContent={'space-between'}>
        <Box
          w={"50%"}
          p={"70px"}
          h={"100vh"}
          display={["none", "none", "block"]}
          color={"white"}
          background={"#272939"}
          backgroundImage={
            "https://travelmedicare.com/public/users/images/bg-02.jpg"
          }
          backgroundSize={"cover"}
          backgroundPosition={"center"}
        >
          {/* <Image src='https://experiortravel.com/public/users/images/bg-02.jpg'/> */}
        </Box>
      {/* <Flex direction="column" pt="90px" alignItems={'center'} w={"50%"}> */}
      <Box w={["95%","45%","45%"]} borderRadius={'10px'} background={'white'} p={5} pt={["90px","140px","140px"]} textAlign={"center"}>
        <Tabs variant="soft-rounded" colorScheme="teal">
          <TabList>
            <Tab fontSize={'14px'}>Advisor</Tab>
            <Tab fontSize={'14px'}>Customer</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
        <Box>
        <FormControl isRequired w={["95%","80%","80%"]} m={"auto"}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter email"
                  name="username"
                  onChange={handleChange}
                />
                <Text color="red.500">{errors.username}</Text>
                <FormLabel pt={"10px"}>Password</FormLabel>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    placeholder="Enter password"
                    name="password"
                    onChange={handleChange}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Text color="red.500">{errors.password}</Text>
                <Link to='/advisorForgetPassword'>
            <Text
              textAlign={"left"}
              fontSize={"14px"}
              color={"blue"}
              mt={"5px"}
            >
              Forgot Password?
            </Text>
            </Link>
                <Button
                  mt={4}
                  width="100%"
                  onClick={handleAdvisorLogin}
                  color={buttonColor}
                  backgroundColor={buttonBackgroundColor}
                  _hover={{background:buttonHoverBackground,color:buttonHoverColor}}
                >
                Login
                </Button>
              </FormControl>
              <Text mt={"35px"}>Dont have an account?</Text>
          <Flex justifyContent={"center"} mt={"15px"}>
            <Link to="/advisorRegister">
              <Button size={'sm'} background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}}>
                Register Here
              </Button>
            </Link>
          </Flex>
        </Box>
              
            </TabPanel>
            <TabPanel>
              <FormControl isRequired w={["95%","80%","80%"]} m={"auto"}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter email"
                  name="username"
                  onChange={handleChange}
                />
                <Text color="red.500">{errors.username}</Text>
                <FormLabel pt={"10px"}>Password</FormLabel>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    placeholder="Enter password"
                    name="password"
                    onChange={handleChange}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Text color="red.500">{errors.password}</Text>
                <Link to='/customerForgetPassword'>
            <Text
              textAlign={"left"}
              fontSize={"14px"}
              color={"blue"}
              mt={"5px"}
            >
              Forgot Password?
            </Text>
            </Link>
                <Button
                  mt={4}
                  width="100%"
                  onClick={handleCustomerLogin}
                  color={buttonColor}
                  backgroundColor={buttonBackgroundColor}
                  _hover={{background:buttonHoverBackground,color:buttonHoverColor}}
                >
                Login
                </Button>
                <Text mt={"35px"}>Dont have an account?</Text>
          <Flex justifyContent={"center"} mt={"15px"}>
            <Link to="/register">
              <Button size={'sm'} background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}}>
                Register Here
              </Button>
            </Link>
          </Flex>
              </FormControl>
            </TabPanel>
          </TabPanels>
        </Tabs>
        
      </Box>
    {/* </Flex> */}
      </Flex>
    </Box>
  );
};

export default Login;
